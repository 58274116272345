import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import Skeleton from "react-loading-skeleton";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import PotencyTag from "@ui/components/PotencyTag/PotencyTag";
import SimpleFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/SimpleFlowerTypeIndicator";
import ReadMore from "@ui/components/ReadMore/ReadMore";
import QuantityPicker from "@ui/components/ProductDetail/BigQuantityPicker";
import Button from "@ui/components/Button";
import PromotionIndicator from "@ui/components/ProductCard/PromotionIndicator";
import PotencyTags from "@ui/components/ProductCard/PotencyTags";
import {ZoomIn} from "@styled-icons/bootstrap/ZoomIn";
import {ZoomOut} from "@styled-icons/bootstrap/ZoomOut";
import {Close} from "@styled-icons/remix-line/Close";
import SearchButton from "../TopNavigationBar/SearchButton";
import SearchIconButton from "@ui/components/ProductDetail/SearchIconButton";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";

export default function ProductInfo({
  styles,
  hasEmptyPlaceholder,
  emptyPlaceholderProps,
  isSkeleton,
  image,
  LinkComponent,
  brand,
  productName,
  separator,
  sizes,
  mainTags,
  description,
  price,
  validForSale,
  quantityPicker,
  secondaryTags,
  FlowerTypeIndicatorComponent,
  PotencyTagComponent,
  PromotionIndicatorComponent,
  NavigationActionsComponent,
  ActionsComponent,
  searchButtonProps,
  promotionText,
  potencyTags,
  ...props
}) {
  const _styles = merge({}, defaultStyles, styles);
  const {brandName, brandPath} = {...brand};
  const {
    flowerTypeBefore,
    flowerType,
    hideNameOnSmallerScreens,
    showFlowerTypeIcon,
    flowerTypeIndicatorProps,
    tags,
  } = {...mainTags};

  const [imageOpen, setImageOpen] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageOpen = () => {
    setImageOpen(!imageOpen);
  };

  const handleSetZoomed = () => {
    setIsZoomed(!isZoomed);
  };

  if (hasEmptyPlaceholder) {
    return (
      <EmptyPlaceholder
        styles={_styles.emptyPlaceholder}
        onClick={emptyPlaceholderProps.onEmptyPlaceholderButtonClickHandler}
        image={emptyPlaceholderProps.emptyPlaceholderImage}
      />
    );
  }

  if (isSkeleton) {
    return (
      <ProductInfoSkeleton
        styles={_styles}
        sizes={sizes}
        separator={separator}
        secondaryTags={secondaryTags}
      />
    );
  }

  const _sizes = sizes?.content
    ?.filter(s => typeof s === "string" && s !== "")
    .map(s => s.toString());

  return (
    <ProductInfoContainer styles={_styles.root}>
      {NavigationActionsComponent}
      {image && (
        <ImageContainer styles={_styles.image}>
          {imageOpen && (
            <ZoomedContainer isZoomed={isZoomed}>
              <img src={image.url} alt={image.name} />
              {promotionText && (
                <PromotionIndicatorComponent styles={_styles.promotionIndicator} />
              )}
              <ZoomButtonsContainer>
                {isZoomed ? (
                  <ZoomOutIcon onClick={handleSetZoomed} />
                ) : (
                  <ZoomInIcon onClick={handleSetZoomed} />
                )}
                <CloseContainer>
                  <CloseIcon onClick={handleImageOpen} />
                </CloseContainer>
              </ZoomButtonsContainer>
            </ZoomedContainer>
          )}
          {!imageOpen && (
            <ImageCardProductContainer>
              <Image
                styles={_styles.image}
                source={image.url}
                height={_styles.image.height.lg.replace("px", "")}
                alt={image.name}
              />
              <SearchIconButton onClick={handleImageOpen} />
            </ImageCardProductContainer>
          )}
        </ImageContainer>
      )}
      <ProductDetailContainer styles={_styles.productDetail}>
        {brandName && (
          <BrandContainer styles={_styles.brand}>
            <LinkComponent to={brandPath} styles={_styles.brand}>
              <BrandName styles={_styles.brand}>{brandName}</BrandName>
            </LinkComponent>
          </BrandContainer>
        )}
        <ProductName styles={_styles.name}>{productName}</ProductName>
        {sizes && sizes.position === "top" && (
          <Size styles={_styles.size}>{_sizes.join(", ")}</Size>
        )}
        {separator && separator.top && <Separator styles={_styles.separator} />}
        <MainTagsContainer styles={_styles.mainTagsContainer}>
          {flowerTypeBefore && flowerType && (
            <FlowerTypeIndicatorComponent
              styles={_styles.flowerTypeIndicator}
              flowerType={flowerType}
              showIcon={showFlowerTypeIcon}
              hideNameOnSmallerScreens={hideNameOnSmallerScreens}
              flowerTypeIndicatorProps={flowerTypeIndicatorProps}
            />
          )}
          <PotencyTags
            sizes={_sizes}
            sizesPosition={sizes?.position}
            potencyTags={tags}
            PotencyTagComponent={PotencyTagComponent}
            styles={_styles.mainTags}
          />
          {!flowerTypeBefore && flowerType && (
            <FlowerTypeIndicatorComponent
              styles={_styles.flowerTypeIndicator}
              flowerType={flowerType}
              showIcon={showFlowerTypeIcon}
              hideNameOnSmallerScreens={hideNameOnSmallerScreens}
              flowerTypeIndicatorProps={flowerTypeIndicatorProps}
            />
          )}
        </MainTagsContainer>
        {description && (
          <ReadMore
            styles={_styles.description}
            text={description.text}
            readMoreText={description.readMoreText}
            hideText={description.hideText}
            ReadMoreButtonComponent={description.ReadMoreButtonComponent}
          />
        )}
        {price && (
          <PriceDisplayContainer styles={_styles.priceDisplay}>
            <PriceDisplay>
              <PriceDisplayPrice styles={_styles.priceDisplay.price}>
                {price}
              </PriceDisplayPrice>
              {sizes && sizes.position === "bottom" && (
                <PriceDisplaySize styles={_styles.priceDisplay.size}>
                  {_sizes.join(", ")}
                </PriceDisplaySize>
              )}
            </PriceDisplay>
            <PricePerUnit styles={_styles.priceDisplay.pricePerUnit}>
              Price per unit
            </PricePerUnit>
          </PriceDisplayContainer>
        )}
        {validForSale ? (
          renderNodeOrComponent(ActionsComponent || <QuantityPicker />, {
            ...quantityPicker,
            styles: _styles.quantityPicker,
          })
        ) : (
          <NotAvailable styles={_styles.notAvailable}>
            This product is no longer available.
          </NotAvailable>
        )}
        {separator && separator.bottom && <Separator styles={_styles.separator.bottom} />}
        {secondaryTags && (
          <SecondaryTagsContainer styles={_styles.secondaryTagsContainer}>
            {secondaryTags.map((tag, index) => (
              <SecondaryTag key={`secondary-tag-${index}`} styles={_styles.secondaryTags}>
                <LinkComponent to={tag.url} keepQuery={tag.keepQuery}>
                  {tag.name}
                </LinkComponent>
              </SecondaryTag>
            ))}
          </SecondaryTagsContainer>
        )}
      </ProductDetailContainer>
    </ProductInfoContainer>
  );
}

function EmptyPlaceholder({styles, onClick, image}) {
  return (
    <EmptyPlaceholderContainer styles={styles}>
      <img src={image} alt="Product unavailable" />
      <h1>Sorry, this product is no longer available</h1>
      <Button
        styles={{root: {width: "100%"}}}
        label="Check our other products"
        onClick={onClick}
      />
    </EmptyPlaceholderContainer>
  );
}

function ProductInfoSkeleton({styles, separator, sizes}) {
  return (
    <ProductInfoContainer styles={styles.root}>
      <ImageContainer styles={styles.image}>
        <div style={{display: "flex", justifyContent: "center"}}>
          <CustomSkeleton styles={styles.skeleton.image} />
        </div>
      </ImageContainer>
      <ProductDetailContainer styles={styles.productDetail}>
        <CustomSkeleton styles={styles.skeleton.productInfo} />
        <CustomSkeleton styles={styles.skeleton.productInfo} />
        {sizes && sizes.position === "top" && (
          <CustomSkeleton styles={styles.skeleton.productInfo} />
        )}
        {separator && separator.top && <Separator styles={styles.separator} />}
        <MainTagsContainer styles={styles.mainTagsContainer}>
          {[...new Array(styles.skeleton.tags.count)].map((v, index) => (
            <CustomSkeleton key={index} styles={styles.skeleton.tags} />
          ))}
        </MainTagsContainer>
        <CustomSkeleton styles={styles.skeleton.description} />
        <CustomSkeleton styles={styles.skeleton.quantityPicker} />
        {separator && separator.bottom && <Separator styles={styles.separator} />}
        <SecondaryTagsContainer styles={styles.secondaryTagsContainer}>
          {[...new Array(styles.skeleton.tags.count)].map((v, index) => (
            <CustomSkeleton key={index} styles={styles.skeleton.tags} />
          ))}
        </SecondaryTagsContainer>
      </ProductDetailContainer>
    </ProductInfoContainer>
  );
}

const EmptyPlaceholderContainer = styled.div`
  box-sizing: border-box;
  padding: ${spacing(2)};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }

  > h1 {
    margin-top: 0;
    font-weight: ${({styles}) => styles.fontWeight};

    ${media.up("lg")} {
      font-size: ${({styles}) => styles.fontSize.lg};
    }

    ${media.down("md")} {
      font-size: ${({styles}) => styles.fontSize.md};
    }

    ${media.down("sm")} {
      font-size: ${({styles}) => styles.fontSize.sm};
    }
  }

  > :last-child {
    width: 100%;
  }
`;

const CustomSkeleton = styled(Skeleton).attrs(() => ({
  className: "product-info__skeleton",
}))`
  height: ${({styles}) => styles.height.lg};
  max-width: ${({styles}) => styles.maxWidth.lg};
  min-width: ${({styles}) => styles.minWidth.lg};
  border-radius: ${({styles}) => styles.borderRadius};

  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    max-width: ${({styles}) => styles.maxWidth.md};
    min-width: ${({styles}) => styles.minWidth.md};
  }

  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
    min-width: ${({styles}) => styles.minWidth.sm};
  }
`;

const ProductInfoContainer = styled.div.attrs(() => ({
  className: "product-info__container",
}))`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  column-gap: 30px;
  margin-bottom: ${({styles}) => styles.marginBottom};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }

  ${media.down("md")} {
    flex-direction: column;
    row-gap: 30px;
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const ImageContainer = styled.div.attrs(() => ({
  className: "product-info__image-container",
}))`
  width: 100%;
  height: 300px;
  object-fit: contain;
  border-radius: 10px;
`;

const Image = styled(ConfigurableImage).attrs(() => ({
  className: "product-info__image",
}))`
  width: 100%;
  object-fit: ${({styles}) => styles.objectFit};
  border-radius: ${({styles}) => styles.borderRadius};

  ${media.up("lg")} {
    height: ${({styles}) => styles.height.lg};
    width: ${({styles}) => styles.width.lg};
  }

  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    width: ${({styles}) => styles.width.md};
  }

  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    width: ${({styles}) => styles.width.sm};
  }
`;

const ProductDetailContainer = styled.div.attrs(() => ({
  className: "product-info__product-detail-container",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${({styles}) => styles.rowGap};
`;

const BrandContainer = styled.div.attrs(() => ({
  className: "product-info__brand-container",
}))`
  > a {
    text-decoration: ${({styles}) => styles.textDecoration};
  }
`;

const BrandName = styled.span.attrs(() => ({
  className: "product-info__brand",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};
  text-transform: ${({styles}) => styles.textTransform};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  &:hover {
    text-decoration: ${({styles}) => styles.hoverTextDecoration};
  }
`;

const ProductName = styled.span.attrs(() => ({
  className: "product-info__product-name",
}))`
    color: ${({styles}) => styles.color};
    font-family: ${({styles}) => styles.fontFamily};
    font-weight: ${({styles}) => styles.fontWeight};
    line-height: ${({styles}) => styles.lineHeight};
    text-transform: ${({styles}) => styles.textTransform};

    ${media.up("lg")} {
        font-size: ${({styles}) => styles.fontSize.lg};
    }

    ${media.down("md")} {
        font-size: ${({styles}) => styles.fontSize.md};
    }

    ${media.down("sm")} {
        font-size: ${({styles}) => styles.fontSize.sm};
`;

const Size = styled.span.attrs(() => ({
  className: "product-info__product-size",
}))`
    padding: 0;
    margin: ${({styles}) => styles.margin};
    max-width: ${({styles}) => styles.maxWidth};
    color: ${({styles}) => styles.color};
    font-family: ${({styles}) => styles.fontFamily};
    font-weight: ${({styles}) => styles.fontWeight};

    ${media.up("lg")} {
        font-size: ${({styles}) => styles.fontSize.lg};
    }

    ${media.down("md")} {
        font-size: ${({styles}) => styles.fontSize.md};
    }

    ${media.down("sm")} {
        font-size: ${({styles}) => styles.fontSize.sm};
`;

const Separator = styled.div.attrs(() => ({
  className: "product-info__separator",
}))`
  background-color: ${({styles}) => styles.color};
  height: ${({styles}) => styles.height};
  max-height: ${({styles}) => styles.height};
  margin: ${({styles}) => styles.margin};
`;

const MainTagsContainer = styled.div.attrs(() => ({
  className: "product-info__main-tags-container",
}))`
  display: flex;
  flex-direction: ${({styles}) => styles.flexDirection};
  box-sizing: border-box;
  align-items: center;
  justify-content: ${({styles}) => styles.justifyContent};
  margin: ${({styles}) => styles.margin};
  flex-wrap: ${({styles}) => styles.flexWrap};
  white-space: ${({styles}) => styles.whiteSpace};
  column-gap: ${({styles}) => styles.columnGap};
  row-gap: ${({styles}) => styles.rowGap};
  line-height: 1.5;
  letter-spacing: 1px;
  width: ${({styles}) => styles.width};

  > * {
    height: ${({styles}) => styles.tagsHeight};
  }
`;

const PriceDisplayContainer = styled.div.attrs(() => ({
  className: "product-info__price-display-container",
}))`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const PriceDisplay = styled.div.attrs(() => ({
  className: "product-info__price-display",
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;

const PriceDisplayPrice = styled.div.attrs(() => ({
  className: "product-info__price-display-price",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const PriceDisplaySize = styled.div.attrs(() => ({
  className: "product-info__price-display-size",
}))`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const PricePerUnit = styled.span.attrs(() => ({
  className: "product-info__price-per-unit",
}))`
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const NotAvailable = styled.span.attrs(() => ({
  className: "product-info__not-available",
}))`
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const SecondaryTagsContainer = styled.div.attrs(() => ({
  className: "product-info__secondary-tags-container",
}))`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  column-gap: ${({styles}) => styles.columnGap};
  row-gap: ${({styles}) => styles.rowGap};
  padding-top: ${({styles}) => styles.paddingTop};

  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const SecondaryTag = styled.div.attrs(() => ({
  className: "product-info__secondary-tag",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  background-color: ${({styles}) => styles.backgroundColor};
  line-height: ${({styles}) => styles.lineHeight};
  text-transform: ${({styles}) => styles.textTransform};
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  a {
    text-decoration: none;
    color: ${({styles}) => styles.color};
  }
`;

const ZoomedContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #212121;
    overflow: scroll;

    ${media.down("sm")} {
        height: 350px;
    }

    img {
        @media screen and (min-width: 768px) {
            max-height: 800px;
            width: 800px;
            object-fit: contain;
        }

        @media screen and (max-width: 767px) {
            width: ${isZoomed => (isZoomed ? "100%" : "50%")};
            transform: scale(1.5);
            transition: width .5s;
        }
    }
}
`;

const ZoomButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;

  @media screen and (max-width: 767px) {
    position: fixed;
  }
`;

const CloseContainer = styled.div`
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;

const CloseIcon = styled(Close)`
  color: #000000;
  padding: 7px;
  width: 25px;
`;

const ZoomInIcon = styled(ZoomIn)`
  color: grey;
  padding: 7px;
  width: 25px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const ZoomOutIcon = styled(ZoomOut)`
  color: grey;
  padding: 7px;
  width: 25px;
`;

const ImageCardProductContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;

  > div:last-child {
    ${media.down("sm")} {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

ProductInfo.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    emptyPlaceholder: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    skeleton: PropTypes.shape({
      image: PropTypes.shape({
        height: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        minWidth: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        maxWidth: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        borderRadius: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      objectFit: PropTypes.string,
      borderRadius: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    productDetail: PropTypes.shape({
      fontFamily: PropTypes.string,
      rowGap: PropTypes.string,
    }),
    brand: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      textTransform: PropTypes.string,
      textDecoration: PropTypes.string,
      hoverTextDecoration: PropTypes.string,
    }),
    name: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      textTransform: PropTypes.string,
      lineHeight: PropTypes.string,
    }),
    size: PropTypes.shape({
      margin: PropTypes.string,
      maxWidth: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
    }),
    separator: PropTypes.shape({
      color: PropTypes.string,
      height: PropTypes.string,
      margin: PropTypes.string,
    }),
    mainTagsContainer: PropTypes.shape({
      justifyContent: PropTypes.string,
      flexWrap: PropTypes.string,
      whiteSpace: PropTypes.string,
      columnGap: PropTypes.string,
      rowGap: PropTypes.string,
      margin: PropTypes.string,
      width: PropTypes.string,
      tagsHeight: PropTypes.string,
    }),
    flowerTypeIndicator: PropTypes.object,
    mainTags: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      backgroundColor: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      height: PropTypes.string,
    }),
    description: PropTypes.shape({
      root: PropTypes.shape({
        margin: PropTypes.string,
        lineHeight: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
      text: PropTypes.shape({
        color: PropTypes.string,
        maxLines: PropTypes.shape({
          lg: PropTypes.number,
          md: PropTypes.number,
          sm: PropTypes.number,
        }),
        textAlign: PropTypes.string,
      }),
      button: PropTypes.shape({
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
    }),
    priceDisplay: PropTypes.shape({
      price: PropTypes.shape({
        color: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      size: PropTypes.shape({
        color: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      pricePerUnit: PropTypes.shape({
        color: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    notAvailable: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
    }),
    secondaryTagsContainer: PropTypes.shape({
      columnGap: PropTypes.string,
      rowGap: PropTypes.string,
      paddingTop: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    secondaryTags: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      lineHeight: PropTypes.string,
      textTransform: PropTypes.string,
    }),
  }),
  hasEmptyPlaceholder: PropTypes.bool,
  emptyPlaceholderProps: PropTypes.shape({
    onEmptyPlaceholderButtonClickHandler: PropTypes.func,
    emptyPlaceholderImage: PropTypes.string,
  }),
  isSkeleton: PropTypes.bool,
  image: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }),
  LinkComponent: PropTypes.elementType,
  brand: PropTypes.shape({
    brandName: PropTypes.string,
    brandPath: PropTypes.object,
  }),
  productName: PropTypes.string,
  sizes: PropTypes.shape({
    position: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.string),
  }),
  separator: PropTypes.shape({
    top: PropTypes.bool,
    bottom: PropTypes.bool,
  }),
  mainTags: PropTypes.shape({
    flowerTypeBefore: PropTypes.bool,
    flowerType: PropTypes.shape({
      icon: PropTypes.elementType,
      color: PropTypes.string,
      name: PropTypes.string,
    }),
    showFlowerTypeIcon: PropTypes.bool,
    hideNameOnSmallerScreens: PropTypes.bool,
    flowerTypeIndicatorProps: PropTypes.shape({
      getColor: PropTypes.func,
      getBackground: PropTypes.func,
    }),
  }),
  description: PropTypes.shape({
    text: PropTypes.string,
    readMoreText: PropTypes.string,
    hideText: PropTypes.string,
    ReadMoreButtonComponent: PropTypes.elementType,
  }),
  price: PropTypes.string,
  validForSale: PropTypes.bool,
  promotionText: PropTypes.bool,
  quantityPicker: PropTypes.object,
  secondaryTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      keepQuery: PropTypes.bool,
    })
  ),
  FlowerTypeIndicatorComponent: PropTypes.elementType,
  PotencyTagComponent: PropTypes.elementType,
  PromotionIndicatorComponent: PropTypes.elementType,
  NavigationActionsComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.elementType,
  ]),
  ActionsComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  searchButtonProps: PropTypes.shape(SearchButton.propTypes),
  potencyTags: PropTypes.arrayOf(PropTypes.string),
};

const defaultStyles = {
  root: {
    padding: {
      lg: "30px",
      md: "25px 0",
      sm: "25px 0",
    },
  },
  emptyPlaceholder: {
    fontSize: {
      lg: "24px",
      md: "24px",
      sm: "24px",
    },
    fontWeight: "300",
  },
  skeleton: {
    image: {
      height: {
        lg: "300px",
        md: "300px",
        sm: "300px",
      },
      minWidth: {
        lg: "300px",
        md: "300px",
        sm: "300px",
      },
      maxWidth: {
        lg: "300px",
        md: "300px",
        sm: "300px",
      },
      borderRadius: "10px",
    },
    productInfo: {
      height: {
        lg: "20px",
        md: "20px",
        sm: "20px",
      },
      minWidth: {
        lg: "320px",
        md: "320px",
        sm: "220px",
      },
      maxWidth: {
        lg: "320px",
        md: "320px",
        sm: "220px",
      },
    },
    tags: {
      count: 3,
      height: {
        lg: "20px",
        md: "20px",
        sm: "20px",
      },
      minWidth: {
        lg: "80px",
        md: "80px",
        sm: "80px",
      },
      maxWidth: {
        lg: "80px",
        md: "80px",
        sm: "80px",
      },
      borderRadius: "12px",
    },
    description: {
      height: {
        lg: "120px",
        md: "120px",
        sm: "80px",
      },
      minWidth: {
        lg: "320px",
        md: "320px",
        sm: "220px",
      },
      maxWidth: {
        lg: "320px",
        md: "320px",
        sm: "220px",
      },
    },
    quantityPicker: {
      height: {
        lg: "100px",
        md: "100px",
        sm: "100px",
      },
      minWidth: {
        lg: "320px",
        md: "320px",
        sm: "220px",
      },
      maxWidth: {
        lg: "320px",
        md: "320px",
        sm: "220px",
      },
    },
  },
  image: {
    objectFit: "contain",
    borderRadius: "10px",
    height: {
      lg: "300px",
      md: "300px",
      sm: "300px",
    },
    width: {
      lg: "350px",
      md: "",
      sm: "",
    },
  },
  productDetail: {
    fontFamily: "sans-serif",
    rowGap: "10px",
  },
  brand: {
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: "400",
    color: "#020202",
    textTransform: "uppercase",
    textDecoration: "none",
    hoverTextDecoration: "none",
  },
  name: {
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "18px",
    },
    fontWeight: "400",
    lineHeight: "1.4",
    color: "#020202",
    textTransform: "none",
  },
  size: {
    maxWidth: "320px",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    fontWeight: "inherit",
    color: "#757575",
  },
  separator: {
    color: "#EBEBEB",
    height: "1px",
    margin: "16px 0 5px",
    bottom: {
      color: "#EBEBEB",
      height: "1px",
      margin: "16px 0 5px",
    },
  },
  mainTagsContainer: {
    justifyContent: "center",
    flexDirection: "row",
    margin: "10px 0",
    width: "max-content",
    columnGap: "8px",
  },
  flowerTypeIndicator: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    fontWeight: "600",
    border: ".5px solid #272727",
    padding: "2px 6px",
    textTransform: "uppercase",
  },
  mainTags: {
    columnGap: "4px",
    rowGap: "4px",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "10px",
      md: "10px",
      sm: "10px",
    },
    fontWeight: "400",
    color: "#020202",
    backgroundColor: "#fff",
    border: "1px solid #EBEBEB",
    padding: {
      lg: "2px 4px",
      md: "2px 4px",
      sm: "2px 4px",
    },
  },
  description: {
    root: {
      margin: "16px 0",
      lineHeight: {
        lg: "20px",
        md: "20px",
        sm: "20px",
      },
    },
    text: {
      color: "#000",
      maxLines: {
        lg: 8,
        md: 5,
        sm: 4,
      },
      textAlign: "left",
    },
    button: {
      fontWeight: "400",
      color: "#000",
    },
  },
  priceDisplay: {
    price: {
      color: "#272727",
      fontSize: {
        lg: "24px",
        md: "24px",
        sm: "24px",
      },
      fontWeight: "400",
    },
    size: {
      color: "#757575",
      fontSize: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      fontWeight: "400",
    },
    pricePerUnit: {
      color: "#000000",
      fontSize: {
        lg: "10px",
        md: "10px",
        sm: "10px",
      },
      fontWeight: "400",
    },
  },
  quantityPicker: {
    root: {
      fontFamily: "sans-serif",
      width: {
        lg: "",
        md: "",
        sm: "",
      },
      padding: {
        lg: "",
        md: "",
        sm: "",
      },
    },
    quantityPicker: {
      backgroundColor: "#FAFAFA",
      quantityColor: "#000",
      quantityFamily: "Arial",
      quantityWeight: "700",
      borderRadius: "10px",
      quantitySize: {
        lg: "17px",
        md: "17px",
        sm: "17px",
      },
      controllersWidth: {
        lg: "28px",
        md: "28px",
        sm: "28px",
      },
      controllersHeight: {
        lg: "28px",
        md: "28px",
        sm: "28px",
      },
      controllersColor: "#8D8D8D",
      minusIconSize: "18px",
      plusIconSize: "18px",
      removeItemIcon: {
        size: "18px",
        color: "#f07178",
      },
    },
    addToCartButton: {
      backgroundColor: "#000",
      color: "#FFF",
      border: "none",
      borderColor: "",
      borderWidth: "",
      borderRadius: "4px",
      mobileMaxHeight: "70px",
      fontSize: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      fontWeight: "700",
      padding: "0.3em 1em",
      inCartIconSize: "22px",
    },
    price: {
      fontSize: {
        lg: "32px",
        md: "32px",
        sm: "32px",
      },
      fontWeight: "700",
      color: "#000",
      backgroundColor: "#fafafa",
      borderRadius: "4px",
    },
    savings: {
      fontSize: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      fontWeight: "300",
      color: "#0b8535",
    },
    weightSelector: {
      marginTop: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      borderRadius: "4px",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      color: "#333333",
      backgroundColor: "#fafafa",
    },
    fixedToBottomContainer: {
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0) -2.56%, #ffffff 34.62%)",
    },
  },
  notAvailable: {
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "18px",
    },
    color: "#f07178",
  },
  secondaryTagsContainer: {
    paddingTop: "8px",
    columnGap: "8px",
    rowGap: "8px",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    fontWeight: "600",
  },
  secondaryTags: {
    padding: {
      lg: "2px 6px",
      md: "2px 6px",
      sm: "2px 6px",
    },
    border: "0",
    borderRadius: "10px",
    color: "#757575",
    backgroundColor: "#F2F2F2",
    lineHeight: "15px",
    textTransform: "lowercase",
    fontSize: {
      lg: "",
      md: "",
      sm: "",
    },
  },
  promotionIndicator: {
    width: "243px",
    borderRadius: "10px",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "8px",
    },
    backgroundColor: "#D0007D",
    color: "#fff",
    fontFamily: "sans-serif",
    margin: {
      lg: "10px",
      md: "",
      sm: "",
    },
    padding: {
      lg: "6px",
      md: "",
      sm: "",
    },
    position: {
      top: "520px",
      left: "200px",
    },
  },
};

ProductInfo.defaultProps = {
  styles: defaultStyles,
  LinkComponent: ({to, keepQuery, children}) => <a href={to}>{children}</a>,
  PotencyTagComponent: PotencyTag,
  FlowerTypeIndicatorComponent: SimpleFlowerTypeIndicator,
  PromotionIndicatorComponent: PromotionIndicator,
  SearchButtonComponent: SearchButton,
  NavigationActionsComponent: null,
  ActionsComponent: null,
};
