import {SortFilters} from "@ui";
import React from "react";
import styled from "styled-components";
import useTheme from "@mock/hooks/useTheme";
import sortAndFilterHeaderStyles from "@themes/default/components/SortAndFilterHeader/styles";
import useSortAndFilterHeader from "@mock/hooks/useSortAndFilterHeader";
import widthLimited from "@ui/components/Decorators/widthLimited";

export default function SortAndFilterHeader({
  filtersDisplayOptions = {},
  filterCounterOverride = c => c,
}) {
  const theme = useTheme();
  const {site, FiltersWrapperComponent, ...props} = useSortAndFilterHeader();

  return (
    <Container>
      <FiltersWrapperComponent
        mode="drawer"
        displayOptions={filtersDisplayOptions}
        onChangeFilter={() => {}}
      />
      <SortFilters
        styles={sortAndFilterHeaderStyles(theme, site)}
        {...props}
        filtersCount={filterCounterOverride(props.filtersCount)}
      />
    </Container>
  );
}

const Container = widthLimited(styled.div`
  margin: 0 auto 32px;
`);
