import useSite from "src/core/sites/hooks/useSite";
import useDeals from "@deals/hooks/useDeals";
import {useEffect} from "react";
import useDealsPageInfo from "@deals/hooks/useDealsPageInfo";
import useRouter from "src/core/common/hooks/useRouter";
import useOnSaleProducts from "@deals/hooks/useOnSaleProducts";
import useThemeConfig from "src/themes/useThemeConfig";
import {subscribeEvent} from "src/events/utils";
import {EventTypes} from "src/events/constants";
import routes from "src/core/common/routes";

export default function useDealsPage() {
  const {
    fetchDeals,
    state: {deals, noResults, loading, hasMore},
  } = useDeals();

  const {fetchOnSaleProducts, state: onSaleProductsState} = useOnSaleProducts();

  useEffect(() => {
    if (deals.length === 0) fetchDeals({reset: true});
    if (onSaleProductsState.products.length === 0) fetchOnSaleProducts({reset: true});

    return subscribeEvent(EventTypes.STORE_CHANGED, () => {
      fetchDeals({reset: true});
      fetchOnSaleProducts({reset: true});
    });
  }, []);

  const site = useSite();

  const dealsPageInfo = useDealsPageInfo();

  const router = useRouter();

  const config = useThemeConfig();

  return {
    site,
    deals: {
      data: deals,
      loading,
      skeleton: loading && deals.length === 0,
      noResults,
      hasMore,
      title: dealsPageInfo.dealsListTitle,
      description: dealsPageInfo.dealsListDescription,
      loadMore: () => fetchDeals({reset: false}),
    },
    products: {
      site,
      products: !onSaleProductsState.noResults ? onSaleProductsState.products : undefined,
      skeleton: onSaleProductsState.showSkeleton,
      hasMore: onSaleProductsState.hasMore,
      loading: onSaleProductsState.loading,
      next: fetchOnSaleProducts,
      ProductCardComponent: config.components.ProductCard,
      totalCount: onSaleProductsState.totalCount,
      title: "Products On Sale Now!",
      description: "",
    },
    onBack: () => router.push({pathname: routes.home}),
  };
}
