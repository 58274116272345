import useSite from "src/core/sites/hooks/useSite";
import {useCallback, useEffect, useMemo} from "react";
import useRouter from "src/core/common/hooks/useRouter";
import useShopGroup from "src/core/shops/hooks/useShopGroup";
import routes from "src/core/common/routes";

export default function useStoreSelectionBar() {
  const site = useSite();
  const {shop} = useRouter().query;

  function isStoreSelected(store) {
    return shop === store.slug;
  }

  const getSelectionBarButtonProps = useCallback(
    store => {
      return {
        label: store.name,
        url: store.url,
        selected: isStoreSelected(store),
      };
    },
    [shop]
  );

  const [shops, Shop] = useShopGroup();
  useEffect(() => {
    if (!shops.data) {
      Shop.loadShopGroup();
    }
  }, []);

  const stores = useMemo(() => {
    if (!shops.data) return [];
    return shops.data
      ?.filter(shop => shop.isPickupAllowed() || shop.isDeliveriesAllowed())
      .map(shop => ({
        name: shop.getName(),
        slug: shop.getSlug(),
        url: {pathname: routes.deals, params: {shop: shop.getSlug()}},
      }));
  }, [shops.data]);

  return {
    isStoreSelected: isStoreSelected,
    getSelectionBarButtonProps,
    stores,
    site,
  };
}
