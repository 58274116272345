import merge from "lodash/merge";
import get from "lodash/get";
import applyShadow from "@ui/utils/applyShadow";
import memoizeClassMethods from "@ui/utils/memoizeClassMethods";
import DefaultThemeProps from "@themes/default/themeStyles/defaultThemeProps";

export default class MedLeafThemeProps {
  constructor() {
    this.defaultThemeProps = new DefaultThemeProps();
  }

  getSelectionBarProps(_theme) {
    return this.defaultThemeProps.getSelectionBarProps(_theme);
  }

  getModalProps(theme) {
    return {
      content: {
        borderRadius: {
          lg: theme.v2.decoration.border.radius.default,
          md: `${theme.v2.decoration.border.radius.default} ${theme.v2.decoration.border.radius.default} 0 0`,
          sm: `${theme.v2.decoration.border.radius.default} ${theme.v2.decoration.border.radius.default} 0 0`,
        },
      },
    };
  }

  getHeaderProps(theme, props) {
    const headerTheme = {v2: merge({}, theme.v2, get(theme.v2, "overrides.appBar"))};

    return {
      root: {
        backgroundColor: headerTheme.v2.color.surface,
        borderBottomColor: headerTheme.v2.color.onSurface,
        borderWidth: "1px",
        fontFamily: "Lato, sans-serif",
        color: "#282828",
        fontWeight: "700",
        fontSize: {
          lg: "15px",
          md: "15px",
          sm: "15px",
        },
        letterSpacing: headerTheme.v2.typography.title6.letterSpacing,
        textTransform: "uppercase",
        hoverOptionBackgroundColor: "#58B856",
        hoverLoginBackgroundColor: "#58217E",
        hoverOptionColor: "#fff",
        width: "95px",
        padding: "0 10px",
        transition: "all 0.5s ease 0s",
      },
      logo: {
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "120px",
        },
      },
    };
  }

  getHeaderCartButtonProps(theme, props) {
    return {
      borderRadius: "50px",
      highlightTextColor: "#fff",
      highlightColor: "#58217E",
      padding: "8px",
      color: "#000",
    };
  }

  getHeaderCartBadgeProps(theme) {
    return {
      color: "#fff",
      width: "5px",
    };
  }

  getHeaderSearchButtonProps(theme, props) {
    return {
      outlineColor: "#fff",
    };
  }

  getHeaderLoginButtonProps(theme) {
    return {
      textTransform: "uppercase",
      fontSize: "15px",
      fontFamily: "Lato, sans-serif",
      color: "#58B856",
      borderRadius: "50px",
      outlineColor: "#58217E",
      padding: {
        lg: "20px",
        sm: "0px 10px",
      },
      hoverLoginBackgroundColor: "#58217E",
    };
  }

  getAnnouncementMessageProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.announcementBox)};
    const buttonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.button")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.title")),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.description")),
    };

    return {
      root: {
        fontFamily: theme.v2.typography.default?.family,
        fontSize: theme.v2.typography.default?.size,
        fontWeight: theme.v2.typography.default?.weight,
        color: theme.v2.color.typography.default,
        backgroundColor: theme.v2.color.surface,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      container: {
        backgroundColor: theme.v2.color.secondary,
      },
      title: {
        fontFamily: titleTheme.v2.typography.default?.family,
        fontSize: titleTheme.v2.typography.default?.size,
        fontWeight: titleTheme.v2.typography.default?.weight,
        color: titleTheme.v2.color.typography.default,
      },
      description: {
        fontFamily: descriptionTheme.v2.typography.default?.family,
        fontSize: descriptionTheme.v2.typography.default?.size,
        fontWeight: descriptionTheme.v2.typography.default?.weight,
        color: descriptionTheme.v2.color.typography.default,
      },
      button: {
        fontFamily: buttonTheme.v2.typography.default?.family,
        fontSize: buttonTheme.v2.typography.default?.size,
        fontWeight: buttonTheme.v2.typography.default?.weight,
        color: buttonTheme.v2.color.typography.default,
      },
    };
  }

  getCategoryBarProps(_theme, options = {}) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.categoryBar"))};
    const categoryButtonTheme = {
      v2: merge(
        {
          spacing: {
            size: {
              xs: "8px",
              m: "16px",
            },
          },
        },
        theme.v2,
        get(theme, "v2.overrides.categoryBar.categoryButton")
      ),
    };
    const categoryImageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.categoryBar.categoryImage")),
    };

    return {
      root: {
        scroll: {
          lg: false,
          md: false,
          sm: true,
        },
        backgroundColor: "#fff",
        minHeight: {
          lg: "70px",
          md: "50px",
          sm: "50px",
        },
        columnGap: {
          lg: "15px",
          md: "15px",
          sm: "15px",
        },
      },
      button: {
        root: {
          fontFamily: "sofia-pro, sans-serif",
          backgroundColor: "#FAF8FB",
          color: "#838383",
          selectedColor: "#fff",
          selectedBackgroundColor: "#000",
          opacityOnHover: 0.6,
          fontWeight: "700",
          padding: {
            lg: "8px 20px",
            md: "8px 20px",
            sm: "8px 20px",
          },
          fontSize: {
            lg: "16px",
            md: "16px",
            sm: "16px",
          },
          textTransform: "none",
          border: "none",
          borderRadius: "20px",
          height: {
            lg: "45px",
            md: "45px",
            sm: "45px",
          },
          boxShadow: options.buttonShadow
            ? applyShadow(get(categoryButtonTheme, "v2.decoration.shadow.s", []))
            : "none",
        },
        image: {
          size: {
            lg: "44px",
            md: "36px",
            sm: "36px",
          },
          borderRadius: categoryImageTheme.v2.decoration.border.radius.default,
        },
      },
    };
  }

  getDeliveryBannerProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, get(_theme.v2, "overrides.deliveryBanner")),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.background,
        fontFamily: theme.v2.typography.title5.family,
        color: theme.v2.color.primary,
        fontWeight: theme.v2.typography.title5.weight,
        fontSize: {
          lg: theme.v2.typography.title5.size.lg,
          md: theme.v2.typography.bodyText2.size.md,
          sm: theme.v2.typography.caption2.size.sm,
        },
      },
    };
  }

  getAppliedFiltersListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appliedFilters)};

    return {
      root: {
        margin: `0 ${theme.v2.spacing(4)} ${theme.v2.spacing(1)} 0`,
      },
      container: {
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onPrimary,
      },
      text: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: {
          lg: theme.v2.typography.bodyText1.size.lg,
          md: theme.v2.typography.bodyText1.size.md,
          sm: theme.v2.typography.bodyText1.size.sm,
        },
        fontWeight: theme.v2.typography.bodyText1.weight,
      },
      closeIcon: {
        color: theme.v2.color.onPrimary,
      },
    };
  }

  getPromotionalBannerProps(theme) {
    return {
      root: {
        padding: "0 32px",
        height: {
          lg: "260px",
          sm: "auto",
        },
        backgroundImage:
          "linear-gradient(27deg, rgb(89, 34, 127) 7.14%, rgb(88, 33, 126) 7.14%, rgb(88, 33, 126) 14.29%, rgb(84, 28, 123) 14.29%, rgb(84, 28, 123) 50%, rgb(89, 34, 127) 50%, rgb(89, 34, 127) 57.14%, rgb(88, 33, 126) 57.14%, rgb(88, 33, 126) 64.29%, rgb(84, 28, 123) 64.29%, rgb(84, 28, 123) 100%);\n" +
          "    background-size: 308.38px 157.13px",
      },
    };
  }

  getProductCardProps(_theme, props = {}) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productCard)};
    const imageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.image")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.flowerTypeIndicator")),
    };
    const actionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.actions")),
    };
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.potencyTag")),
    };
    const strikePriceTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.strikePrice")),
    };
    const sizesTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.sizes")),
    };

    // if white, remove padding
    const potencyTagsPadding =
      potencyTagTheme.v2.color.base.grey[200] === potencyTagTheme.v2.color.secondary
        ? "0px"
        : "2px 4px";

    return {
      root: {
        ...this.getCardProps(theme).root,
        height: {
          lg: "310px",
          md: "310px",
          sm: "310px",
        },
        maxWidth: {
          lg: "280px",
          md: "25vw",
          sm: "50vw",
        },
        minWidth: {
          lg: props.mode === "small" ? "160px" : "150px",
          md: props.mode === "small" ? "160px" : "150px",
          sm: props.mode === "small" ? "100%" : "130px",
        },
      },
      image: {
        borderBottom: "1px solid #f8f8f8",
        borderRadius: imageTheme.v2.decoration.border.radius.default,
        height: {
          lg: props.mode === "small" ? "155px" : "175px",
          md: props.mode === "small" ? "155px" : "175px",
          sm: props.mode === "small" ? "155px" : "175px",
        },
        minWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        maxWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        objectFit: "contain",
        padding: {
          lg: "0",
          md: "0",
          sm: "0",
        },
      },
      flowerTypeIndicator: {
        margin: {
          lg: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          md: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          sm: props.mode === "small" ? "0px 0px 0 30px" : "0",
        },
      },
      saleIndicator: {
        fontWeight: flowerTypeIndicatorTheme.v2.typography.title6.weight,
        fontFamily: flowerTypeIndicatorTheme.v2.typography.bodyText2.family,
        backgroundColor: "#e80c17",
        fontSize: flowerTypeIndicatorTheme.v2.typography.sizing.s,
        top: 0,
      },
      info: {
        padding: {
          lg: "8px 8px 0 8px",
          md: "8px 8px 0 8px",
          sm: "8px 8px 0 8px",
        },
        childrenMarginBottom: {
          lg: "4px",
          md: "4px",
          sm: "4px",
        },
      },
      brandName: {
        color: "#D3CCDD",
        fontFamily: "sofia-pro, sans-serif",
        fontSize: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
        fontWeight: brandNameTheme.v2.typography.body.weight.default,
        maxLines: 2,
      },
      productName: {
        color: productNameTheme.v2.color.onPrimary,
        fontFamily: "sofia-pro, sans-serif",
        fontSize: productNameTheme.v2.typography.sizing.m,
        fontWeight: "700",
        textTransform: "uppercase",
        letterSpacing: "1px",
        maxLines: 1,
      },
      potencyTag: {
        fontFamily: "sofia-pro, sans-serif",
        backgroundColor: potencyTagTheme.v2.color.background,
        border:
          potencyTagTheme.v2.color.base.grey[200] === potencyTagTheme.v2.color.secondary
            ? 0
            : `1px solid ${potencyTagTheme.v2.color.base.grey[200]}`,
        borderRadius: "12px",
        fontWeight: potencyTagTheme.v2.typography.body.weight.default,
        fontSize: potencyTagTheme.v2.typography.sizing.xs,
        color: potencyTagTheme.v2.color.onPrimary,
        padding: {
          lg: potencyTagsPadding,
          md: potencyTagsPadding,
          sm: potencyTagsPadding,
        },
      },
      productSizes: {
        color: "#D3CCDD",
        fontFamily: sizesTheme.v2.typography.bodyText2.family,
        fontSize: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
        fontWeight: sizesTheme.v2.typography.body.weight.default,
      },
      actionsContainer: {
        padding: {
          lg: "0 8px 8px 8px",
          md: "0 8px 8px 8px",
          sm: "0 8px 8px 8px",
        },
      },
      actions: {
        root: {
          fontFamily: actionsTheme.v2.typography.bodyText2.family,
          fontWeight: actionsTheme.v2.typography.title6.weight,
        },
        actions: {
          backgroundColor: actionsTheme.v2.color.primary,
          color: "#ffffff",
          borderRadius: "40px",
          height: {
            lg: "36px",
            md: "36px",
            sm: "36px",
          },
          width: {
            lg: "48px",
            md: "48px",
            sm: "48px",
          },
        },
        prices: {
          position: {
            lg: "left",
            md: "left",
            sm: "left",
          },
          crossed: {
            fontSize: strikePriceTheme.v2.typography.sizing.m,
            fontWeight: "700",
            color: props.mode === "small" ? strikePriceTheme.v2.color.primary : "#6D6E70",
          },
          alignment: "row-reverse",
          fontSize: actionsTheme.v2.typography.sizing.m,
          fontWeight: "700",
          color: props.mode === "small" ? actionsTheme.v2.color.primary : "#A32684",
        },
      },
    };
  }

  getProductListProps(_theme, site) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productList)};
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productList.text")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productList.title")),
    };

    return {
      root: {
        elementsPerRow: {
          lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
          md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
          sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.sm,
        },
      },
      element: {},
      header: {
        title: {
          fontFamily: titleTheme.v2.typography.title1.family,
          color: titleTheme.v2.color.onBackground,
          fontWeight: 700,
          textTransform: titleTheme.v2.text.decorations.none,
          fontSize: {
            lg: "32px",
            md: "32px",
            sm: "32px",
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: descriptionTheme.v2.typography.title5.family,
            fontSize: {
              lg: descriptionTheme.v2.typography.title5.size.lg,
              md: descriptionTheme.v2.typography.title5.size.lg,
              sm: descriptionTheme.v2.typography.title5.size.lg,
            },
            lineHeight: {
              lg: descriptionTheme.v2.typography.title5.lineHeight,
              md: descriptionTheme.v2.typography.title5.lineHeight,
              sm: descriptionTheme.v2.typography.title5.lineHeight,
            },
          },
          button: {
            fontWeight: descriptionTheme.v2.typography.title1.weight,
            color: descriptionTheme.v2.color.onBackground,
          },
          text: {
            fontWeight: descriptionTheme.v2.typography.title5.weight,
            color: descriptionTheme.v2.color.onBackground,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
    };
  }

  getSearchProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appBar?.search)};
    const searchBarTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchBar")),
    };
    const searchPanelTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPanel")),
    };
    const searchPanelGroupTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.group")
      ),
    };
    const searchPanelTitleTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.title")
      ),
    };
    const searchPanelDescriptionTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.description")
      ),
    };
    const searchPanelSeeAllTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.seeAll")
      ),
    };
    const searchPageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPage")),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.secondary,
      },
      searchBar: {
        root: {
          padding: {
            lg: 0,
            md: 0,
            sm: 0,
          },
        },
        input: {
          backgroundColor: searchBarTheme.v2.color.surface,
          borderRadius: searchBarTheme.v2.decoration.border.radius.default,
          border: `1px solid ${searchBarTheme.v2.color.onSecondary}`,
          fontFamily: searchBarTheme.v2.typography.bodyText1.family,
          fontWeight: searchBarTheme.v2.typography.bodyText1.weight,
          fontSize: searchBarTheme.v2.typography.bodyText1.size,
          color: searchBarTheme.v2.color.typography.bodyText1,
          placeholder: {
            color: searchBarTheme.v2.color.typography.bodyText1,
          },
          searchIcon: {
            color: searchBarTheme.v2.color.base.grey[600],
          },
        },
        close: {
          color: searchBarTheme.v2.color.onSecondary,
        },
      },
      searchPanel: {
        root: {
          backgroundColor: searchPanelTheme.v2.color.secondary || "#F0F0F0",
        },
        groupName: {
          fontFamily: searchPanelGroupTheme.v2.typography.title4.family,
          fontWeight: searchPanelGroupTheme.v2.typography.title4.weight,
          fontSize: searchPanelGroupTheme.v2.typography.title4.size,
          color: searchPanelGroupTheme.v2.color.onSecondary,
        },
        image: {
          borderRadius: searchPanelTheme.v2.decoration.border.radius.default,
        },
        title: {
          fontFamily: searchPanelTitleTheme.v2.typography.title6.family,
          fontWeight: searchPanelTitleTheme.v2.typography.title6.weight,
          fontSize: searchPanelTitleTheme.v2.typography.title6.size,
          color: searchPanelTitleTheme.v2.color.onSecondary,
        },
        description: {
          fontFamily: searchPanelDescriptionTheme.v2.typography.caption1.family,
          fontWeight: searchPanelDescriptionTheme.v2.typography.caption1.weight,
          fontSize: searchPanelDescriptionTheme.v2.typography.caption1.size,
          color: searchPanelDescriptionTheme.v2.color.onSecondary,
        },
        seeAll: {
          fontFamily: searchPanelSeeAllTheme.v2.typography.caption1.family,
          fontWeight: searchPanelSeeAllTheme.v2.typography.caption1.weight,
          fontSize: searchPanelSeeAllTheme.v2.typography.caption1.size,
          color: searchPanelSeeAllTheme.v2.color.onSecondary,
        },
      },
      searchPage: {
        header: {
          fontFamily: searchPageTheme.v2.typography.title6.family,
          fontWeight: searchPageTheme.v2.typography.title6.weight,
          fontSize: searchPageTheme.v2.typography.title6.size,
          color: searchPageTheme.v2.color.onSecondary,
        },
        tabs: {
          root: {
            padding: 0, //add token to differentiate from flight
          },
          tab: {
            fontFamily: searchPageTheme.v2.typography.title5.family,
            fontWeight: searchPageTheme.v2.typography.title5.weight,
            fontSize: {
              lg: searchPageTheme.v2.typography.title5.size.lg,
              md: searchPageTheme.v2.typography.title5.size.md,
              sm: "14px",
            },
            color: searchPageTheme.v2.color.typography.onBackground,
            border: `3px solid ${searchPageTheme.v2.color.secondary}`,
            buttonSelectedBackgroundColor: searchPageTheme.v2.color.primary,
            buttonSelectedColor: searchPageTheme.v2.color.onPrimary,
            buttonBorder: `1px solid ${searchPageTheme.v2.color.primary}`,
          },
          separator: {
            color: searchPageTheme.v2.color.base.grey["200"],
          },
        },
        results: {
          fontFamily: searchPageTheme.v2.typography.bodyText2.family,
          fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
          fontSize: searchPageTheme.v2.typography.bodyText2.size,
          color: searchPageTheme.v2.color.typography.onBackground,
        },
        sort: {
          label: {
            fontFamily: searchPageTheme.v2.typography.bodyText2.family,
            fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
            fontSize: searchPageTheme.v2.typography.bodyText2.size,
            color: searchPageTheme.v2.color.typography.onBackground,
          },
        },
      },
    };
  }

  getDeliveryModePickerProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, get(_theme.v2, "overrides.deliveryBanner.deliveryButton")),
    };

    const deliveryBannerTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.deliveryBanner")),
    };

    return {
      backgroundColor: deliveryBannerTheme.v2.input?.color.surface,
      hoverSelectedBackgroundColor: theme.v2.active?.color.surface,
      color: theme.v2.active?.color.typography.default,
      pickupColor: deliveryBannerTheme.v2.color.primary,
      locationColor: deliveryBannerTheme.v2.input?.color.typography.default,
      borderRadius: theme.v2.active?.decoration.border.radius.default,
    };
  }

  getTitleProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.title)};

    return {
      fontSize: theme.v2.typography.sizing["4xl"].lg,
      lineHeight: "32px",
      color: "#020202",
      margin: 0,
      textAlign: "start",
    };
  }

  getProductFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productFilters)};
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.title")),
    };
    const counterTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.counter")),
    };
    const sectionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.section")),
    };

    return {
      title: {
        backgroundColor: titleTheme.v2.color.background,
        color: titleTheme.v2.color.onBackground,
      },
      counter: {
        color: counterTheme.v2.color.onSurface,
      },
      section: {
        color: sectionTheme.v2.color.onSurface,
        separatorColor: sectionTheme.v2.color.base.grey[300],
        separatorWidth: "1px",
        checkmarkColor: sectionTheme.v2.color.onBackground,
      },
    };
  }

  getRecommendedListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.recommendedList)};
    return {
      element: {
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "360px",
        },
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px",
        },
      },
      header: {
        viewAllColor: theme.v2.color.onSurface,
        backgroundColor: theme.v2.color.background,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.xxl.lg,
        fontWeight: theme.v2.typography.body.weight.heavy,
        fontFamily: theme.v2.typography.body.family,
        skewness: theme.v2.decoration.textSkewness || 0,
        padding: "0 20px",
      },
    };
  }

  getNoResultsPlaceholderProps(theme) {
    return {
      root: {
        padding: {
          lg: "24px 8px",
          md: "24px 8px",
          sm: "24px 8px",
        },
      },
      logo: {
        backgroundColor: theme.v2.color.primary,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      titleText: {
        fontFamily: theme.v2.typography.title1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.l,
      },
      detailText: {
        fontFamily: theme.v2.typography.bodyText1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.m,
      },
    };
  }

  getShowcasedProductListProps(_theme, site) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.showcasedProductsList)};
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.showcasedProductsList.text")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.showcasedProductsList.title")),
    };

    return {
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 42px 16px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "row",
        },
        columnGap: "30px",
      },
      element: {
        elementsPerRow: {
          lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
          md: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
          sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
        },
        maxWidth: {
          lg: site.getUiConfiguration().showcasedProductsList.element.maxWidth.lg,
          md: site.getUiConfiguration().showcasedProductsList.element.maxWidth.md,
          sm: site.getUiConfiguration().showcasedProductsList.element.maxWidth.sm,
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "200px",
        },
      },
      header: {
        title: {
          fontFamily: titleTheme.v2.typography.title1.family,
          color: titleTheme.v2.color.onBackground,
          fontWeight: "700",
          textTransform: titleTheme.v2.text.decorations.none,
          fontSize: {
            lg: "32px",
            md: "20px",
            sm: "20px",
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        viewAll: {
          position: site.getUiConfiguration().showcasedProductsList.viewAll.position,
        },
        description: {
          root: {
            fontFamily: descriptionTheme.v2.typography.title5.family,
            fontSize: {
              lg: descriptionTheme.v2.typography.title5.size.lg,
              md: descriptionTheme.v2.typography.title5.size.sm,
              sm: descriptionTheme.v2.typography.title5.size.sm,
            },
            lineHeight: {
              lg: descriptionTheme.v2.typography.title5.lineHeight,
              md: descriptionTheme.v2.typography.title5.lineHeight,
              sm: descriptionTheme.v2.typography.title5.lineHeight,
            },
          },
          button: {
            fontWeight: 400,
            color: "#D3CCDD",
          },
          text: {
            fontWeight: 400,
            color: "#D3CCDD",
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
      indicator: {
        indicator: {
          color: theme.v2.color.primary,
        },
      },
      navigationButton: {
        root: {
          width: "0px",
          backgroundColor: theme.v2.color.secondary,
          color: theme.v2.color.onBackground,
        },
      },
    };
  }

  getProductDetailSeparatorProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail?.separator),
    };

    return {
      backgroundColor: theme.v2.color.base.grey["200"],
    };
  }

  getProductDetailProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail)};
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.potencyTag")),
    };
    const secondaryTagsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.secondaryTags")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.flowerTypeIndicator")
      ),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.description")),
    };
    const priceDisplayTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.priceDisplay")),
    };

    const quantityControllersTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.quantityControllers")
      ),
    };

    const addToCartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.addToCartButton")),
    };

    const divisorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.divisor")),
    };

    const navigationActionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.navigationActions")),
    };

    return {
      brand: {
        fontSize: {
          lg: get(brandNameTheme, "v2.typography.sizing.m.lg"),
          md: get(brandNameTheme, "v2.typography.sizing.m.lg"),
          sm: get(brandNameTheme, "v2.typography.sizing.m.lg"),
        },
        fontWeight: get(brandNameTheme, "v2.typography.bodyText1.weight.default"),
        color: get(brandNameTheme, "v2.color.onPrimary"),
        hoverTextDecoration: get(brandNameTheme, "v2.text.decorations.underline"),
        textTransform: "uppercase",
      },
      name: {
        fontFamily: get(productNameTheme, "v2.typography.bodyText1.family"),
        fontSize: {
          lg: "18px",
          md: "18px",
          sm: "18px",
        },
        fontWeight: get(productNameTheme, "v2.typography.bodyText1.weight.default"),
        color: get(productNameTheme, "v2.color.onPrimary"),
        textTransform: get(productNameTheme, "v2.typography.bodyText1.textCase"),
      },
      separator: {
        color: get(divisorTheme, "v2.color.base.grey.200"),
        bottom: {
          margin: "40px 0px 5px",
        },
      },
      mainTagsContainer: {
        columnGap: "10px",
        margin: "10px 0 0 0",
      },
      flowerTypeIndicator: {
        padding: get(flowerTypeIndicatorTheme, "v2.static.spacing.size.md"),
        border: `0.5px solid ${flowerTypeIndicatorTheme.v2.color.base.grey[900]}`,
        borderRadius: "10px",
        fontSize: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
        fontWeight: get(flowerTypeIndicatorTheme, "v2.typography.body.weight.heavy"),
      },
      mainTags: {
        padding: {
          lg: get(potencyTagTheme, "v2.static.spacing.size.md"),
          md: get(potencyTagTheme, "v2.static.spacing.size.md"),
          sm: get(potencyTagTheme, "v2.static.spacing.size.md"),
        },
        border: `1px solid ${potencyTagTheme.v2.color.base.grey[200]}`,
        borderRadius: get(potencyTagTheme, "v2.decoration.border.radius.xs"),
        backgroundColor: get(potencyTagTheme, "v2.color.background"),
        fontSize: {
          lg: get(potencyTagTheme, "v2.typography.sizing.xs.lg"),
          md: get(potencyTagTheme, "v2.typography.sizing.xs.lg"),
          sm: get(potencyTagTheme, "v2.typography.sizing.xs.lg"),
        },
        fontFamily: get(potencyTagTheme, "v2.typography.bodyText1.family"),
        fontWeight: get(potencyTagTheme, "v2.typography.bodyText1.weight.default"),
        color: get(potencyTagTheme, "v2.color.onPrimary"),
      },
      description: {
        root: {
          margin: `${theme.v2.spacing(4)} 0`,
          fontFamily: get(descriptionTheme, "v2.typography.bodyText1.family"),
          lineHeight: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            md: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
          },
        },
        text: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.sizing.m.lg"),
            md: get(descriptionTheme, "v2.typography.sizing.m.lg"),
            sm: get(descriptionTheme, "v2.typography.sizing.m.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.bodyText1.weight.default"),
          fontFamily: "sofia-pro, sans-serif",
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
        button: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.sizing.m.lg"),
            md: get(descriptionTheme, "v2.typography.sizing.m.lg"),
            sm: get(descriptionTheme, "v2.typography.sizing.m.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.bodyText1.weight.default"),
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
      },
      priceDisplay: {
        price: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.sizing.3xl.lg"),
            md: get(priceDisplayTheme, "v2.typography.sizing.3xl.lg"),
            sm: get(priceDisplayTheme, "v2.typography.sizing.3xl.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight.heavy"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
        size: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.sizing.xl.lg"),
            md: get(priceDisplayTheme, "v2.typography.sizing.xl.lg"),
            sm: get(priceDisplayTheme, "v2.typography.sizing.xl.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight"),
          color: get(priceDisplayTheme, "v2.color.base.grey.600"),
        },
        pricePerUnit: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.caption1.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
      },
      quantityPicker: {
        root: {
          width: {
            sm: "100vw",
          },
          padding: {
            sm: `0 8px`,
          },
          height: {
            lg: "100px",
            md: "100px",
          },
        },
        quantityPicker: {
          borderRadius: get(
            quantityControllersTheme,
            "v2.decoration.border.radius.default"
          ),
          backgroundColor: get(quantityControllersTheme, "v2.color.base.grey.200"),
          quantityColor: get(quantityControllersTheme, "v2.color.base.grey.500"),
          controllersColor: get(quantityControllersTheme, "v2.color.base.grey.500"),
          quantitySize: get(quantityControllersTheme, "v2.typography.title3.size"),
          controllersWidth: {
            lg: "30px",
            md: "30px",
            sm: "30px",
          },
          controllersHeight: {
            lg: "auto",
            md: "auto",
            sm: "auto",
          },
          minusIconSize: "15px",
          plusIconSize: "15px",
          removeItemIcon: {
            color: "#f44336",
          },
        },
        addToCartButton: {
          fontFamily: "sofia-pro, sans-serif",
          backgroundColor: get(addToCartButtonTheme, "v2.color.primary"),
          color: get(addToCartButtonTheme, "v2.color.surface"),
          borderRadius: "50px",
          fontSize: {
            lg: "16px",
            md: "16px",
            sm: "16px",
          },
          fontWeight: "700",
        },
        price: {
          fontFamily: "sofia-pro, sans-serif",
          fontSize: {
            lg: "20px",
            md: "20px",
            sm: "20px",
          },
          borderRadius: "15px",
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight.heavy"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
      },
      secondaryTags: {
        fontFamily: "sofia-pro, sans-serif",
        backgroundColor: get(secondaryTagsTheme, "v2.color.base.grey.200"),
        color: "#6D6D6D",
        border: `1px solid ${get(secondaryTagsTheme, "v2.color.base.grey.300")}`,
        borderRadius: "10px",
        fontSize: {
          lg: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          md: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          sm: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
        },
        fontWeight: "600",
        textTransform: "lowercase",
        padding: {
          lg: `${theme.v2.spacing(1)} ${theme.v2.spacing(2)}`,
          md: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
          sm: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
        },
      },
      navigationActions: {
        backButton: {
          backgroundColor: navigationActionsTheme.v2.color.base.grey["400"],
          color: navigationActionsTheme.v2.color.base.grey["900"],
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
        cartButton: {
          backgroundColor: navigationActionsTheme.v2.color.primary,
          color: navigationActionsTheme.v2.color.onPrimary,
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
      },
    };
  }

  getProductRecommendationsProps(_theme) {
    return {
      element: {
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px",
        },
        maxWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px",
        },
      },
    };
  }

  getSortFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.sortFilters"))};
    const optionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.sortFilters.options")),
    };

    return {
      sort: {
        root: {
          width: {
            lg: "230px",
            sm: "190px",
          },
          control: {
            borderWidth: "1px",
            borderColor: theme.v2.color.border,
            borderRadius: theme.v2.decoration.border.radius.default,
            color: theme.v2.color.onSurface,
            backgroundColor: theme.v2.color.surface,
            fontFamily: theme.v2.typography.title5.family,
            fontSize: {
              lg: theme.v2.typography.title5.size.md,
            },
            fontWeight: theme.v2.typography.title5.weight,
            lineHeight: theme.v2.typography.title5.lineHeight,
            hover: {
              borderColor: theme.v2.color.primary,
            },
          },
          menu: {
            borderRadius: theme.v2.decoration.border.radius.default,
            backgroundColor: theme.v2.color.surface,
            borderColor: theme.v2.color.border,
            borderWidth: "1px",
            margin: `${theme.v2.static.spacing.size.xs} 0`,
          },
          options: {
            fontFamily: optionsTheme.v2.typography.title5.family,
            fontSize: {
              lg: optionsTheme.v2.typography.title5.size.md,
            },
            fontWeight: optionsTheme.v2.typography.title5.weight,
            hover: {
              backgroundColor: "#58217e",
              color: "#fff",
            },
            selected: {
              backgroundColor: "#58217e",
              color: "#fff",
            },
          },
        },
      },
      filters: {
        button: {
          root: {
            fontFamily: theme.v2.typography.title5.family,
            fontSize: theme.v2.typography.title5.size.md,
            borderRadius: theme.v2.decoration.border.radius.default,
            border: `1px solid ${theme.v2.color.border}`,
            fontWeight: theme.v2.typography.title5.weight,
            hoverBorderColor: theme.v2.color.primary,
          },
          secondary: {
            backgroundColor: theme.v2.color.surface,
            color: theme.v2.color.onSurface,
          },
        },
      },
    };
  }

  getFooterProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.footer)};
    return {
      trademark: {
        color: theme.v2.color.primary,
      },
    };
  }

  getCardProps(theme) {
    return {
      root: {
        fontFamily: theme.v2.typography.bodyText1.family,
        boxShadow: theme.v2.decoration.shadow.s
          ? applyShadow(theme.v2.decoration.shadow.s)
          : "rgb(0 0 0 / 20%) 0px 0px 10px -3px !important",
        onHoverBoxShadow: applyShadow(theme.v2.decoration.shadow.s),
        border: "0",
        hoverBorder: `0`,
        borderRadius: "10px",
      },
    };
  }

  getDealsListProps(theme) {
    return {
      ...this.defaultThemeProps.getDealsListProps(theme),
      list: {padding: "14px 16px 160px 16px"},
    };
  }

  getDealDetailProps(theme) {
    return this.defaultThemeProps.getDealDetailProps(theme);
  }

  getDealCardProps(theme) {
    const rootTheme = {v2: merge({}, theme.v2, theme.v2.overrides.dealCard)};
    return {
      ...this.defaultThemeProps.getDealCardProps(theme),
      root: {
        ...this.getCardProps(theme, rootTheme).root,
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "130px",
        },
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "250px",
        },
        padding: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
      },
      type: {
        fontSize: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
        fontWeight: theme.v2.typography.body.weight.default,
        fontFamily: "sofia-pro, sans-serif",
        color: "#D3CCDD",
      },
      title: {
        fontSize: theme.v2.typography.sizing.m,
        fontWeight: "700",
        color: theme.v2.color.onSurface,
        fontFamily: "sofia-pro, sans-serif",
      },
    };
  }

  getSubcategoriesProps(theme) {
    return {
      root: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: theme.v2.typography.bodyText1.size,
        fontWeight: theme.v2.typography.bodyText1.weight,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      selected: {
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onPrimary,
      },
      unselected: {
        backgroundColor: theme.v2.color.base.grey["200"],
        color: theme.v2.color.onSurface,
      },
    };
  }
}

memoizeClassMethods(MedLeafThemeProps);
