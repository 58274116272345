import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {DisplayOnly, media} from "@ui";
import {widthLimit} from "@ui/components/Decorators/widthLimited";
import useRouter from "@mock/hooks/useRouter";
import useAppliedFilters from "@mock/hooks/useAppliedFilters";
import AppliedFilters from "@themes/boost/components/AppliedFilters";
import SortAndFilterActions from "@themes/boost/components/SortAndFilterActions";
import ShowcaseProductsList from "@themes/boost/components/ShowcaseProductsList";
import useSortAndFilterHeader from "@mock/hooks/useSortAndFilterHeader";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";

export default function BoostThemeProductsPage({title = null, ...props}) {
  const {BasePage, ShowcaseDealsList, ProductsOrderSelector} = useThemeComponents();
  const {FiltersWrapperComponent, site} = useSortAndFilterHeader();
  const router = useRouter();
  const [paramFilters] = useAppliedFilters();

  const [filters, setFilters] = useState(paramFilters);
  const {inlineOnLargeScreen} = site.getUiConfiguration().filters;

  const {productId} = router.query;

  const mediaDimensions = {
    lg: 1440,
  };

  useEffect(() => {
    if (!productId && !paramFilters.equals(filters)) {
      setFilters(paramFilters);
    }
  }, [productId, setFilters, paramFilters, filters]);

  return (
    <BasePage {...props}>
      <BrowserWithFiltersContainer>
        {inlineOnLargeScreen && (
          <DisplayOnly dims={["lg"]} overrideDimensions={mediaDimensions}>
            <FiltersWrapperComponent
              mode="inline"
              header={
                <CustomHeader>
                  <ProductsOrderSelector />
                </CustomHeader>
              }
            />
          </DisplayOnly>
        )}
        <Container inlineOnLargeScreen={inlineOnLargeScreen}>
          <DisplayOnly dims={["md", "sm"]} overrideDimensions={mediaDimensions}>
            <AppliedFilters filters={filters} />
          </DisplayOnly>
          <ProductsContainer inlineOnLargeScreen={inlineOnLargeScreen}>
            {title}
            {!inlineOnLargeScreen && <SortAndFilterActions />}
            {inlineOnLargeScreen && (
              <DisplayOnly
                dims={inlineOnLargeScreen ? ["md", "sm"] : ["md", "sm", "lg"]}
                overrideDimensions={mediaDimensions}
              >
                <SortAndFilterActions />
              </DisplayOnly>
            )}
            <ShowcaseDealsList />
            <ShowcaseProductsList />
          </ProductsContainer>
        </Container>
      </BrowserWithFiltersContainer>
    </BasePage>
  );
}

const BrowserWithFiltersContainer = styled.div`
  @media (min-width: 1440px) {
    max-width: ${({inlineOnLargeScreen}) => (inlineOnLargeScreen ? "1440px" : "unset")};
    margin: 0 auto;
  }

  position: relative;
  display: flex;
  justify-content: center;

  .inline-filters__container {
    padding-top: ${({theme}) => theme.v2?.spacing(2)};
  }
`;

const CustomHeader = styled.div`
  margin-bottom: ${({theme}) => theme.v2?.spacing(8)};
`;

const ProductsContainer = styled.div`
  width: 100%;
  @media (min-width: 1440px) {
    max-width: ${({inlineOnLargeScreen}) =>
      inlineOnLargeScreen ? "1150px" : widthLimit} !important;
  }

  .sort-filters__container {
    padding: 0 32px 32px;
  }

  ${media.down("md")} {
    .sort-filters__container {
      padding: 0 16px 32px;
    }
  }
`;

const Container = styled.div`
  padding-top: 20px;

  > section > header,
  > section > p {
    padding: 0 32px ${media.down("md")} {
      padding: 0 16px;
    }
  }

  width: 100%;
  max-width: ${widthLimit};
  margin: 0 auto;
  box-sizing: border-box;

  @media (min-width: 1440px) {
    max-width: ${({inlineOnLargeScreen}) =>
      inlineOnLargeScreen ? "1150px" : widthLimit};
    margin: ${({inlineOnLargeScreen}) => (inlineOnLargeScreen ? 0 : "0 auto")};

    ${({theme, inlineOnLargeScreen}) =>
      !inlineOnLargeScreen
        ? ""
        : `
      position: relative;
      display: flex;
      justify-content: center;
      padding: 0 ${theme.v2.spacing(8)};
  
      .carousel-card-list__container {
        position: relative;
        > div:first-child {
          > button {
            position: absolute;
            left: -300px;
            top: 50%;
            transform: translate3d(-50px, -50%, 0);
          }
        }
        > div:last-child {
          > button {
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translate3d(50px, -50%, 0) rotate(180deg);
          }
        }
      }
    `};
  }
`;
