import React, {useContext} from "react";
import useRouter from "../../hooks/useRouter";
import ReactLink from "next/link";
import {ModalContext} from "src/core/common/components/modules/modal/modalContext";
import routes from "src/core/common/routes";
import RouteParamsContext from "src/core/common/routeParamsContext";
import Router from "next/router";
import {isClient} from "src/server/utils/isClient";

function Link({
  children,
  keepQuery,
  keepScroll,
  to,
  external,
  openInNewTab,
  staticContext,
  underlined,
  accessibilityLabel,
  className,
  prefetch,
  shallow,
  ...props
}) {
  const router = useRouter();

  const {modalBasePath, modalParams} = useContext(ModalContext);
  const globalParams = useContext(RouteParamsContext);

  const asModal = to ? routes.isModal(to.pathname) : false;
  let resolvedTo = resolve(to, globalParams, router, {
    keepQuery: keepQuery || asModal,
    keepScroll,
  });

  if (external) {
    return (
      <a
        {...props}
        className={className}
        href={to ? to.pathname : ""}
        style={underlined ? {textDecoration: "underlined"} : {textDecoration: "none"}}
        aria-label={accessibilityLabel}
        target={openInNewTab && "_blank"}
      >
        {children}
      </a>
    );
  } else {
    let linkProps;
    if (asModal) {
      linkProps = getModalLinkProps(resolvedTo, to, modalBasePath, modalParams);
    } else {
      linkProps = getLinkProps(resolvedTo);
    }

    return (
      <ReactLink
        {...linkProps}
        className={className}
        prefetch={prefetch ?? false}
        shallow={shallow}
        style={underlined ? {textDecoration: "underlined"} : {textDecoration: "none"}}
        onClick={props.onClick}
        aria-label={accessibilityLabel}
        target={openInNewTab && "_blank"}
        replace={asModal && router.currentlyInModal()}
      >
        {children}
      </ReactLink>
    );
  }
}

function resolve(to, globalParams = {}, router, opts = {}) {
  if (typeof to === "string") return router.resolve(to);

  const {keepQuery, keepScroll} = opts;

  const _params = to?.params
    ? {...to.params, ...globalParams, shop: to.params.shop || globalParams.shop}
    : globalParams;
  const query = {...to.query};
  if (keepScroll) query.keepScroll = 1;

  return router.resolve({...to, params: _params, query}, {keepQuery});
}

function getModalLinkProps(resolvedAs, to, modalBasePath, modalParams) {
  let _to = to || {params: {}};
  if (typeof to === "string") _to = {pathname: to, params: {}, query: to ? to.query : {}};

  const href = {
    pathname: modalBasePath,
    query: {
      ...(isClient ? Router.query : {}),
      ..._to.params,
      ..._to.query,
      ...modalParams,
      s: 2, // s: 2 to keep scroll on the background
    },
  };
  const as = {
    pathname: resolvedAs.pathname || _to.pathname,
    query: resolvedAs.query,
  };
  return {
    as: as,
    href: href,
  };
}
function getLinkProps(resolvedPath) {
  const href = {
    pathname: routes.toNextFormat(resolvedPath.pathname),
    query: resolvedPath.query,
  };

  const asQuery = {...href.query};
  delete asQuery.keepScroll;
  return {
    href,
    as: {
      pathname: href.pathname,
      query: asQuery,
    },
  };
}

export default Link;
