import React from "react";
import {useTheme} from "styled-components";
import {
  CartIconActions,
  HorizontalProductCard,
  ProductCard as ProductCardUI,
  ResponsiveProductCard,
  SaleIndicator,
} from "@ui";
import useProductCard from "@mock/hooks/useProductCard";
import styles from "./styles";
import {getFlowerTypeIndicatorProps} from "@themes/boost/themeStyles/flowerType";
import makeFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/makeFlowerTypeIndicator";
import productCardStyles from "@themes/default/components/ProductCard/styles";
import merge from "lodash/merge";

export default function ProductCard(props) {
  const {site, actionProps, cartProps, flowerTypeIndicatorProps, ...productCardProps} =
    useProductCard(props);
  const {product, ...otherProps} = props;
  const defaultFlowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site);
  const flowerTypeIndicator = merge(
    {},
    flowerTypeIndicatorProps,
    defaultFlowerTypeIndicatorProps
  );
  const theme = useTheme();

  const iconType = site.getUiConfiguration().flowerTypeIndicator.iconType;
  const FlowerTypeIndicator = makeFlowerTypeIndicator(iconType);

  const DesktopProductCard = (
    <div>
      <ProductCardUI
        {...otherProps}
        {...productCardProps}
        styles={productCardStyles(theme, {mode: props.mode}, site)}
        ActionsComponent={<CartIconActions {...props} {...actionProps} />}
        SaleIndicatorComponent={<SaleIndicator position={"left"} />}
        potencyTagsPosition="bottom"
        flowerTypeIndicatorProps={flowerTypeIndicator}
        FlowerTypeIndicatorComponent={FlowerTypeIndicator}
      />
    </div>
  );

  const MobileProductCard = (
    <div>
      <HorizontalProductCard
        {...props}
        {...productCardProps}
        styles={styles(theme, site, {isHorizontalInMobile: true})}
        showSizes
        potencyTagsPosition="bottom"
        FlowerTypeIndicatorComponent={FlowerTypeIndicator}
        flowerTypeIndicatorProps={flowerTypeIndicator}
        ActionsComponent={<CartIconActions {...actionProps} />}
        SaleIndicatorComponent={<SaleIndicator position={"right"} />}
      />
    </div>
  );

  return (
    <ResponsiveProductCard
      DesktopProductCard={DesktopProductCard}
      MobileProductCard={props.mode === "small" ? DesktopProductCard : MobileProductCard}
    />
  );
}
