class Promotion {
  constructor(obj) {
    this.obj = obj;
  }

  get title() {
    return this.obj?.attributes?.name;
  }

  get id() {
    return this.obj?.id;
  }

  get description() {
    return this.obj?.attributes?.description;
  }

  get disclaimer() {
    return this.obj?.attributes?.disclaimer;
  }

  get image() {
    return this.obj?.attributes?.main_image_url;
  }

  get isFeatured() {
    return this.obj?.attributes?.is_featured;
  }

  get overrideName() {
    return this.obj?.attributes?.override_name;
  }

  get position() {
    return this.obj?.attributes?.position;
  }

  get type() {
    return this.obj?.attributes?.type?.replace("_", " ");
  }

  get slug() {
    return this.obj?.attributes?.slug;
  }
}

export default Promotion;
