import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DealImagePlaceholder from "@ui/components/DealCard/deal-image-placeholder.jpg";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import DealCard from "../DealCard";
import merge from "lodash/merge";
import Skeleton from "react-loading-skeleton";
import media from "@ui/utils/media";
import widthLimited from "@ui/components/Decorators/widthLimited";
import {ReadMore} from "@ui/components";

export default function DealDetail({
  skeleton,
  image,
  title,
  description,
  type,
  disclaimer,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  if (skeleton) {
    return (
      <Container>
        <Content styles={_styles.root}>
          <ImageContainer>
            <Skeleton height={200} width={290} />
          </ImageContainer>
          <TextContainer styles={_styles.textContainer}>
            <InnerTextContainer styles={_styles.textContainer}>
              <Skeleton height={25} width={100} />
              <Skeleton height={40} width={200} />
              <CustomSkeleton />
            </InnerTextContainer>
            <Skeleton height={25} width={200} />
          </TextContainer>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content styles={_styles.root}>
        <ImageContainer>
          {image ? (
            <Image source={image} styles={_styles.image} />
          ) : (
            <ImagePlaceholder
              src={DealImagePlaceholder}
              alt={title}
              styles={_styles.image}
            />
          )}
        </ImageContainer>
        <TextContainer styles={_styles.textContainer}>
          <InnerTextContainer styles={_styles.textContainer}>
            <Type styles={_styles.type}>{type}</Type>
            <Title styles={_styles.title}>{title}</Title>
            {description && <ReadMore text={description} styles={_styles.description} />}
          </InnerTextContainer>
          {disclaimer && (
            <Disclaimer styles={_styles.disclaimer}>{disclaimer}</Disclaimer>
          )}
        </TextContainer>
      </Content>
    </Container>
  );
}

const CustomSkeleton = styled(Skeleton)`
  height: 70px;
  max-width: 700px;
  min-width: 580px;

  ${media.down("md")} {
    max-width: 580px;
    min-width: 365px;
  }

  ${media.down("sm")} {
    max-width: 500px;
    min-width: 260px;
  }
`;

const Container = widthLimited(
  styled.div.attrs(() => ({
    className: "deal-detail deal-detail__container",
  }))``
);

const Content = styled.div`
  display: flex;
  box-shadow: ${({styles}) => styles.boxShadow};
  border: ${({styles}) => styles.border};
  background-color: ${({styles}) => styles.backgroundColor};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
    border-radius: ${({styles}) => styles.borderRadius.lg};
  }

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
    border-radius: ${({styles}) => styles.borderRadius.md};
  }

  ${media.down("sm")} {
    flex-direction: column;
    padding: ${({styles}) => styles.padding.sm};
    border-radius: ${({styles}) => styles.borderRadius.sm};
  }
`;

const ImageContainer = styled.div`
  display: flex;

  ${media.down("sm")} {
    justify-content: center;
  }
`;

const Image = styled(ConfigurableImage)`
  object-fit: contain;
  height: ${({styles}) => styles.height};
`;

const ImagePlaceholder = styled.img`
  object-fit: contain;
  height: ${({styles}) => styles.height};
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  ${media.up("lg")} {
    margin: ${({styles}) => styles.margin.lg};
  }

  ${media.down("md")} {
    margin: ${({styles}) => styles.margin.md};
  }

  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
  }
`;

const InnerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  ${media.up("lg")} {
    row-gap: ${({styles}) => styles.rowGap.lg};
  }

  ${media.down("md")} {
    row-gap: ${({styles}) => styles.rowGap.md};
  }

  ${media.down("sm")} {
    margin-top: 16px;
    row-gap: ${({styles}) => styles.rowGap.sm};
  }
`;

const Type = styled.div`
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  color: ${({styles}) => styles.color};
  text-transform: ${({styles}) => styles.textTransform};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Title = styled.div`
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  color: ${({styles}) => styles.color};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Disclaimer = styled.div`
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  color: ${({styles}) => styles.color};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  root: {
    padding: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    borderRadius: {
      lg: "0px",
      md: "0px",
      sm: "0px",
    },
    boxShadow: "0px 16px 40px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.12);",
    border: "0",
    backgroundColor: "#fff",
  },
  image: {
    height: "200px",
  },
  textContainer: {
    margin: {
      lg: "0 0 0 16px",
      md: "0 0 0 16px",
      sm: "0 0 0 0",
    },
    rowGap: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
  },
  type: {
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    fontWeight: "500",
    fontFamily: "sans-serif",
    color: "#272727",
    textTransform: "uppercase",
  },
  title: {
    fontSize: {
      lg: "24px",
      md: "24px",
      sm: "24px",
    },
    fontWeight: "700",
    fontFamily: "sans-serif",
    color: "#272727",
  },
  description: {
    text: {
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      fontWeight: "400",
      fontFamily: "sans-serif",
      color: "#272727",
    },
  },
  disclaimer: {
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: "400",
    fontFamily: "sans-serif",
    color: "#272727",
  },
};

DealCard.propTypes = {
  skeleton: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  disclaimer: PropTypes.string,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      width: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      borderRadius: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      boxShadow: PropTypes.string,
      border: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
    image: PropTypes.shape({
      height: PropTypes.string,
    }),
    textContainer: PropTypes.shape({
      margin: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      rowGap: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    type: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.string,
      fontFamily: PropTypes.string,
      color: PropTypes.string,
    }),
    title: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.string,
      fontFamily: PropTypes.string,
      color: PropTypes.string,
    }),
    description: PropTypes.shape({
      text: PropTypes.shape({
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.string,
        fontFamily: PropTypes.string,
        color: PropTypes.string,
      }),
    }),
    disclaimer: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.string,
      fontFamily: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
};
