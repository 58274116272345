import React from "react";
import {Text} from "@ui";
import {useTheme} from "styled-components";
import merge from "lodash/merge";
import styles from "src/themes/colorful/elements/Title/styles";
import useSite from "src/core/sites/hooks/useSite";

export const TheColorFulTitle = ({children, ...props}) => {
  const theme = useTheme();
  const site = useSite();
  return (
    <Text
      {...props}
      text={children}
      styles={merge({}, styles(theme, site), props.styles)}
    />
  );
};
