import BaseThemeProps from "src/themes/baseTheme/baseThemeProps";
import merge from "lodash/merge";
import get from "lodash/get";
import memoizeClassMethods from "@ui/utils/memoizeClassMethods";
import DefaultThemeProps from "@themes/default/themeStyles/defaultThemeProps";

class FlightThemeProps extends BaseThemeProps {
  constructor() {
    super();
    this.defaultThemeProps = new DefaultThemeProps();
  }

  getSelectionBarProps(_theme) {
    return this.defaultThemeProps.getSelectionBarProps(_theme);
  }

  getBreadcrumbsProps(theme) {
    const breadcrumbsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.breadcrumbs")),
    };

    return {
      root: {
        fontFamily: breadcrumbsTheme.v2.typography.caption1.family,
        fontWeight: breadcrumbsTheme.v2.typography.caption1.weight,
        color: breadcrumbsTheme.v2.color.onSurface,
        textTransform: breadcrumbsTheme.v2.typography.caption1.textCase,
        fontSize: breadcrumbsTheme.v2.typography.caption1.size,
      },
      separator: {
        color: breadcrumbsTheme.v2.color.onSurface,
        fontWeight: breadcrumbsTheme.v2.typography.caption1.weight,
        fontSize: breadcrumbsTheme.v2.typography.caption1.size,
      },
    };
  }

  applyShadow(shadow) {
    return `${shadow[1]?.x}px ${shadow[1]?.y}px ${shadow[1]?.blur}px ${shadow[1]?.spread}px  ${shadow[1]?.color},
  ${shadow[0]?.x}px ${shadow[0]?.y}px ${shadow[0]?.blur}px ${shadow[0]?.spread}px ${shadow[0]?.color}`;
  }

  getHeaderProps(theme) {
    const headerTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar")),
    };

    return {
      root: {
        backgroundColor: headerTheme.v2.color.surface,
        borderBottomColor: "transparent",
        borderWidth: headerTheme.v2.decoration.border.width.xs,
        boxShadow: this.applyShadow(theme.v2.decoration.shadow.s),
        fontFamily: headerTheme.v2.typography.bodyText1.family,
        color: headerTheme.v2.color.onSurface,
        fontWeight: headerTheme.v2.typography.bodyText1.weight,
        fontSize: headerTheme.v2.typography.bodyText1.size,
        letterSpacing: headerTheme.v2.typography.bodyText1.letterSpacing,
        textTransform: headerTheme.v2.typography.bodyText1.textCase,
      },
      logo: {
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "120px",
        },
      },
    };
  }

  getHeaderLoginButtonProps(theme, options = {}) {
    const loginButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.loginButton")),
    };

    return {
      outlineColor: loginButtonTheme.v2.color.secondary,
      borderRadius: loginButtonTheme.v2.decoration.border.radius.default,
      padding: options.loginText !== "login" ? "10px" : "8px 24px", // only used if outline/highlight is set to true
      highlightColor: loginButtonTheme.v2.color.primary,
    };
  }

  getHeaderSearchButtonProps(theme) {
    const searchButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.searchButton")),
    };

    return {
      borderRadius: searchButtonTheme.v2.decoration.border.radius.default,
      outlineColor: searchButtonTheme.v2.color.secondary,
      color: searchButtonTheme.v2.color.onSurface,
      highlightColor: searchButtonTheme.v2.color.primary,
    };
  }

  getHeaderCartButtonProps(theme) {
    const cartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.cartButton")),
    };

    return {
      borderRadius: cartButtonTheme.v2.decoration.border.radius.default,
      highlightColor: cartButtonTheme.v2.color.primary,
      highlightTextColor: cartButtonTheme.v2.color.onPrimary,
      color: cartButtonTheme.v2.color.surface,
    };
  }

  getHeaderCartBadgeProps(theme) {
    const cartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.cartBadge")),
    };
    return {
      color: cartButtonTheme.v2.color.surface,
    };
  }

  getAnnouncementMessageProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.announcementBox)};
    const buttonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.button")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.title")),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.description")),
    };

    return {
      root: {
        fontFamily: theme.v2.typography.default?.family,
        fontSize: theme.v2.typography.default?.size,
        fontWeight: theme.v2.typography.default?.weight,
        color: theme.v2.color.typography.default,
        backgroundColor: theme.v2.color.surface,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      container: {
        backgroundColor: theme.v2.color.secondary,
      },
      title: {
        fontFamily: titleTheme.v2.typography.default?.family,
        fontSize: titleTheme.v2.typography.default?.size,
        fontWeight: titleTheme.v2.typography.default?.weight,
        color: titleTheme.v2.color.typography.default,
      },
      description: {
        fontFamily: descriptionTheme.v2.typography.default?.family,
        fontSize: descriptionTheme.v2.typography.default?.size,
        fontWeight: descriptionTheme.v2.typography.default?.weight,
        color: descriptionTheme.v2.color.typography.default,
      },
      button: {
        fontFamily: buttonTheme.v2.typography.default?.family,
        fontSize: buttonTheme.v2.typography.default?.size,
        fontWeight: buttonTheme.v2.typography.default?.weight,
        color: buttonTheme.v2.color.typography.default,
      },
    };
  }

  getSidemenuProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.sidemenu"))};

    return {
      root: {},
      options: {
        color: theme.v2.color.onSurface,
        fontFamily: theme.v2.typography.bodyText2.family,
        fontWeight: theme.v2.typography.bodyText2.weight,
        textTransform: theme.v2.typography.bodyText2.textCase,
        fontSize: {
          lg: theme.v2.typography.bodyText2.size.lg,
          md: theme.v2.typography.bodyText2.size.lg,
          sm: theme.v2.typography.bodyText2.size.lg,
        },
      },
    };
  }

  getDeliveryBannerProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.deliveryBanner"))};

    return {
      root: {
        backgroundColor: theme.v2.color.secondary,
        color: theme.v2.color.typography.default,
        fontFamily: theme.v2.typography.title6.family,
        fontWeight: theme.v2.typography.title6.weight,
        fontSize: {
          lg: theme.v2.typography.title6.size.lg,
          md: theme.v2.typography.title6.size.lg,
          sm: theme.v2.typography.title6.size.md,
        },
      },
    };
  }

  getDeliveryModalProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.deliveryModal"))};
    const deliveryDropdownTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.deliveryModal.deliveryDropdown")),
    };
    const typeSelectorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.deliveryModal.typeSelector")),
    };
    const addressTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.deliveryModal.addressInput")),
    };
    const storeSelectorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.deliveryModal.storeSelector")),
    };

    return {
      root: {
        backgroundColor: deliveryDropdownTheme.v2.color.background,
        borderRadius: "0 0 8px 8px",
        color: deliveryDropdownTheme.v2.color.onBackground,
      },
      typeSelector: {
        title: {
          color: typeSelectorTheme.v2.color.onBackground,
          fontFamily: typeSelectorTheme.v2.typography.title3.family,
          fontWeight: typeSelectorTheme.v2.typography.title3.weight,
          fontSize: {
            lg: typeSelectorTheme.v2.typography.title3.size.lg,
            md: typeSelectorTheme.v2.typography.title3.size.lg,
            sm: typeSelectorTheme.v2.typography.title3.size.md,
          },
        },
        options: {
          textShadowColor: typeSelectorTheme.v2.color.primary,
          borderColor: typeSelectorTheme.v2.color.onBackground,
          color: typeSelectorTheme.v2.color.onBackground,
          fontFamily: typeSelectorTheme.v2.typography.bodyText2.family,
          fontWeight: typeSelectorTheme.v2.typography.bodyText2.weight,
          fontSize: {
            lg: typeSelectorTheme.v2.typography.bodyText2.size.lg,
            md: typeSelectorTheme.v2.typography.bodyText2.size.lg,
            sm: typeSelectorTheme.v2.typography.bodyText2.size.md,
          },
        },
      },
      addressInput: {
        title: {
          color: addressTheme.v2.color.onBackground,
          fontFamily: addressTheme.v2.typography.title3.family,
          fontWeight: addressTheme.v2.typography.title3.weight,
          fontSize: {
            lg: addressTheme.v2.typography.title3.size.lg,
            md: addressTheme.v2.typography.title3.size.lg,
            sm: addressTheme.v2.typography.title3.size.md,
          },
        },
        modeSelector: {
          mode: {
            borderColor: addressTheme.v2.color.onBackground,
            color: addressTheme.v2.color.onBackground,
            selectedColor: "#D8D8D8",
            fontFamily: addressTheme.v2.typography.bodyText1.family,
            fontWeight: addressTheme.v2.typography.bodyText1.weight,
            fontSize: {
              lg: addressTheme.v2.typography.bodyText1.size.lg,
              md: addressTheme.v2.typography.bodyText1.size.lg,
              sm: addressTheme.v2.typography.bodyText1.size.md,
            },
          },
          description: {
            color: "#7f7f7f",
            fontFamily: addressTheme.v2.typography.bodyText2.family,
            fontWeight: addressTheme.v2.typography.bodyText2.weight,
            fontSize: {
              lg: addressTheme.v2.typography.bodyText2.size.lg,
              md: addressTheme.v2.typography.bodyText2.size.lg,
              sm: addressTheme.v2.typography.bodyText2.size.md,
            },
          },
        },
      },
      storeSelector: {
        title: {
          color: storeSelectorTheme.v2.color.onBackground,
          fontFamily: storeSelectorTheme.v2.typography.title3.family,
          fontWeight: storeSelectorTheme.v2.typography.title3.weight,
          fontSize: {
            lg: storeSelectorTheme.v2.typography.title3.size.lg,
            md: storeSelectorTheme.v2.typography.title3.size.lg,
            sm: storeSelectorTheme.v2.typography.title3.size.md,
          },
        },
        options: {
          borderColor: storeSelectorTheme.v2.color.onBackground,
          color: storeSelectorTheme.v2.color.onBackground,
          selectedColor: "#D8D8D8",
          fontFamily: storeSelectorTheme.v2.typography.bodyText1.family,
          fontWeight: storeSelectorTheme.v2.typography.bodyText1.weight,
          fontSize: {
            lg: storeSelectorTheme.v2.typography.bodyText1.size.lg,
            md: storeSelectorTheme.v2.typography.bodyText1.size.lg,
            sm: storeSelectorTheme.v2.typography.bodyText1.size.md,
          },
        },
      },
    };
  }

  getCategoryBarProps(_theme, options = {}) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.categoryBar"))};
    const categoryButtonTheme = {
      v2: merge(
        {
          spacing: {
            size: {
              xs: "8px",
              m: "16px",
            },
          },
        },
        theme.v2,
        get(theme, "v2.overrides.categoryBar.categoryButton")
      ),
    };
    const categoryImageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.categoryBar.categoryImage")),
    };

    return {
      root: {
        scroll: {
          lg: false,
          md: false,
          sm: true,
        },
        backgroundColor: theme.v2.color.secondary,
        minHeight: {
          lg: "70px",
          md: "50px",
          sm: "50px",
        },
        columnGap: {
          lg: theme.v2.spacing("m"),
          md: theme.v2.spacing("xs"),
          sm: theme.v2.spacing("xs"),
        },
        padding: {
          lg: `${theme.v2.spacing("xs")} 0`,
          md: `${theme.v2.spacing("xs")} 0`,
          sm: `${theme.v2.spacing("xs")} 0`,
        },
      },
      button: {
        root: {
          fontFamily: categoryButtonTheme.v2.typography.title5.family,
          backgroundColor: categoryButtonTheme.v2.color.surface,
          color: categoryButtonTheme.v2.color.onSurface,
          selectedColor: categoryButtonTheme.v2.color.background,
          selectedBackgroundColor: categoryButtonTheme.v2.color.primary,
          fontWeight: categoryButtonTheme.v2.typography.title5.weight,
          padding: {
            lg: `${categoryButtonTheme.v2.spacing("xs")} ${categoryButtonTheme.v2.spacing(
              "xl"
            )}`,
            md: `${categoryButtonTheme.v2.spacing("xs")} ${categoryButtonTheme.v2.spacing(
              "xl"
            )}`,
            sm: `${categoryButtonTheme.v2.spacing("xs")} ${categoryButtonTheme.v2.spacing(
              "xl"
            )}`,
          },
          fontSize: {
            lg: categoryButtonTheme.v2.typography.title5.size.lg,
            md: categoryButtonTheme.v2.typography.title5.size.md,
            sm: categoryButtonTheme.v2.typography.title5.size.sm,
          },
          textTransform: categoryButtonTheme.v2.typography.title5.textCase,
          border: "none",
          borderRadius: categoryButtonTheme.v2.decoration.border.radius.default,
          height: {
            lg: "56px",
            md: "48px",
            sm: "48px",
          },
          boxShadow: options.buttonShadow
            ? this.applyShadow(categoryButtonTheme.v2.decoration.shadow.s)
            : "none",
        },
        image: {
          size: {
            lg: "44px",
            md: "36px",
            sm: "36px",
          },
          borderRadius: categoryImageTheme.v2.decoration.border.radius.default,
        },
      },
    };
  }

  getDealsListProps(theme) {
    return {
      ...this.defaultThemeProps.getDealsListProps(theme),
      header: {
        fontFamily: theme.v2.typography.title1.family,
        color: theme.v2.color.primary,
        lineColor: theme.v2.color.base.grey["300"],
        fontWeight: theme.v2.typography.title1.weight,
        fontSize: {
          sm: theme.v2.typography.sizing["3xl"].lg,
          md: theme.v2.typography.sizing["4xl"].lg,
          lg: theme.v2.typography.sizing["4xl"].lg,
        },
      },
      indicator: {
        indicator: {
          color: theme.v2.color.primary,
        },
      },
      navigationButton: {
        root: {
          width: "41px",
          height: "41px",
          backgroundColor: "#ffffff",
          color: "#000000",
          borderRadius: "50%",
          border: "1px solid #a8a8a8",
          boxShadow: "0 0 8.78294px 0.878294px #E8E8E8",
        },
      },
    };
  }

  getDealDetailProps(theme) {
    return this.defaultThemeProps.getDealDetailProps(theme);
  }

  getDealCardProps(theme) {
    const rootTheme = {v2: merge({}, theme.v2, theme.v2.overrides.dealCard)};
    return {
      ...this.defaultThemeProps.getDealCardProps(theme),
      root: {
        ...this.getCardProps(theme, rootTheme).root,
        maxWidth: {
          lg: "200px",
          md: "25vw",
          sm: "245px",
        },
        minWidth: {
          lg: "130px",
          md: "130px",
          sm: "130px",
        },
        padding: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
      },
      type: {
        fontSize: {
          lg: theme.v2.typography.bodyText2.size.lg,
          md: theme.v2.typography.bodyText2.size.lg,
          sm: theme.v2.typography.bodyText2.size.lg,
        },
        fontWeight: theme.v2.typography.bodyText2.weight,
        fontFamily: theme.v2.typography.bodyText2.family,
        color: theme.v2.color.base.grey["600"],
      },
      title: {
        fontSize: {
          lg: theme.v2.typography.title6.size.lg,
          md: theme.v2.typography.title6.size.lg,
          sm: theme.v2.typography.title6.size.lg,
        },
        fontWeight: theme.v2.typography.title6.weight,
        color: theme.v2.color.onSurface,
        fontFamily: theme.v2.typography.bodyText2.family,
      },
    };
  }

  getCardProps(theme, rootTheme) {
    return {
      root: {
        borderRadius: rootTheme.v2.decoration.border.radius.default,
        boxShadow: this.applyShadow(theme.v2.decoration.shadow.m),
        border: `1px solid ${rootTheme.v2.color.border}`,
        hoverBorder: `${theme.v2.decoration.border.width.s} solid ${theme.v2.color.primary}`,
      },
    };
  }

  getProductCardProps(_theme, props = {}) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productCard)};
    const imageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.image")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.flowerTypeIndicator")),
    };
    const rootTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.card")),
    };
    const actionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.actions")),
    };
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.potencyTag")),
    };
    const priceTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.price")),
    };
    const sizesTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.sizes")),
    };
    const infoTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.info")),
    };

    const saleIndicatorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.saleIndicator")),
    };

    const ifSmall = (small, otherwise) => (props.mode === "small" ? small : otherwise);

    return {
      root: {
        ...this.getCardProps(theme, rootTheme).root,
        fontFamily: theme.v2.typography.bodyText1.family,
        padding: {
          lg: "16px",
          md: "16px",
          sm: props.isHorizontalInMobile && "8px 16px 8px 0",
        },
        onHoverBoxShadow: this.applyShadow(theme.v2.decoration.shadow.l),
        height: {
          lg: ifSmall("300px", "380px"),
          md: ifSmall("300px", "380px"),
          sm: props.isHorizontalInMobile ? "200px" : ifSmall("300px", "380px"),
        },
        maxWidth: {
          lg: "200px",
          md: "25vw",
          sm: props.isHorizontalInMobile ? "368px" : "245px",
        },
        minWidth: {
          lg: ifSmall("100%", "130px"),
          md: ifSmall("100%", "130px"),
          sm: props.isHorizontalInMobile ? "300px" : ifSmall("100%", "130px"),
        },
      },
      image: {
        borderBottom: "0",
        borderRadius: imageTheme.v2.decoration.border.radius.default,
        height: {
          lg: ifSmall("150px", "155px"),
          md: ifSmall("150px", "155px"),
          sm: props.isHorizontalInMobile ? "155px" : ifSmall("150px", "155px"),
        },
        minWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        maxWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        objectFit: "contain",
        padding: {
          lg: "16px",
          md: "16px",
          sm: props.isHorizontalInMobile ? "0" : "8px",
        },
      },
      promotionIndicator: {
        position: {
          top: "165px",
          left: "4px",
        },
      },
      flowerTypeIndicator: {
        fontWeight: flowerTypeIndicatorTheme.v2.typography.title6.weight,
        borderRadius: flowerTypeIndicatorTheme.v2.decoration.border.radius.default,
        padding: ifSmall("4px", "6px 8px"),
        fontSize: {
          lg: brandNameTheme.v2.typography.bodyText2.size.md,
          md: brandNameTheme.v2.typography.bodyText2.size.sm,
          sm: brandNameTheme.v2.typography.bodyText2.size.sm,
        },
        position: {
          left: ifSmall("-8px", "-20px"),
          top: props.isHorizontalInMobile ? "0px" : ifSmall("-8px", "20px"),
        },
        textTransform: "none",
        width: "42px",
        height: "42px",
      },
      saleIndicator: {
        fontWeight: flowerTypeIndicatorTheme.v2.typography.title6.weight,
        borderRadius: flowerTypeIndicatorTheme.v2.decoration.border.radius.default,
        fontFamily: flowerTypeIndicatorTheme.v2.typography.title6.family,
        backgroundColor: saleIndicatorTheme.v2.color.primary,
        fontSize: {
          lg: brandNameTheme.v2.typography.bodyText2.size.md,
          md: brandNameTheme.v2.typography.bodyText2.size.sm,
          sm: brandNameTheme.v2.typography.bodyText2.size.sm,
        },
        top: props.isHorizontalInMobile ? "30px" : ifSmall("2px 10px 0 15px", "0px"),
        margin: {
          lg: ifSmall("2px 10px 0 15px", "16px"),
          md: ifSmall("2px 10px 0 15px", "16px"),
          sm: ifSmall("2px 10px 0 15px", "16px"),
        },
      },
      info: {
        padding: {
          lg: ifSmall("8px", "16px 8px 24px 8px"),
          md: ifSmall("8px", "8px 8px 16px 8px"),
          sm: props.isHorizontalInMobile ? "0" : "8px",
        },
        childrenMarginBottom: {
          lg: infoTheme.v2.spacing.size?.xs,
          md: infoTheme.v2.spacing.size?.xs,
          sm: infoTheme.v2.spacing.size?.xs,
        },
      },
      brandName: {
        color: brandNameTheme.v2.color.typography.title6,
        fontFamily: brandNameTheme.v2.typography.title6.family,
        fontSize: {
          lg: brandNameTheme.v2.typography.title6.size.lg,
          md: brandNameTheme.v2.typography.title6.size.lg,
          sm: brandNameTheme.v2.typography.title6.size.lg,
        },
        fontWeight: brandNameTheme.v2.typography.title6.weight,
        textTransform: brandNameTheme.v2.typography.title6.textCase,
      },
      productName: {
        color: productNameTheme.v2.color.typography.bodyText2,
        fontFamily: productNameTheme.v2.typography.bodyText2.family,
        fontSize: {
          lg: productNameTheme.v2.typography.bodyText2.size.lg,
          md: productNameTheme.v2.typography.bodyText2.size.lg,
          sm: productNameTheme.v2.typography.bodyText2.size.lg,
        },
        fontWeight: productNameTheme.v2.typography.bodyText2.weight,
        textTransform: productNameTheme.v2.typography.bodyText2.textCase,
      },
      potencyTag: {
        fontFamily: potencyTagTheme.v2.typography.bodyText2.family,
        backgroundColor: potencyTagTheme.v2.color.secondary,
        border: 0,
        borderRadius: potencyTagTheme.v2.decoration.border.radius.default,
        fontSize: {
          lg: potencyTagTheme.v2.typography.bodyText2.size.lg,
          md: potencyTagTheme.v2.typography.bodyText2.size.lg,
          sm: potencyTagTheme.v2.typography.bodyText2.size.lg,
        },
        color: potencyTagTheme.v2.color.typography.bodyText2,
        padding: {
          lg: "0",
          md: "0",
          sm: "0",
        },
      },
      productSizes: {
        color: sizesTheme.v2.color.typography.caption1,
        fontFamily: sizesTheme.v2.typography.caption1.family,
        fontSize: {
          lg: sizesTheme.v2.typography.caption1.size.lg,
          md: sizesTheme.v2.typography.caption1.size.lg,
          sm: sizesTheme.v2.typography.caption1.size.lg,
        },
        fontWeight: sizesTheme.v2.typography.caption1.weight,
      },
      actionsContainer: {
        padding: {
          lg: ifSmall("8px", "0 8px 8px 8px"),
          md: ifSmall("8px", "0 8px 8px 8px"),
          sm: props.isHorizontalInMobile ? "0" : "0px 8px 8px 8px",
        },
      },
      actions: {
        root: {
          fontFamily: theme.v2.typography.title6.family,
          fontWeight: theme.v2.typography.title6.weight,
        },
        actions: {
          backgroundColor: theme.v2.color.primary,
          boxShadow: this.applyShadow(theme.v2.decoration.shadow.m),
          color: actionsTheme.v2.color.onPrimary,
          borderColor: actionsTheme.v2.color.onSecondary,
          borderRadius: actionsTheme.v2.decoration.border.radius.default,
          height: {
            lg: "48px",
            md: "48px",
            sm: "48px",
          },
        },
        prices: {
          position: {
            lg: "left",
            md: "left",
            sm: "left",
          },
          crossed: {
            fontSize: {
              lg: actionsTheme.v2.typography.bodyText2.size.lg,
              md: actionsTheme.v2.typography.bodyText2.size.lg,
              sm: actionsTheme.v2.typography.bodyText2.size.lg,
            },
            fontWeight: actionsTheme.v2.typography.bodyText2.weight,
            color: ifSmall(
              actionsTheme.v2.color.primary,
              actionsTheme.v2.color.onBackground
            ),
          },
          alignment: "column",
          fontSize: {
            lg: priceTheme.v2.typography.title6.size.lg,
            md: priceTheme.v2.typography.title6.size.lg,
            sm: priceTheme.v2.typography.title6.size.sm,
          },
          fontWeight: priceTheme.v2.typography.title6.weight,
          color: ifSmall(
            actionsTheme.v2.color.primary,
            actionsTheme.v2.color.onBackground
          ),
          discountColor: actionsTheme.v2.color.secondaryVariant,
        },
      },
    };
  }

  getProductDetailProps(_theme, props = {}) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail)};
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.potencyTag")),
    };
    const secondaryTagsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.secondaryTags")),
    };

    const flowerTypeIndicatorTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.flowerTypeIndicator")
      ),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.description")),
    };
    const priceDisplayTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.priceDisplay")),
    };

    const quantityControllersTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.quantityControllers")
      ),
    };

    const navigationActionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.navigationActions")),
    };

    const addToCartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.addToCartButton")),
    };

    const inStockTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, theme.v2, "v2.overrides.productDetail.inStockIndicator")
      ),
    };

    const promotionIndicatorTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.promotionIndicator")
      ),
    };

    const weightSelectorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.weightSelector")),
    };

    return {
      brand: {
        fontSize: {
          lg: get(brandNameTheme, "v2.typography.title6.size.lg"),
          md: get(brandNameTheme, "v2.typography.title6.size.lg"),
          sm: get(brandNameTheme, "v2.typography.title6.size.lg"),
        },
        fontWeight: get(brandNameTheme, "v2.typography.title6.weight"),
        color: get(brandNameTheme, "v2.color.typography.title6"),
        hoverTextDecoration: get(brandNameTheme, "v2.text.decorations.underline"),
        textTransform: get(brandNameTheme, "v2.typography.title6.textCase"),
      },
      name: {
        fontFamily: get(productNameTheme, "v2.typography.title2.family"),
        fontSize: {
          lg: get(productNameTheme, "v2.typography.title2.size.lg"),
          md: get(productNameTheme, "v2.typography.title2.size.lg"),
          sm: get(productNameTheme, "v2.typography.title2.size.md"),
        },
        fontWeight: get(productNameTheme, "v2.typography.title2.weight"),
        color: get(productNameTheme, "v2.color.onSurface"),
        textTransform: get(productNameTheme, "v2.typography.title2.textCase"),
      },
      mainTagsContainer: {
        columnGap: "10px",
        margin: "10px 0 0 0",
      },
      promotionIndicator: {
        borderRadius: get(promotionIndicatorTheme, "v2.decoration.border.radius.6xl"),
        fontFamily: get(promotionIndicatorTheme, "v2.typography.title6.family"),
        fontSize: {
          lg: get(promotionIndicatorTheme, "v2.typography.title6.size.lg"),
          md: get(promotionIndicatorTheme, "v2.typography.title6.size.lg"),
          sm: get(promotionIndicatorTheme, "v2.typography.title6.size.md"),
        },
        fontWeight: get(promotionIndicatorTheme, "v2.typography.title6.weight"),
        backgroundColor: get(promotionIndicatorTheme, "v2.color.secondaryVariant"),
        position: {
          left: "100px",
          top: "455px",
        },
        left: "15px",
        top: "275px",
      },
      flowerTypeIndicator: {
        position: {
          lg: {
            top: "16px",
            left: "-20px",
          },
          md: {
            top: "16px",
            left: "-20px",
          },
          sm: {
            top: "16px",
            left: "-20px",
          },
        },
        width: "42px",
        height: "42px",
        border: "none",
        borderRadius: get(
          flowerTypeIndicatorTheme,
          "v2.decoration.border.radius.default"
        ),
        fontSize: {
          lg: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
          md: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
          sm: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.md"),
        },
        fontWeight: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.weight"),
      },
      mainTags: {
        padding: {
          lg: "4px 0px 0px 4px",
          md: "4px 0px 0px 4px",
          sm: "4px 0px 0px 4px",
        },
        border: "none",
        borderRadius: get(potencyTagTheme, "v2.decoration.border.radius.default"),
        backgroundColor: get(potencyTagTheme, "v2.color.secondary"),
        fontSize: {
          lg: get(potencyTagTheme, "v2.typography.bodyText2.size.lg"),
          md: get(potencyTagTheme, "v2.typography.bodyText2.size.lg"),
          sm: get(potencyTagTheme, "v2.typography.bodyText2.size.md"),
        },
        fontFamily: get(potencyTagTheme, "v2.typography.bodyText2.family"),
        fontWeight: get(potencyTagTheme, "v2.typography.bodyText2.weight"),
        color: get(potencyTagTheme, "v2.color.typography.bodyText2"),
      },
      description: {
        root: {
          margin: "6px 0",
          fontFamily: get(descriptionTheme, "v2.typography.bodyText1.family"),
          lineHeight: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            md: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
          },
        },
        text: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
            md: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.bodyText1.weight"),
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
        button: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.title6.size.lg"),
            md: get(descriptionTheme, "v2.typography.title6.size.lg"),
            sm: get(descriptionTheme, "v2.typography.title6.size.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.title6.weight"),
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
      },
      priceDisplay: {
        price: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.title5.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
        size: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight"),
          color: get(priceDisplayTheme, "v2.color.base.grey.600"),
        },
        pricePerUnit: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.caption1.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
      },
      quantityPicker: {
        root: {
          flexDirection: {
            sm: "column",
          },
          alignItems: {
            sm: "flex-start",
          },
          width: {
            sm: "100%",
          },
          padding: {
            sm: `0`,
          },
        },
        quantityPicker: {
          borderRadius: get(
            quantityControllersTheme,
            "v2.decoration.border.radius.default"
          ),
          backgroundColor: get(quantityControllersTheme, "v2.color.base.grey.200"),
          quantityColor: get(quantityControllersTheme, "v2.color.onSurface"),
          controllersColor: get(quantityControllersTheme, "v2.color.onSurface"),
          quantitySize: get(quantityControllersTheme, "v2.typography.title4.size"),
          quantityWeight: get(quantityControllersTheme, "v2.typography.title4.weight"),
          controllersWidth: {
            lg: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
            md: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
            sm: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
          },
          controllersHeight: {
            lg: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
            md: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
            sm: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
          },
          minusIconSize: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
          plusIconSize: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
          removeItemIcon: {
            color: get(quantityControllersTheme, "v2.color.onSurface"),
          },
        },
        addToCartButton: {
          backgroundColor: get(addToCartButtonTheme, "v2.color.primary"),
          color: get(addToCartButtonTheme, "v2.color.onPrimary"),
          borderColor: get(addToCartButtonTheme, "v2.color.onSecondary"),
          borderRadius: get(addToCartButtonTheme, "v2.decoration.border.radius.default"),
          borderWidth: addToCartButtonTheme.v2?.decoration?.border?.width?.xs || 0,
          fontSize: get(addToCartButtonTheme, "v2.typography.title5.size"),
          fontWeight: get(addToCartButtonTheme, "v2.typography.title5.weight"),
          mobileMaxHeight: "55px",
          width: {
            lg: "220px",
            sm: "100%",
          },
        },
        weightSelector: {
          borderRadius: get(weightSelectorTheme, "v2.decoration.border.radius.default"),
          marginTop: {
            lg: "16px",
            sm: "6px",
          },
        },
      },
      inStockContainer: {
        color: theme.v2?.inStockIndicator ? inStockTheme?.v2.color.primary : "#249711",
      },
      secondaryTags: {
        backgroundColor: get(secondaryTagsTheme, "v2.color.base.grey.300"),
        color: get(secondaryTagsTheme, "v2.color.base.grey.900"),
        borderRadius: theme.v2.decoration.border.radius.xl,
        fontSize: {
          lg: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          md: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          sm: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
        },
        fontWeight: get(secondaryTagsTheme, "v2.typography.caption1.weight"),
        textTransform: get(theme, "v2.text.case.capitalize"),
        padding: {
          lg: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          md: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          sm: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
        },
      },
      navigationActions: {
        root: {
          padding: "0 16px",
        },
        backButton: {
          backgroundColor: navigationActionsTheme.v2.color.base.grey["400"],
          color: navigationActionsTheme.v2.color.base.grey["900"],
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
        cartButton: {
          backgroundColor: navigationActionsTheme.v2.color.primary,
          color: navigationActionsTheme.v2.color.onPrimary,
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
      },
    };
  }

  getProductDetailSeparatorProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail?.separator),
    };

    return {
      backgroundColor: theme.v2.color.base.grey["200"],
    };
  }

  getProductListProps(_theme, site) {
    // const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productList)};

    return {
      root: {
        elementsPerRow: {
          lg: site.getUiConfiguration().productsList.productsPerLine.lg,
          md: site.getUiConfiguration().productsList.productsPerLine.md,
          sm: 1,
        },
        padding: {
          lg: "0px",
          md: "0px",
          sm: "0px",
        },
      },
      element: {},
    };
  }

  getNoResultsPlaceholderProps(theme) {
    return {
      root: {
        padding: {
          lg: "24px 8px",
          md: "24px 8px",
          sm: "24px 8px",
        },
      },
      logo: {
        backgroundColor: theme.v2.color.primary,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      titleText: {
        fontFamily: theme.v2.typography.title1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.l,
      },
      detailText: {
        fontFamily: theme.v2.typography.bodyText1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.m,
      },
    };
  }

  getViewAllLinkProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.viewAllLink)};

    return {
      viewAllLink: {
        borderRadius: theme.v2.decoration.border.radius.default,
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onPrimary,
        fontWeight: theme.v2.typography.body.weight.heavy,
      },
    };
  }

  getShowcasedProductListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.showcasedProductsList)};

    return {
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 42px 20px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "column",
        },
      },
      element: {
        maxWidth: {
          lg: "320px",
          md: "250px",
          sm: "368px",
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "235px",
        },
      },
      header: {
        fontFamily: theme.v2.typography.title1.family,
        color: theme.v2.color.primary,
        lineColor: theme.v2.color.base.grey["300"],
        fontWeight: theme.v2.typography.title1.weight,
        fontSize: {
          sm: theme.v2.typography.sizing["3xl"].lg,
          md: theme.v2.typography.sizing["4xl"].lg,
          lg: theme.v2.typography.sizing["4xl"].lg,
        },
      },
      indicator: {
        indicator: {
          color: theme.v2.color.primary,
        },
      },
      navigationButton: {
        root: {
          width: "41px",
          height: "41px",
          backgroundColor: "#ffffff",
          color: "#000000",
          borderRadius: "50%",
          border: "1px solid #a8a8a8",
          boxShadow: "0 0 8.78294px 0.878294px #E8E8E8",
        },
      },
    };
  }

  getAppliedFiltersListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appliedFilters)};

    return {
      root: {
        margin: `0 ${theme.v2.spacing(4)} ${theme.v2.spacing(1)} 0`,
      },
      container: {
        backgroundColor: theme.v2.color.secondary,
        color: theme.v2.color.onSecondary,
      },
      text: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: {
          lg: theme.v2.typography.bodyText1.size.lg,
          md: theme.v2.typography.bodyText1.size.md,
          sm: theme.v2.typography.bodyText1.size.sm,
        },
        fontWeight: theme.v2.typography.bodyText1.weight,
      },
    };
  }

  getSortFiltersProps(_theme, options = {}) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.sortFilters"))};
    const optionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.sortFilters.options")),
    };

    return {
      root: {
        padding: {
          lg: `0 0 ${theme.v2.spacing(8)} ${theme.v2.spacing(8)}`,
          md: `0 0 ${theme.v2.spacing(4)} ${theme.v2.spacing(4)}`,
        },
      },
      sort: {
        root: {
          width: {
            lg: "230px",
            sm: "190px",
          },
          control: {
            borderColor: theme.v2.color.border,
            borderRadius: theme.v2.decoration.border.radius.default,
            borderWidth: {
              lg: "1px",
              md: "1px",
              sm: options.withBorder?.sm ? "1px" : "0",
            },
            color: theme.v2.color.onSurface,
            backgroundColor: theme.v2.color.surface,
            fontFamily: theme.v2.typography.title5.family,
            fontSize: {
              lg: theme.v2.typography.title5.size.md,
            },
            fontWeight: theme.v2.typography.title5.weight,
            lineHeight: theme.v2.typography.title5.lineHeight,
            hover: {
              borderColor: theme.v2.color.primary,
            },
          },
          menu: {
            borderRadius: theme.v2.decoration.border.radius.default,
            backgroundColor: theme.v2.color.surface,
            borderColor: theme.v2.color.border,
            margin: `${theme.v2.static.spacing.size.xs} 0`,
          },
          options: {
            color: theme.v2.color.onBackground,
            fontFamily: optionsTheme.v2.typography.title5.family,
            fontSize: {
              lg: optionsTheme.v2.typography.title5.size.md,
            },
            fontWeight: optionsTheme.v2.typography.title5.weight,
            hover: {
              backgroundColor: optionsTheme.v2.color.secondary,
              color: optionsTheme.v2.color.onSurface,
            },
            selected: {
              backgroundColor: optionsTheme.v2.color.secondary,
              color: optionsTheme.v2.color.onSurface,
            },
          },
          arrow: {
            borderColor: `${theme.v2.color.onSurface} transparent transparent`,
            open: {
              borderColor: `transparent transparent ${theme.v2.color.onSurface}`,
            },
          },
        },
      },
      filters: {
        button: {
          root: {
            fontFamily: theme.v2.typography.title5.family,
            fontSize: theme.v2.typography.title5.size.md,
            borderRadius: theme.v2.decoration.border.radius.default,
            border: {
              lg: `1px solid ${theme.v2.color.border}`,
              md: `1px solid ${theme.v2.color.border}`,
              sm: options.withBorder?.sm ? `1px solid ${theme.v2.color.border}` : "0",
            },
            fontWeight: theme.v2.typography.title5.weight,
            hoverBorderColor: theme.v2.color.primary,
            boxShadow: {
              sm: "0 0 20px -5px rgba(0, 0, 0, 0.2)",
            },
          },
          secondary: {
            backgroundColor: theme.v2.color.surface,
            color: theme.v2.color.onSurface,
          },
        },
      },
    };
  }

  getFooterProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.footer)};
    return {
      root: {
        backgroundColor: theme.v2.color.base.grey["50"],
      },
      element: {
        hoverLinkColor: theme.v2.color.primary,
        activeLinkColor: theme.v2.color.primary,
        fontFamily: theme.v2.typography.primary.family,
        color: theme.v2.color.onBackground,
      },
    };
  }

  getRecommendedListProps(theme) {
    return {
      list: {justifyContent: "flex-start"},
      element: {
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "360px",
        },
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px",
        },
      },
      header: {},
      indicator: {
        indicator: {
          color: theme.v2.color.primaryVariant,
        },
      },
      navigationButton: {
        root: {},
      },
    };
  }

  getBrandHeaderProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme, "v2.overrides.title"))};
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.title")),
    };
    return {
      fontFamily: titleTheme.v2.typography.title1.family,
      fontSize: {
        sm: titleTheme.v2.typography.sizing["3xl"].lg,
        md: titleTheme.v2.typography.sizing["4xl"].lg,
        lg: titleTheme.v2.typography.sizing["4xl"].lg,
      },
      letterSpacing: {
        sm: titleTheme.v2.typography.title1.letterSpacing,
        md: titleTheme.v2.typography.title1.letterSpacing,
        lg: titleTheme.v2.typography.title1.letterSpacing,
      },
      fontWeight: titleTheme.v2.typography.primary.weight.heavy,
      color: titleTheme.v2.color.typography.title1,
      margin: 0,
      padding: 0,
      textTransform: "none",
      backgroundColor: "transparent",

      "> span": ` {
      cursor: default;
      font-family: ${titleTheme.v2.typography.body.family};
  
      &:hover {
        text-decoration: none;
      }
    }`,
    };
  }

  getProductRecommendationsProps() {
    return {
      element: {
        minWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
      },
    };
  }

  getFiltersPanelProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.filtersPanel)};

    return {
      root: {
        backgroundColor: theme.v2.color.background,
        width: {
          lg: "180px",
          sm: "100%",
        },
        minWidth: {
          lg: "180px",
          sm: "180px",
        },
        padding: {
          lg: "0px",
          sm: "0px",
        },
      },
      header: {
        margin: {
          lg: "10px 0 20px",
          sm: "10px 0 20px",
        },
        fontFamily: theme.v2.typography.title2.family,
        fontSize: theme.v2.typography.title2.size,
        fontWeight: theme.v2.typography.title2.weight,
      },
      clearFilters: {
        fontFamily: theme.v2.typography.bodyText1.family,
      },
      separator: {
        color: theme.v2.color.base.grey["200"],
        height: "2px",
      },
      filterGroup: {
        header: {
          margin: {
            lg: "10px 0",
            sm: "10px 0",
          },
          fontFamily: theme.v2.typography.title5.family,
          fontSize: theme.v2.typography.title5.size,
          fontWeight: theme.v2.typography.title5.weight,
        },
        onSale: {
          label: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
          },
        },
        showMore: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
        },
        options: {
          root: {
            margin: {
              lg: "0 0 10px",
              sm: "0 0 10px",
            },
            padding: {
              lg: "0",
              sm: "0",
            },
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
          },
        },
        separator: {
          color: theme.v2.color.base.grey["200"],
          height: "2px",
        },
      },
    };
  }

  getProductFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productFilters)};
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.title")),
    };
    const counterTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.counter")),
    };
    const sectionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.section")),
    };

    return {
      title: {
        backgroundColor: titleTheme.v2.color.background,
        color: titleTheme.v2.color.onBackground,
      },
      counter: {
        color: counterTheme.v2.color.onSurface,
      },
      section: {
        color: sectionTheme.v2.color.onSurface,
        separatorColor: sectionTheme.v2.color.base.grey[300],
        separatorWidth: "1px",
        checkmarkColor: sectionTheme.v2.color.onBackground,
      },
    };
  }

  getSearchProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appBar?.search)};

    return {
      root: {
        backgroundColor: theme.v2.color.secondary,
      },
      searchBar: {
        root: {
          padding: {
            lg: 0,
            md: 0,
            sm: 0,
          },
        },
        input: {
          backgroundColor: theme.v2.color.surface,
          borderRadius: theme.v2.decoration.border.radius.xs,
          border: `1px solid ${theme.v2.color.onSecondary}`,
          fontFamily: theme.v2.typography.bodyText1.family,
          fontWeight: theme.v2.typography.bodyText1.weight,
          fontSize: theme.v2.typography.bodyText1.size,
          color: theme.v2.color.typography.bodyText1,
          placeholder: {
            color: theme.v2.color.typography.bodyText1,
          },
          searchIcon: {
            color: theme.v2.color.base.grey[600],
          },
        },
        close: {
          color: theme.v2.color.onSecondary,
        },
      },
      searchPanel: {
        root: {
          backgroundColor: theme.v2.color.secondary,
        },
        groupName: {
          fontFamily: theme.v2.typography.title4.family,
          fontWeight: theme.v2.typography.title4.weight,
          fontSize: theme.v2.typography.title4.size,
          color: theme.v2.color.typography.onSecondary,
        },
        image: {
          borderRadius: theme.v2.decoration.border.radius.xs,
        },
        title: {
          fontFamily: theme.v2.typography.title6.family,
          fontWeight: theme.v2.typography.title6.weight,
          fontSize: theme.v2.typography.title6.size,
          color: theme.v2.color.typography.onSecondary,
        },
        description: {
          fontFamily: theme.v2.typography.caption1.family,
          fontWeight: theme.v2.typography.caption1.weight,
          fontSize: theme.v2.typography.caption1.size,
          color: theme.v2.color.onSecondary,
        },
        seeAll: {
          fontFamily: theme.v2.typography.caption1.family,
          fontWeight: theme.v2.typography.caption1.weight,
          fontSize: theme.v2.typography.caption1.size,
          color: theme.v2.color.onSecondary,
        },
      },
      searchPage: {
        header: {
          fontFamily: theme.v2.typography.title6.family,
          fontWeight: theme.v2.typography.title6.weight,
          fontSize: theme.v2.typography.title6.size,
          color: theme.v2.color.onSecondary,
        },
        tabs: {
          tab: {
            fontFamily: theme.v2.typography.title5.family,
            fontWeight: theme.v2.typography.title5.weight,
            fontSize: {
              lg: theme.v2.typography.title5.size.lg,
              md: theme.v2.typography.title5.size.md,
              sm: "14px",
            },
            color: theme.v2.color.typography.onBackground,
            border: `3px solid ${theme.v2.color.secondary}`,
            buttonSelectedBackgroundColor: theme.v2.color.primary,
            buttonSelectedColor: theme.v2.color.onPrimary,
            buttonBorder: `1px solid ${theme.v2.color.primary}`,
          },
          separator: {
            color: theme.v2.color.base.grey["200"],
          },
        },
        results: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontWeight: theme.v2.typography.bodyText2.weight,
          fontSize: theme.v2.typography.bodyText2.size,
          color: theme.v2.color.typography.onBackground,
        },
        sort: {
          label: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontWeight: theme.v2.typography.bodyText2.weight,
            fontSize: theme.v2.typography.bodyText2.size,
            color: theme.v2.color.typography.onBackground,
          },
        },
        groupedProductsList: {
          elementsPerRow: {
            lg: 4,
            md: 3,
            sm: 1,
          },
        },
      },
    };
  }

  getTitleProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme, "v2.overrides.title"))};
    return {
      fontFamily: theme.v2.typography.title1.family,
      fontSize: {
        sm: theme.v2.typography.sizing["3xl"].lg,
        md: theme.v2.typography.sizing["4xl"].lg,
        lg: theme.v2.typography.sizing["4xl"].lg,
      },
      letterSpacing: {
        sm: theme.v2.typography.title1.letterSpacing,
        md: theme.v2.typography.title1.letterSpacing,
        lg: theme.v2.typography.title1.letterSpacing,
      },
      fontWeight: theme.v2.typography.primary.weight.heavy,
      color: theme.v2.color.onBackground,
      margin: 0,
      padding: 0,
      textTransform: "uppercase",
      backgroundColor: "transparent",

      "> span": ` {
        cursor: default;
        font-family: ${theme.v2.typography.body.family};
    
        &:hover {
          text-decoration: none;
        }
      }`,
    };
  }
}

memoizeClassMethods(FlightThemeProps);

export default FlightThemeProps;
