import React from "react";
import merge from "lodash/merge";
import useProductDetail from "@mock/hooks/useProductDetail";
import useTheme from "@mock/hooks/useTheme";
import {ProductInfo, Modal, media, ModalExternalContainer} from "@ui";
import styled from "styled-components";
import Link from "@mock/components/modules/Link";
import productDetailStyles, {
  separatorStyles,
  modalStyles,
} from "@themes/default/components/ProductDetail/styles";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";

export default function ProductDetail(props) {
  const {
    product,
    meta,
    onEmptyPlaceholderButtonClickHandler,
    EmptyPlaceholderImage,
    showSkeleton,
    PageHead,
    ProductDetailSchema,
    quantityPickerProps,
    ProductDetailHeaderActions,
    ProductDetailActions,
    defaultProductInfoProps,
    FlowerTypeIndicatorComponent,
    showProductDetailRecommendations,
    theme,
    site,
    styles,
  } = props;

  const {RecommendedProductsList} = useThemeComponents();

  return (
    <>
      <Container style={{height: meta?.error ? "100%" : "auto"}}>
        {!product && meta?.error && meta?.error.status === 404 && (
          <ProductInfo
            hasEmptyPlaceholder
            emptyPlaceholderProps={{
              onEmptyPlaceholderButtonClickHandler,
              emptyPlaceholderImage: EmptyPlaceholderImage,
            }}
          />
        )}
        {showSkeleton && <ProductInfo isSkeleton />}
        {product && (
          <>
            <PageHead
              priority={1}
              title={product.getName()}
              description={product.getDescription()}
              image={product.getMainImage()}
              schemas={<ProductDetailSchema product={product} />}
            />
            <ProductInfo
              styles={styles}
              LinkComponent={Link}
              quantityPicker={quantityPickerProps}
              FlowerTypeIndicatorComponent={FlowerTypeIndicatorComponent}
              NavigationActionsComponent={
                <ProductDetailHeaderActions styles={styles.navigationActions} />
              }
              ActionsComponent={<ProductDetailActions product={product} />}
              {...defaultProductInfoProps}
            />
          </>
        )}
      </Container>
      {showProductDetailRecommendations && product && product.getBrandName() && (
        <>
          <Separator styles={separatorStyles(theme, site)} />
          <BrandRecommendationsContainer>
            <RecommendedProductsList />
          </BrandRecommendationsContainer>
        </>
      )}
    </>
  );
}

export const ProductDetailModal = ({ProductDetailComponent, ...props}) => {
  const {
    site,
    openModal,
    product,
    meta,
    onEmptyPlaceholderButtonClickHandler,
    showSkeleton,
    quantityPickerProps,
    productInfoProps,
    ProductDetailSchema,
    PageHead,
    EmptyPlaceholderImage,
    ProductDetailHeaderActions,
    ProductDetailActions,
    showProductDetailRecommendations,
    flowerTypeIndicatorProps,
    FlowerTypeIndicatorComponent,
    config,
  } = useProductDetail({onAddItem: props.onRequestClose});

  const theme = useTheme();
  const styles = productDetailStyles(theme, site);
  const {onRequestClose, allowedToClose = true} = props;

  const defaultMainTags = {
    ...productInfoProps?.mainTags,
    flowerTypeIndicatorProps,
    flowerTypeBefore: config?.flowerTypeIndicator?.beforeTags,
  };

  const defaultProductInfoProps = {
    ...productInfoProps,
    mainTags: merge({}, productInfoProps.mainTags, defaultMainTags),
    separator: {
      top: config?.separators?.afterTitle,
      bottom: config?.separators?.afterPrices,
    },
    sizes: {position: config?.sizes?.position, content: productInfoProps?.sizes?.content},
  };

  const productDetailProps = {
    product,
    meta,
    onEmptyPlaceholderButtonClickHandler,
    EmptyPlaceholderImage,
    showSkeleton,
    PageHead,
    ProductDetailSchema,
    quantityPickerProps,
    ProductDetailHeaderActions,
    ProductDetailActions,
    defaultProductInfoProps,
    FlowerTypeIndicatorComponent,
    showProductDetailRecommendations,
    theme,
    site,
    styles,
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={onRequestClose}
      ContainerComponent={VerticalContainer}
      showCloseButton={allowedToClose}
      styles={modalStyles(theme, site)}
    >
      <div>
        <ProductDetailComponent {...productDetailProps} />
      </div>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const BrandRecommendationsContainer = styled.div`
  ${media.up("lg")} {
    margin-top: 24px;
  }

  padding-bottom: 24px;
  ${media.down("md")} {
    padding-bottom: 130px;
  }
`;

const Separator = styled.div`
  background-color: ${({styles}) => styles?.backgroundColor || "#ebebeb"};
  width: 100%;
  height: 2px;
  max-height: 2px;
`;

const VerticalContainer = styled(ModalExternalContainer)`
  display: block;
`;
