import useSite from "src/core/sites/hooks/useSite";
import useRouter from "src/core/common/hooks/useRouter";
import {useEffect} from "react";
import useDeal from "@deals/hooks/useDeal";
import usePromotionProducts from "@deals/hooks/usePromotionProducts";
import useThemeConfig from "src/themes/useThemeConfig";
import routes from "src/core/common/routes";

export default function useDealDetail() {
  const config = useThemeConfig();
  const site = useSite();
  const {dealType, deal: dealSlug} = useRouter().query;
  const router = useRouter();

  const {
    fetchDeal,
    clearDeal,
    state: {deal, loading, error},
  } = useDeal({dealType, dealId: dealSlug});

  const {fetchPromotionProducts, state: promotionProductsState} = usePromotionProducts();

  useEffect(() => {
    if (!deal) fetchDeal(dealType, dealSlug);
  }, [dealType, dealSlug]);

  useEffect(() => {
    if (deal) fetchPromotionProducts(deal.id, {reset: true});
  }, [deal]);

  useEffect(() => {
    return clearDeal;
  }, []);

  return {
    loading: loading,
    site: site,
    deal,
    showProductsList: dealType !== "rewards",
    onBack: router.safeBack,
    backToDealsPage: () => router.push({pathname: routes.deals}),
    notFound: error?.status === 404,
    products: {
      site,
      products: !promotionProductsState.noResults
        ? promotionProductsState.products
        : undefined,
      skeleton: promotionProductsState.showSkeleton,
      hasMore: promotionProductsState.hasMore,
      loading: promotionProductsState.loading,
      next: fetchPromotionProducts,
      ProductCardComponent: config.components.ProductCard,
      totalCount: promotionProductsState.totalCount,
      title: "Save with these products",
      description: "",
    },
  };
}
