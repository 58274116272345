import TymberAPIConfig from "../api/config";
import {v1} from "../api/utils";

const Endpoints = {
  v1: {
    promotions: () => v1(`store/deals/promotions/`),
    rewards: () => v1(`store/deals/rewards/`),
    promotion: id => v1(`store/deals/promotions/${id}/`),
    reward: id => v1(`store/deals/rewards/${id}/`),
  },
};

export function promotion(id) {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.promotion(id)).then(response => response.data);
}

export function reward(id) {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.reward(id)).then(response => response.data);
}

export function promotions(params, axiosInstance) {
  const axios = axiosInstance || TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.promotions(), {
      params,
    })
    .then(response => response.data);
}

export function rewards(params, axiosInstance) {
  const axios = axiosInstance || TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.rewards(), {
      params,
    })
    .then(response => response.data);
}
