import ProductsPage from "@themes/boost/pages/ProductsPage";
import PromotionalBanner from "@themes/boost/components/PromotionalBanner";
import ShowcaseDealsList from "@themes/boost/components/ShowcaseDealsList";
import defaultConfig from "@themes/default/config";
import ProductCard from "@themes/boost/components/ProductCard";
import ViewAllLink from "@themes/boost/components/ViewAllLink";
import CategoryBar from "@themes/boost/components/CategoryBar";
import Title from "@themes/boost/components/Title";
import BrandRecommendations from "@themes/boost/components/RecommendedProductsList";
import {getFlowerTypeIndicatorProps} from "@themes/boost/themeStyles/flowerType";
import Footer from "@themes/boost/components/Footer";

const config = {
  inherits: "default",
  components: {
    ProductCard: ProductCard,
    PromotionalBanner: PromotionalBanner,
    ShowcaseDealsList: ShowcaseDealsList,
    ViewAllLink: ViewAllLink,
    CategoryBar: CategoryBar,
    Title: Title,
    Footer: Footer,
    RecommendedProductsList: BrandRecommendations,
  },
  pages: {
    HomePage: ProductsPage,
  },
  options: {
    pages: {
      productDetail: {
        isModal: true,
        quantityPicker: {
          displayPrice: false,
        },
        flowerTypeIndicator: {
          beforeTags: false,
        },
        separators: {
          afterTitle: false,
          afterPrices: false,
        },
        sizes: {
          position: "bottom",
        },
      },
    },
    flowerType: {
      getFlowerTypeIndicatorProps,
    },
  },
};

export const components = {
  ...defaultConfig.components,
  ...defaultConfig.pages,

  ...config.components,
  ...config.pages,
};

export default config;
