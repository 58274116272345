import {TheColorFulTitle} from "src/themes/colorful/elements/Title";
import ProductDetail from "src/themes/colorful/components/ProductDetail";
import TheColorFulBrandHeader from "src/themes/colorful/elements/BrandHeader";
import {TitleCard} from "@ui";
import TheColorFulHeader from "src/themes/colorful/components/Header";
import ColorFulShowCaseSection from "src/themes/colorful/elements/ShowCaseSection/ShowCaseSection";
import BoostShowCaseProductsList from "src/themes/baseTheme/components/ShowCaseProductsList";
import TheColorFulProductsList from "src/themes/colorful/components/ProductsList/ProductsList";
import TheColorFulProductCard from "src/themes/colorful/components/ProductCard";
import TheColorFulViewAllLink from "src/themes/colorful/elements/ViewAllLink/ViewAllLink";
import ColorFulCategorizedProductsBrowser from "src/themes/colorful/components/CategorizedProductsBrowser";
import TheColorFulDeliveryBanner from "src/themes/colorful/components/DeliveryBanner";
import ColourfulBrandPage from "./pages/BrandPage";
import ColourfulBasePage from "./pages/BasePage";
import {lazy} from "react";
import suspensify from "@ui/utils/suspensify";
import {getFlowerTypeIndicatorProps} from "src/themes/colorful/elements/flowerType";

const TheColorFulModal = suspensify(
  lazy(() =>
    import("src/themes/colorful/elements/Modal").then(module => ({
      default: module.TheColorFulModal,
    }))
  )
);

export default {
  inherits: "baseTheme",
  components: {
    Header: TheColorFulHeader,
    ProductsList: TheColorFulProductsList,
    ProductCard: TheColorFulProductCard,
    ShowCasedProductsList: BoostShowCaseProductsList,
    Title: TheColorFulTitle,
    CategoryCard: TitleCard,
    DeliveryBanner: TheColorFulDeliveryBanner,
    ViewAllLink: TheColorFulViewAllLink,
    ProductDetail,
    Modal: TheColorFulModal,
    BrandHeader: TheColorFulBrandHeader,
    ShowCaseSection: ColorFulShowCaseSection,
    CategorizedProductsBrowser: ColorFulCategorizedProductsBrowser,
  },
  pages: {
    BrandPage: ColourfulBrandPage,
    BasePage: ColourfulBasePage,
  },
  options: {
    flowerType: {getFlowerTypeIndicatorProps},
  },
};
