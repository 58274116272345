import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import Skeleton from "react-loading-skeleton";
import media from "@ui/utils/media";
import ConfigurableImage from "../ConfigurableImage";
import {TrashIcon} from "@ui/components/Icons";
import SelectField from "@ui/components/SelectField";
import {RoundedInfoIcon} from "@ui/components/IconsSVGs";

export default function CheckoutProductItem({
  styles,
  product,
  onRemoveProduct,
  onProductIncrement,
  onProductDecrement,
  onUpdateWeight,
  ErrorIcon,
  loading,
  readonly,
  skeleton,
}) {
  const _styles = merge({}, defaultStyles, styles);

  if (skeleton) {
    return (
      <Container styles={_styles.container}>
        <ProductImageContainerSkeleton styles={_styles.productImageContainer} />
        <ProductInfoContainer styles={_styles.productInfoContainer}>
          <BrandNameSkeleton />
          <ProductNameSkeleton />
          <ProductSizeSkeleton />
        </ProductInfoContainer>
        <ProductPriceContainer styles={_styles.productPriceContainer}>
          <ProductPriceSkeleton />
        </ProductPriceContainer>
      </Container>
    );
  }

  const {
    imageUrl,
    name,
    brandName,
    quantity,
    isWeighted,
    size,
    weightPrices,
    price,
    discountedPrice,
    error,
  } = product;

  const sizeOptions = weightPrices?.map(opt => ({
    label: opt.label,
    value: opt,
  }));
  const selectedWeight = sizeOptions?.find(option => option.label === size);

  return (
    <Container loading={loading} styles={_styles.container}>
      <ProductImageContainer styles={_styles.productImageContainer}>
        <ProductImage
          styles={_styles.productImage}
          source={imageUrl}
          height={parseInt(_styles.productImageContainer.height.lg.replace("px", ""))}
        />
      </ProductImageContainer>
      <ProductInfoContainer styles={_styles.productInfoContainer}>
        <BrandName styles={_styles.brandName}>
          {brandName}{" "}
          {error && (
            <ErrorContainer>
              <ErrorIcon width={"13px"} height={"13px"} />
              <ErrorMessage styles={_styles.errorMessage}>{error.message}</ErrorMessage>
            </ErrorContainer>
          )}
        </BrandName>
        <ProductName styles={_styles.productName}>{name}</ProductName>
        <ProductSize styles={_styles.productSize}>
          {readonly ? (
            <>
              {!size || quantity > 1 ? (
                <QuantityDisplay styles={_styles.itemQuantity}>
                  {quantity} {quantity === 1 ? "unit" : "units"}
                </QuantityDisplay>
              ) : (
                <QuantityDisplay styles={_styles.itemQuantity}>
                  {!!size && size}
                </QuantityDisplay>
              )}
            </>
          ) : (
            <>
              {isWeighted ? (
                <WeightPicker styles={_styles.itemQuantity}>
                  <SelectField
                    value={selectedWeight}
                    options={sizeOptions}
                    onChange={onUpdateWeight}
                    classNamePrefix="Dropdown"
                  />
                </WeightPicker>
              ) : (
                <QuantityPicker styles={_styles.itemQuantity}>
                  {quantity > 1 ? (
                    <QuantityHandler onClick={onProductDecrement}>-</QuantityHandler>
                  ) : (
                    <CustomTrashIcon
                      onClick={onRemoveProduct}
                      color={_styles.itemQuantity.color}
                    />
                  )}
                  <QuantityValue styles={_styles.itemQuantity}>{quantity}</QuantityValue>
                  <QuantityHandler onClick={onProductIncrement}>+</QuantityHandler>
                </QuantityPicker>
              )}
              <RemoveButton onClick={onRemoveProduct} styles={_styles.removeButton}>
                Remove
              </RemoveButton>
            </>
          )}
        </ProductSize>
      </ProductInfoContainer>
      <ProductPriceContainer styles={_styles.productPriceContainer}>
        {discountedPrice && (
          <ProductDiscountedPrice styles={_styles.productDiscountedPrice}>
            {discountedPrice}
          </ProductDiscountedPrice>
        )}
        <ProductPrice crossed={!!discountedPrice} styles={_styles.productPrice}>
          {price}
        </ProductPrice>
      </ProductPriceContainer>
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "checkout-product-item",
}))`
  display: flex;
  align-items: center;
  gap: ${({styles}) => styles.gap};
  opacity: ${({loading, styles}) => (loading ? styles.loadingOpacity : 1)};
  pointer-events: ${({loading}) => (loading ? "none" : "auto")};
`;

const ProductImageContainer = styled.div.attrs(() => ({
  className: "checkout-product-item__image-container",
}))`
  position: relative;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};

  ${media.up("lg")} {
    width: ${({styles}) => styles.width.lg};
    height: ${({styles}) => styles.height.lg};
  }

  ${media.between("md", "lg")} {
    width: ${({styles}) => styles.width.md};
    height: ${({styles}) => styles.height.md};
  }

  ${media.down("sm")} {
    width: ${({styles}) => styles.width.sm};
    height: ${({styles}) => styles.height.sm};
  }
`;

const ProductImageContainerSkeleton = styled(Skeleton)`
  position: relative;
  border-radius: ${({styles}) => styles.borderRadius};

  ${media.up("lg")} {
    width: ${({styles}) => styles.width.lg};
    height: ${({styles}) => styles.height.lg};
  }

  ${media.between("md", "lg")} {
    width: ${({styles}) => styles.width.md};
    height: ${({styles}) => styles.height.md};
  }

  ${media.down("sm")} {
    width: ${({styles}) => styles.width.sm};
    height: ${({styles}) => styles.height.sm};
  }
`;

const ProductImage = styled(ConfigurableImage).attrs(() => ({
  className: "checkout-product-item__image",
}))`
  object-fit: ${({styles}) => styles.objectFit};
  border-radius: ${({styles}) => styles.borderRadius};

  ${media.up("lg")} {
    width: ${({styles}) => styles.width.lg};
    height: ${({styles}) => styles.height.lg};
  }

  ${media.between("md", "lg")} {
    width: ${({styles}) => styles.width.md};
    height: ${({styles}) => styles.height.md};
  }

  ${media.down("sm")} {
    width: ${({styles}) => styles.width.sm};
    height: ${({styles}) => styles.height.sm};
  }
`;

const QuantityPicker = styled.div.attrs(() => ({
  className: "checkout-product-item__quantity-picker",
}))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;
  padding: 0 4px;

  background: ${({styles}) => styles.background};
  border-radius: ${({styles}) => styles.borderRadius};

  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    width: ${({styles}) => styles.width.lg};
    height: ${({styles}) => styles.height.lg};
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    width: ${({styles}) => styles.width.md};
    height: ${({styles}) => styles.height.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    width: ${({styles}) => styles.width.sm};
    height: ${({styles}) => styles.height.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const QuantityValue = styled.div.attrs(() => ({
  className: "checkout-product-item__quantity-value",
}))`
  color: ${({styles}) => styles.quantityColor};
`;

const QuantityDisplay = styled.div.attrs(() => ({
  className: "checkout-product-item__quantity-display",
}))`
  background: ${({styles}) => styles.background};
  border-radius: ${({styles}) => styles.borderRadius};

  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const QuantityHandler = styled.div.attrs(() => ({
  className: "checkout-product-item__quantity-picker__handler",
}))`
  cursor: pointer;
  width: 24px;
  text-align: center;

  &:active {
    filter: brightness(0.5);
  }
`;

const CustomTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;

const WeightPicker = styled.div.attrs(() => ({
  className: "checkout-product-item__weight-picker",
}))`
  margin-bottom: 3px;

  background: ${({styles}) => styles.background};
  border-radius: ${({styles}) => styles.borderRadius};
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  & .Dropdown__control {
    min-height: ${({styles}) => styles.height.lg};
    height: ${({styles}) => styles.height.lg};
    background-color: ${({styles}) => styles.background};
    border-color: ${({styles}) => styles.background};
    color: ${({styles}) => styles.color};
    font-family: ${({styles}) => styles.fontFamily};
    font-style: ${({styles}) => styles.fontStyle};
    font-weight: ${({styles}) => styles.fontWeight};
    line-height: ${({styles}) => styles.lineHeight};
  }

  & .Dropdown__single-value {
    color: ${({styles}) => styles.color};
  }

  & .Dropdown__input-container {
    color: ${({styles}) => styles.color};
    padding: 0;
    margin: 0;
  }

  & .Dropdown__value-container {
    color: ${({styles}) => styles.color};
    padding: 2px 0 0 0;
  }

  & .Dropdown__indicator-separator {
    background-color: ${({styles}) => styles.background};
  }

  & .Dropdown__dropdown-indicator {
    color: ${({styles}) => styles.color};
    padding: 0;
  }

  & .Dropdown__menu {
    background-color: ${({styles}) => styles.background};
    color: ${({styles}) => styles.color};
  }

  & .Dropdown__option {
    padding: 4px 4px;
  }

  & .Dropdown__option--is-focused {
    color: #000;
  }

  & .Dropdown__option--is-selected {
    color: #000;
  }

  ${media.up("lg")} {
    max-width: calc(${({styles}) => styles.width.lg} + 36px);
    height: ${({styles}) => styles.height.lg};
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    max-width: calc(${({styles}) => styles.width.lg} + 36px);
    height: ${({styles}) => styles.height.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    max-width: calc(${({styles}) => styles.width.lg} + 36px);
    height: ${({styles}) => styles.height.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ProductInfoContainer = styled.div.attrs(() => ({
  className: "checkout-product-item__product-info-container",
}))`
  display: flex;
  flex-direction: column;
  gap: ${({styles}) => styles.gap};
`;

const BrandName = styled.div.attrs(() => ({
  className: "checkout-product-item__brand-name",
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8px;

  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const BrandNameSkeleton = styled(Skeleton)`
  width: 80px;
  height: 18px;
`;

const ErrorContainer = styled.div.attrs(() => ({
  className: "checkout-product-item__product-error-container",
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 4px;
`;

const ErrorMessage = styled.span.attrs(() => ({
  className: "checkout-product-item__product-error-message",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ProductName = styled.div.attrs(() => ({
  className: "checkout-product-item__product-name",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ProductNameSkeleton = styled(Skeleton)`
  width: 100px;
  height: 18px;
`;

const ProductSize = styled.div.attrs(() => ({
  className: "checkout-product-item__product-size",
}))`
  display: flex;
  flex-direction: row;
  gap: ${({styles}) => styles.gap};

  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ProductSizeSkeleton = styled(Skeleton)`
  width: 50px;
  height: 18px;
`;

const RemoveButton = styled.button.attrs(() => ({
  className: "checkout-product-item__remove-button",
}))`
  border: none;
  background-color: inherit;
  cursor: ${({disabled}) => (disabled ? "unset" : "pointer")};

  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    padding: ${({styles}) => styles.padding.sm};
  }

  &:hover {
    text-decoration: ${({styles, disabled}) =>
      disabled ? "none" : styles.hover.textDecoration};
  }
`;

const ProductPriceContainer = styled.div.attrs(() => ({
  className: "checkout-product-item__product-price-container",
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  flex: 1;
  gap: ${({styles}) => styles.gap};
`;

const ProductPrice = styled.div.attrs(() => ({
  className: "checkout-product-item__product-price",
}))`
  text-decoration: ${({crossed}) => (crossed ? "line-through" : "none")};
  color: ${({crossed, styles}) =>
    crossed && styles.crossed ? styles.crossed.color : styles.color};
  font-family: ${({crossed, styles}) =>
    crossed && styles.crossed ? styles.crossed.fontFamily : styles.fontFamily};
  font-style: ${({crossed, styles}) =>
    crossed && styles.crossed ? styles.crossed.fontStyle : styles.fontStyle};
  font-weight: ${({crossed, styles}) =>
    crossed && styles.crossed ? styles.crossed.fontWeight : styles.fontWeight};
  line-height: ${({crossed, styles}) =>
    crossed && styles.crossed ? styles.crossed.lineHeight : styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({crossed, styles}) =>
      crossed && styles.crossed ? styles.crossed.fontSize.lg : styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    font-size: ${({crossed, styles}) =>
      crossed && styles.crossed ? styles.crossed.fontSize.md : styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({crossed, styles}) =>
      crossed && styles.crossed ? styles.crossed.fontSize.sm : styles.fontSize.sm};
  }
`;

const ProductDiscountedPrice = styled.div.attrs(() => ({
  className: "checkout-product-item__product-discounted-price",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ProductPriceSkeleton = styled(Skeleton)`
  width: 50px;
  height: 40px;
`;

const defaultStyles = {
  container: {
    gap: "14px",
    loadingOpacity: 0.4,
  },
  productImageContainer: {
    width: {
      sm: "64px",
      md: "64px",
      lg: "64px",
    },
    height: {
      sm: "64px",
      md: "64px",
      lg: "64px",
    },
    border: "1px solid #d9d9d9",
    borderRadius: "5px",
  },
  productImage: {
    objectFit: "cover",
    borderRadius: "5px",
    width: {
      sm: "64px",
      md: "64px",
      lg: "64px",
    },
    height: {
      sm: "64px",
      md: "64px",
      lg: "64px",
    },
  },
  itemQuantity: {
    width: {
      sm: "35px",
      md: "35px",
      lg: "35px",
    },
    height: {
      sm: "21px",
      md: "21px",
      lg: "21px",
    },
    background: "#737373",
    borderRadius: "4px",
    color: "#ffffff",
    quantityColor: "#0B0B0B",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: {
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    lineHeight: "140%",
  },
  productInfoContainer: {
    gap: "0px",
  },
  brandName: {
    color: "#737373",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: {
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    lineHeight: "140%",
  },
  errorMessage: {
    color: "#BA0B15",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: {
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    lineHeight: "140%",
  },
  productName: {
    color: "#333333",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "16px",
    },
    lineHeight: "140%",
  },
  productSize: {
    color: "#737373",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: {
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    lineHeight: "140%",
    gap: "8px",
  },
  removeButton: {
    marginLeft: "8px",
    color: "#333333",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: {
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    padding: {
      sm: "0px",
      md: "0px",
      lg: "0px",
    },
    lineHeight: "140%",
    hover: {
      textDecoration: "underline",
    },
  },
  productPriceContainer: {
    gap: "0px",
  },
  productPrice: {
    color: "#333333",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    lineHeight: "140%",
    crossed: {
      color: "#737373",
      fontFamily: "sans-serif",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: {
        sm: "12px",
        md: "12px",
        lg: "12px",
      },
      lineHeight: "140%",
    },
  },
  productDiscountedPrice: {
    color: "#333333",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    lineHeight: "140%",
  },
};

CheckoutProductItem.defaultProps = {
  styles: defaultStyles,
  ErrorIcon: RoundedInfoIcon,
};

CheckoutProductItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    brandName: PropTypes.string,
    quantity: PropTypes.number,
    size: PropTypes.string,
    weightPrices: PropTypes.array,
    price: PropTypes.string,
    discountedPrice: PropTypes.string,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
  onRemoveProduct: PropTypes.func,
  onProductIncrement: PropTypes.func,
  onProductDecrement: PropTypes.func,
  onUpdateWeight: PropTypes.func,
  ErrorIcon: PropTypes.any,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  skeleton: PropTypes.bool,
  styles: PropTypes.shape({
    container: PropTypes.shape({
      gap: PropTypes.string,
    }),
    productImageContainer: PropTypes.shape({
      width: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      height: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      border: PropTypes.string,
      borderRadius: PropTypes.string,
    }),
    productImage: PropTypes.shape({
      objectFit: PropTypes.string,
      borderRadius: PropTypes.string,
      width: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      height: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
    }),
    productInfoContainer: PropTypes.shape({
      gap: PropTypes.string,
    }),
    brandName: PropTypes.shape({
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontStyle: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      lineHeight: PropTypes.string,
    }),
    errorMessage: PropTypes.shape({
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontStyle: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      lineHeight: PropTypes.string,
    }),
    productName: PropTypes.shape({
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontStyle: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      lineHeight: PropTypes.string,
    }),
    productSize: PropTypes.shape({
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontStyle: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      lineHeight: PropTypes.string,
      gap: PropTypes.string,
    }),
    removeButton: PropTypes.shape({
      marginLeft: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontStyle: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      padding: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      lineHeight: PropTypes.string,
      hover: PropTypes.shape({
        textDecoration: PropTypes.string,
      }),
    }),
    productPriceContainer: PropTypes.shape({
      gap: PropTypes.string,
    }),
    productPrice: PropTypes.shape({
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontStyle: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      lineHeight: PropTypes.string,
      crossed: PropTypes.shape({
        color: PropTypes.string,
        fontFamily: PropTypes.string,
        fontStyle: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontSize: PropTypes.shape({
          sm: PropTypes.string,
          md: PropTypes.string,
          lg: PropTypes.string,
        }),
        lineHeight: PropTypes.string,
      }),
    }),
    productDiscountedPrice: PropTypes.shape({
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontStyle: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      lineHeight: PropTypes.string,
    }),
  }),
};
