import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import responsiveStyle from "@ui/utils/responsiveStyle";
import fontStyles from "@ui/utils/fontStyles";
import Skeleton from "react-loading-skeleton";
import DealImagePlaceholder from "@ui/components/DealCard/deal-image-placeholder.jpg";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import media from "@ui/utils/media";
import Card from "@ui/components/Card/Card";
import CardHeader from "@ui/components/CardHeader";

export default function DealCard({
  skeleton,
  styles,
  image,
  title,
  type,
  zoomOnHover = true,
  LinkComponent,
  dealDetailUrl,
  showHeader = false,
  isFirst = false,
  headerProps = {},
}) {
  const _styles = merge({}, defaultStyles, styles);

  if (skeleton) {
    return <CustomSkeleton styles={_styles.root} />;
  }

  return (
    <HeaderContainer styles={_styles.root} zoomOnHover={zoomOnHover}>
      <CardHeader
        showHeader={showHeader}
        headerProps={headerProps}
        isFirst={isFirst}
        styles={_styles}
        LinkComponent={LinkComponent}
      />
      <Card styles={_styles.root} zoomOnHover={zoomOnHover} showHeader={showHeader}>
        <Content styles={_styles.root}>
          <LinkComponent to={dealDetailUrl}>
            {image ? (
              <Image source={image} styles={_styles} />
            ) : (
              <ImagePlaceholder src={DealImagePlaceholder} alt={title} styles={_styles} />
            )}
            <TextContainer styles={_styles.root}>
              <Type styles={_styles.type}>{type}</Type>
              {title && <Title styles={_styles.title}>{title}</Title>}
            </TextContainer>
          </LinkComponent>
        </Content>
      </Card>
    </HeaderContainer>
  );
}

const CustomSkeleton = styled(Skeleton).attrs(() => ({}))`
  ${media.up("lg")} {
    min-width: ${({styles}) => styles.minWidth.lg};
    max-width: ${({styles}) => styles.maxWidth.lg};
  }

  ${media.down("md")} {
    min-width: ${({styles}) => styles.minWidth.md};
    max-width: ${({styles}) => styles.maxWidth.md};
  }

  ${media.down("sm")} {
    min-width: ${({styles}) => styles.minWidth.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
  }

  height: 225px;
  ${({styles}) => responsiveStyle("border-radius", styles.borderRadius)};
`;

const HeaderContainer = styled.div.attrs(() => ({
  className: "product-card__header-container",
  "data-keep-cart": "true",
}))`
  position: relative;
  min-height: 0 !important;

  > * {
    box-sizing: border-box;
    width: 100%;
  }

  ${media.up("lg")} {
    min-width: ${({styles}) => styles.minWidth.lg};
    max-width: ${({styles}) => styles.maxWidth.lg};
  }

  ${media.down("md")} {
    min-width: ${({styles}) => styles.minWidth.md};
    max-width: ${({styles}) => styles.maxWidth.md};
  }

  ${media.down("sm")} {
    min-width: ${({styles}) => styles.minWidth.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
  }

  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  &:hover {
    ${media.up("md")} {
      transform: scale(${({zoomOnHover}) => (zoomOnHover ? 1.0 : 1)});
    }
  }
`;

const Content = styled.div`
  ${({styles}) => responsiveStyle("padding", styles.padding)};
  box-sizing: border-box;
  height: 225px;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
`;

const Image = styled(ConfigurableImage)`
  object-fit: contain;
  height: 125px;
  width: 100%;
`;

const ImagePlaceholder = styled.img`
  object-fit: contain;
  height: 125px;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  row-gap: 5px;
`;

const Type = styled.div`
  ${({styles}) => fontStyles(styles)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;

const Title = styled.div`
  ${({styles}) => fontStyles(styles)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const defaultStyles = {
  root: {
    minWidth: {
      lg: "150px",
      md: "150px",
      sm: "130px",
    },
    maxWidth: {
      lg: "250px",
      md: "250px",
      sm: "250px",
    },
    padding: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    borderRadius: "10px",
    boxShadow: "0px 16px 40px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.12);",
    border: "0",
    backgroundColor: "#fff",
  },
  header: {
    root: {
      backgroundColor: "#D03A2B",
      borderRadius: "10px",
    },
    title: {
      fontFamily: "sans-serif",
      fontWeight: "500",
      color: "#fff",
      fontSize: {
        lg: "22px",
        md: "22px",
        sm: "22px",
      },
    },
    arrow: {
      color: "#fff",
    },
  },
  type: {
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    fontWeight: "600",
    fontFamily: "sans-serif",
    color: "#272727",
  },
  title: {
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: "400",
    fontFamily: "sans-serif",
    color: "rgba(0, 0, 0, 0.5)",
  },
};

DealCard.defaultProps = {
  styles: defaultStyles,
};

DealCard.defaultPropTypes = {
  styles: {control: "object"},
  type: {control: "text"},
  title: {control: "text"},
};

DealCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  skeleton: PropTypes.bool,
  zoomOnHover: PropTypes.bool,
  showHeader: PropTypes.bool,
  isFirst: PropTypes.bool,
  headerProps: PropTypes.object,
  LinkComponent: PropTypes.elementType,
  dealDetailUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  styles: PropTypes.shape({
    root: PropTypes.shape({
      minWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      maxWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      borderRadius: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      boxShadow: PropTypes.string,
      border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      backgroundColor: PropTypes.string,
    }),
    header: PropTypes.shape({
      root: PropTypes.shape({
        backgroundColor: PropTypes.string,
        borderRadius: PropTypes.string,
      }),
      title: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
      arrow: PropTypes.shape({
        color: PropTypes.string,
      }),
    }),
    type: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      color: PropTypes.string,
    }),
    title: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
};

DealCard.defaultProps = {
  LinkComponent: ({to, children}) => <a href={to}>{children}</a>,
};
