import merge from "lodash/merge";
import get from "lodash/get";
import applyShadow from "@ui/utils/applyShadow";
import BaseThemeProps from "src/themes/baseTheme/baseThemeProps";
import memoizeClassMethods from "@ui/utils/memoizeClassMethods";
import DefaultThemeProps from "@themes/default/themeStyles/defaultThemeProps";

class ColorfulThemeProps extends BaseThemeProps {
  constructor() {
    super();
    this.defaultThemeProps = new DefaultThemeProps();
  }

  getSelectionBarProps(_theme) {
    return this.defaultThemeProps.getSelectionBarProps(_theme);
  }

  getHeaderProps(theme) {
    return {
      root: {
        backgroundColor: theme.v2.color.surface,
        borderBottomColor: "transparent",
        color: theme.v2.color.base.grey["900"],
        fontWeight: theme.v2.typography.title6.weight,
        fontSize: theme.v2.typography.title6.size,
        fontFamily: theme.v2.typography.title6.family,
        textTransform: theme.v2.typography.title6.textCase,
        letterSpacing: theme.v2.typography.title6.letterSpacing,
      },
      logo: {
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "120px",
        },
      },
    };
  }

  getHeaderLoginButtonProps(theme) {
    const loginButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.loginButton")),
    };
    return {
      borderRadius: loginButtonTheme.v2.decoration.border.radius.default,
      outlineColor: loginButtonTheme.v2.color.primary,
      textTransform: loginButtonTheme.v2.typography.title6.textCase,
      fontFamily: loginButtonTheme.v2.typography.title6.family,
      padding: "1px 10px",
    };
  }

  getHeaderSearchButtonProps(theme) {
    const searchButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.searchButton")),
    };
    return {
      outlineColor: searchButtonTheme.v2.color.background,
      highlightColor: searchButtonTheme.v2.color.primary,
      borderRadius: searchButtonTheme.v2.decoration.border.radius.default,
      color: searchButtonTheme.v2.color.surface,
    };
  }

  getHeaderCartButtonProps(theme) {
    const cartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.cartButton")),
    };
    return {
      borderRadius: cartButtonTheme.v2.decoration.border.radius.default,
      highlightColor: cartButtonTheme.v2.color.primary,
    };
  }

  getHeaderCartBadgeProps(theme) {
    return {
      color: theme.v2.color.onError,
      backgroundColor: theme.v2.color.error,
      position: "absolute",
      fontSize: theme.v2.typography.title6.size,
      letterSpacing: "0",
      top: {
        lg: "-8px",
        md: "-8px",
        sm: "-8px",
      },
      right: {
        lg: "-24px",
        md: "-24px",
        sm: "-14px",
      },
      width: {
        lg: "20px",
        md: "20px",
        sm: "16px",
      },
      height: {
        lg: "20px",
        md: "20px",
        sm: "16px",
      },
    };
  }

  getAnnouncementMessageProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.announcementBox)};
    const buttonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides?.announcementBox?.button")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides?.announcementBox?.title")),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides?.announcementBox?.description")),
    };

    return {
      root: {
        fontFamily: theme.v2.typography.default?.family,
        fontSize: theme.v2.typography.default?.size,
        fontWeight: theme.v2.typography.default?.weight,
        color: theme.v2.color.typography.default,
        backgroundColor: theme.v2.color.surface,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      container: {
        backgroundColor: theme.v2.color.secondary,
      },
      title: {
        fontFamily: titleTheme.v2.typography.default?.family,
        fontSize: titleTheme.v2.typography.default?.size,
        fontWeight: titleTheme.v2.typography.default?.weight,
        color: titleTheme.v2.color.typography.default,
      },
      description: {
        fontFamily: descriptionTheme.v2.typography.default?.family,
        fontSize: descriptionTheme.v2.typography.default?.size,
        fontWeight: descriptionTheme.v2.typography.default?.weight,
        color: descriptionTheme.v2.color.typography.default,
      },
      button: {
        fontFamily: buttonTheme.v2.typography.default?.family,
        fontSize: buttonTheme.v2.typography.default?.size,
        fontWeight: buttonTheme.v2.typography.default?.weight,
        color: buttonTheme.v2.color.typography.default,
      },
    };
  }

  getDeliveryBannerProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.deliveryBanner"))};

    return {
      root: {
        backgroundColor: theme.v2.color.primary,
        fontFamily: theme.v2.typography.title6.family,
        fontWeight: theme.v2.typography.title6.weight,
        color: theme.v2.color.primary,
        fontSize: {
          lg: theme.v2.typography.title6.size.lg,
          md: theme.v2.typography.title6.size.lg,
          sm: theme.v2.typography.title6.size.md,
        },
        locationText: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontWeight: theme.v2.typography.bodyText2.weight,
          fontSize: {
            lg: theme.v2.typography.bodyText2.size.lg,
            md: theme.v2.typography.bodyText2.size.lg,
            sm: theme.v2.typography.bodyText2.size.md,
          },
        },
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    };
  }

  getDeliveryModePickerProps(_theme, showInventoryPicker) {
    const theme = {
      v2: merge(
        {},
        _theme.v2,
        get(_theme.v2, "overrides.deliveryBanner.deliveryModePicker")
      ),
    };

    const deliveryTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.deliveryBanner.deliveryMode")),
    };

    const pickupTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.deliveryBanner.deliveryModePicker.pickup")
      ),
    };

    return {
      backgroundColor: theme.v2.color.background,
      color: theme.v2.color.primary,
      iconColor: deliveryTheme.v2.color.primary,
      pickupColor: pickupTheme.v2.color.primary,
      locationColor: theme.v2.color.typography.bodyText1,
      hoverSelectedBackgroundColor: theme.v2.color.primaryVariant,
      hoverBackgroundColor: theme.v2.color.primary,
      hoverTextColor: theme.v2.color.onPrimary,
      selectedColor: theme.v2.color.surface,
      borderRadius: theme.v2.decoration.border.radius.default,
    };
  }

  getCategoryBarProps(_theme, options = {}) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.categoryBar"))};
    const categoryButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.categoryBar.categoryButton")),
    };
    const categoryImageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.categoryBar.categoryImage")),
    };

    return {
      root: {
        scroll: {
          lg: false,
          md: false,
          sm: true,
        },
        backgroundColor: theme.v2.color.base.grey["200"],
        minHeight: {
          lg: "70px",
          md: "50px",
          sm: "50px",
        },
        columnGap: {
          lg: `${theme.v2.spacing("s")}`,
          md: `${theme.v2.spacing("xs")}`,
          sm: `${theme.v2.spacing("xs")}`,
        },
        padding: {
          lg: `${theme.v2.spacing("xs")} 0`,
          md: `${theme.v2.spacing("xs")} 0`,
          sm: `${theme.v2.spacing("xs")} 0`,
        },
      },
      button: {
        root: {
          fontFamily: categoryButtonTheme.v2.typography.title6.family,
          fontStyle: categoryButtonTheme.v2.typography.title6.fontStyle,
          backgroundColor: categoryButtonTheme.v2.color.surface,
          color: categoryButtonTheme.v2.color.onSurface,
          selectedBackgroundColor: categoryButtonTheme.v2.color.primary,
          selectedColor: categoryButtonTheme.v2.color.onPrimary,
          fontWeight: categoryButtonTheme.v2.typography.title6.weight,
          padding: {
            lg: "8px 16px",
            md: "8px 16px",
            sm: "8px 16px",
          },
          fontSize: {
            lg: categoryButtonTheme.v2.typography.title6.size.lg,
            md: categoryButtonTheme.v2.typography.title6.size.md,
            sm: categoryButtonTheme.v2.typography.title6.size.sm,
          },
          textTransform: categoryButtonTheme.v2.typography.title6.textCase,
          border: "solid",
          borderWidth: get(categoryButtonTheme, "v2.decoration.width.xs"),
          borderColor: categoryButtonTheme.v2.color.background,
          borderRadius: categoryButtonTheme.v2.decoration.border.radius.default,
          height: {
            lg: "56px",
            md: "48px",
            sm: "48px",
          },
          boxShadow: options.buttonShadow
            ? applyShadow(get(categoryButtonTheme, "v2.decoration.shadow.s", []))
            : "none",
        },
        image: {
          size: {
            lg: "44px",
            md: "36px",
            sm: "36px",
          },
          borderRadius: categoryImageTheme.v2.decoration.border.radius.default,
        },
      },
    };
  }

  getProductCardProps(_theme, props = {}) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productCard)};
    const actionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.actions")),
    };
    const strikePriceTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.crossed")),
    };
    const imageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.image")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.flowerTypeIndicator")),
    };
    const rootTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.card")),
    };
    const headerTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.header")),
    };
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.potencyTag")),
    };
    const sizesTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.sizes")),
    };
    const infoTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.info")),
    };

    const buyTextStyles = {
      fontSize: {
        lg: actionsTheme.v2.typography.title6.size.lg,
        md: actionsTheme.v2.typography.title6.size.lg,
        sm: actionsTheme.v2.typography.title6.size.lg,
      },
      fontWeight: actionsTheme.v2.typography.title6.weight,
    };

    if (props.mode === "small") {
      buyTextStyles.color = "#ffffff";
    }

    return {
      header: {
        root: {
          backgroundColor: headerTheme.v2.color.primary,
          borderRadius: headerTheme.v2.decoration.border.radius.default,
        },
        title: {
          fontFamily: headerTheme.v2.typography.title1.family,
          fontSize: {
            lg: "22px",
            md: "20px",
            sm: "22px",
          },
          fontWeight: headerTheme.v2.typography.title1.weight,
        },
      },
      root: {
        ...this.getCardProps(theme, rootTheme).root,
        padding: {
          sm: props.isHorizontalInMobile && "8px 16px 8px 8px",
        },
        height: {
          lg: props.mode === "small" ? "320px" : "442px",
          md: props.mode === "small" ? "320px" : "442px",
          sm: props.isHorizontalInMobile
            ? "200px"
            : props.mode === "small"
            ? "320px"
            : "442px",
        },
        maxWidth: {
          lg: props.mode === "small" ? "290px" : "395px",
          md: `280px`,
          sm: props.isHorizontalInMobile ? "100%" : "280px",
        },
        minWidth: {
          lg: "155px",
          md: "155px",
          sm: props.isHorizontalInMobile
            ? "300px"
            : props.mode === "small"
            ? "100%"
            : "200px",
        },
      },
      image: {
        borderBottom: "0",
        borderRadius: imageTheme.v2.decoration.border.radius.default,
        height: {
          lg: props.mode === "small" ? "120px" : "175px",
          md: props.mode === "small" ? "120px" : "175px",
          sm: props.isHorizontalInMobile
            ? "155px"
            : props.mode === "small"
            ? "120px"
            : "175px",
        },
        minWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        maxWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        objectFit: "contain",
        padding: {
          lg: get(imageTheme, "v2.padding.lg", theme.v2.spacing(6)),
          md: get(imageTheme, "v2.padding.md", theme.v2.spacing(4)),
          sm: get(
            imageTheme,
            "v2.padding.sm",
            props.isHorizontalInMobile ? "0" : imageTheme.v2.spacing(2)
          ),
        },
      },
      flowerTypeIndicator: {
        borderRadius: theme.v2.decoration.border.radius.xs,
        padding: "6px 8px",
        position: {
          bottom: "",
          left: props.mode === "small" ? "auto" : "",
          right: props.mode === "small" ? "0" : "8px",
          top: props.isHorizontalInMobile || props.mode === "small" ? "0px" : "8px",
        },
        margin: {
          lg: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          md: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          sm: props.mode === "small" ? "0px 0px 0 20px" : "0",
        },
        fontSize: {
          lg: flowerTypeIndicatorTheme.v2.typography.title6.size.lg,
          md: flowerTypeIndicatorTheme.v2.typography.title6.size.sm,
          sm: flowerTypeIndicatorTheme.v2.typography.title6.size.sm,
        },
        fontWeight: flowerTypeIndicatorTheme.v2.typography.title6.weight,
        color: "#fff",
        textTransform: "none",
      },
      saleIndicator: {
        fontWeight: theme.v2.typography.title6.weight,
        borderRadius: theme.v2.decoration.border.radius.xs,
        fontFamily: theme.v2.typography.title6.family,
        fontSize: {
          lg: flowerTypeIndicatorTheme.v2.typography.title6.size.md,
          md: flowerTypeIndicatorTheme.v2.typography.title6.size.sm,
          sm: flowerTypeIndicatorTheme.v2.typography.title6.size.sm,
        },
        top: props.mode === "small" ? "0px" : "10px",
        margin: {
          lg:
            props.mode === "small"
              ? `${get(imageTheme, "v2.padding.lg", theme.v2.spacing(6))} 16px 0 16px`
              : `${get(
                  imageTheme,
                  "v2.padding.lg",
                  theme.v2.spacing(6)
                )} calc(8px + ${get(imageTheme, "v2.padding.lg", theme.v2.spacing(6))})`,
          md:
            props.mode === "small"
              ? "16px 16px 0 16px"
              : `${get(
                  imageTheme,
                  "v2.padding.md",
                  theme.v2.spacing(4)
                )} calc(8px + ${get(imageTheme, "v2.padding.md", theme.v2.spacing(4))})`,
          sm: props.mode === "small" ? "8px 10px 0 8px" : "0px",
        },
      },
      info: {
        padding: {
          lg: get(
            imageTheme,
            "v2.padding.lg",
            `${infoTheme.v2.spacing(2)} ${infoTheme.v2.spacing(6)} ${infoTheme.v2.spacing(
              6
            )}`
          ),
          md: get(
            imageTheme,
            "v2.padding.md",
            `${infoTheme.v2.spacing(2)} ${infoTheme.v2.spacing(4)} ${infoTheme.v2.spacing(
              4
            )}`
          ),
          sm: get(
            imageTheme,
            "v2.padding.sm",
            props.isHorizontalInMobile ? "0" : `${infoTheme.v2.spacing(2)}`
          ),
        },
      },
      brandName: {
        fontFamily: brandNameTheme.v2.typography.bodyText2.family,
        fontSize: {
          lg: brandNameTheme.v2.typography.bodyText2.size.lg,
          md: brandNameTheme.v2.typography.bodyText2.size.lg,
          sm: brandNameTheme.v2.typography.bodyText2.size.lg,
        },
        fontWeight: brandNameTheme.v2.typography.bodyText2.weight,
        color: brandNameTheme.v2.color.base.grey["600"],
        textCase: brandNameTheme.v2.typography.bodyText2.textCase,
      },
      productName: {
        fontFamily: productNameTheme.v2.typography.title6.family,
        fontSize: {
          lg: productNameTheme.v2.typography.title6.size.lg,
          md: productNameTheme.v2.typography.title6.size.lg,
          sm: productNameTheme.v2.typography.title6.size.lg,
        },
        fontWeight: productNameTheme.v2.typography.title6.weight,
        color: productNameTheme.v2.color.onSurface,
      },
      productSizes: {
        fontFamily: sizesTheme.v2.typography.bodyText1.family,
        fontSize: {
          lg: sizesTheme.v2.typography.bodyText1.size.lg,
          md: sizesTheme.v2.typography.bodyText1.size.lg,
          sm: sizesTheme.v2.typography.bodyText1.size.lg,
        },
        color: sizesTheme.v2.color.base.grey["600"],
        fontWeight: sizesTheme.v2.typography.body.weight.medium,
      },
      potencyTag: {
        fontFamily: potencyTagTheme.v2.typography.bodyText2.family,
        backgroundColor: potencyTagTheme.v2.color.secondary,
        border: 0,
        borderRadius: potencyTagTheme.v2.decoration.border.radius.xs,
        color: potencyTagTheme.v2.color.onSecondary,
        fontSize: {
          lg: potencyTagTheme.v2.typography.bodyText2.size.sm,
          md: potencyTagTheme.v2.typography.bodyText2.size.sm,
          sm: potencyTagTheme.v2.typography.bodyText2.size.sm,
        },
      },
      actionsContainer: {
        padding: {
          lg:
            props.mode === "small"
              ? "10px"
              : get(
                  imageTheme,
                  "v2.padding.lg",
                  `0 ${actionsTheme.v2.spacing(6)} ${actionsTheme.v2.spacing(
                    6
                  )} ${actionsTheme.v2.spacing(6)}`
                ),
          md: get(
            imageTheme,
            "v2.padding.md",
            `0 ${actionsTheme.v2.spacing(4)} ${actionsTheme.v2.spacing(
              4
            )} ${actionsTheme.v2.spacing(4)}`
          ),
          sm: get(
            imageTheme,
            "v2.padding.sm",
            props.isHorizontalInMobile
              ? "0"
              : `0 ${actionsTheme.v2.spacing(2)} ${actionsTheme.v2.spacing(
                  2
                )} ${actionsTheme.v2.spacing(2)}`
          ),
        },
      },
      actions: {
        root: {
          cartColor: actionsTheme.v2.color.onBackground,
          backgroundColor: actionsTheme.v2.color.primary,
          borderRadius: props.isHorizontalInMobile
            ? actionsTheme.v2.decoration.border.radius["3xl"]
            : actionsTheme.v2.decoration.border.radius.default,
          padding: {
            lg: "16px",
            md: "16px",
            sm: "10px",
          },
        },
        buyText: buyTextStyles,
        prices: {
          alignment: "column-reverse",
          fontSize: {
            lg: actionsTheme.v2.typography.title6.size.lg,
            md: actionsTheme.v2.typography.title6.size.lg,
            sm: actionsTheme.v2.typography.title6.size.lg,
          },
          color:
            props.mode === "small"
              ? actionsTheme.v2.color.primary
              : props.isHorizontalInMobile
              ? actionsTheme.v2.color.onSurface
              : actionsTheme.v2.color.onPrimary,
          fontWeight: actionsTheme.v2.typography.title6.weight,
          crossed: {
            color:
              props.mode === "small"
                ? strikePriceTheme.v2.color.primary
                : props.isHorizontalInMobile
                ? strikePriceTheme.v2.color.onSurface
                : strikePriceTheme.v2.color.onPrimary,
            fontSize: {
              lg: strikePriceTheme.v2.typography.title6.size.md,
              sm: strikePriceTheme.v2.typography.title6.size.sm,
            },
            fontWeight: strikePriceTheme.v2.typography.title5.weight,
          },
        },
      },
    };
  }

  getProductDetailProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail)};

    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.potencyTag")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.flowerTypeIndicator")
      ),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.description")),
    };
    const priceDisplayTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.priceDisplay")),
    };

    const quantityControllersTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.quantityControllers")
      ),
    };

    const navigationActionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.navigationActions")),
    };

    return {
      productDetail: {
        rowGap: "4px",
      },
      brand: {
        fontFamily: get(brandNameTheme, "v2.typography.title6.family"),
        fontSize: {
          lg: get(brandNameTheme, "v2.typography.title6.size.lg"),
          md: get(brandNameTheme, "v2.typography.title6.size.lg"),
          sm: get(brandNameTheme, "v2.typography.title6.size.lg"),
        },
        fontWeight: get(brandNameTheme, "v2.typography.title6.weight"),
        color: get(brandNameTheme, "v2.color.base.grey.700"),
        hoverTextDecoration: get(brandNameTheme, "v2.text.decorations.underline"),
      },
      name: {
        fontSize: get(productNameTheme, "v2.typography.title2.size"),
        fontWeight: get(productNameTheme, "v2.typography.title2.weight"),
        fontFamily: get(productNameTheme, "v2.typography.title2.family"),
        color: get(productNameTheme, "v2.color.onSurface"),
      },
      mainTagsContainer: {
        columnGap: "10px",
        margin: "10px 0 0 0",
      },
      flowerTypeIndicator: {
        color: get(flowerTypeIndicatorTheme, "v2.color.onBackground"),
        padding: `${get(flowerTypeIndicatorTheme, "v2.static.spacing.size.xs")} ${get(
          theme,
          "v2.static.spacing.size.s"
        )}`,
        border: "none",
        borderRadius: get(flowerTypeIndicatorTheme, "v2.decoration.border.radius.xs"),
        fontSize: {
          lg: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
          md: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
          sm: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
        },
        fontWeight: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.weight"),
        fontFamily: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.family"),
        textTransform: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.textCase"),
      },
      mainTags: {
        padding: {
          lg: `${get(potencyTagTheme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          md: `${get(potencyTagTheme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          sm: `${get(potencyTagTheme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
        },
        border: "none",
        borderRadius: get(potencyTagTheme, "v2.decoration.border.radius.xs"),
        backgroundColor: get(potencyTagTheme, "v2.color.secondary"),
        fontSize: {
          lg: get(potencyTagTheme, "v2.typography.bodyText1.size.lg"),
          md: get(potencyTagTheme, "v2.typography.bodyText1.size.lg"),
          sm: get(potencyTagTheme, "v2.typography.bodyText1.size.lg"),
        },
        fontWeight: get(potencyTagTheme, "v2.typography.bodyText1.weight"),
        fontFamily: get(potencyTagTheme, "v2.typography.bodyText1.family"),
        color: get(potencyTagTheme, "v2.color.onSecondary"),
      },
      description: {
        root: {
          fontFamily: get(descriptionTheme, "v2.typography.bodyText1.family"),
          lineHeight: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            md: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
          },
        },
        text: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
            md: get(descriptionTheme, "v2.typography.bodyText1.size.m"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.size.m"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.bodyText1.weight"),
          color: get(descriptionTheme, "v2.color.onSurface"),
        },
        button: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.title6.size.lg"),
            md: get(descriptionTheme, "v2.typography.title6.size.lg"),
            sm: get(descriptionTheme, "v2.typography.title6.size.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.title6.weight"),
        },
      },
      priceDisplay: {
        price: {
          fontFamily: get(theme, "v2.typography.title5.family"),
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.title5.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
        size: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight"),
          color: get(priceDisplayTheme, "v2.color.base.grey.600"),
        },
        pricePerUnit: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.caption1.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
      },
      quantityPicker: {
        root: {
          width: {
            sm: "100vw",
          },
          padding: {
            sm: `0 ${get(theme, "v2.static.spacing.size.l")}`,
          },
        },
        quantityPicker: {
          borderRadius: get(theme, "v2.decoration.border.radius.s"),
          backgroundColor: get(theme, "v2.color.base.grey.100"),
          quantityFamily: get(theme, "v2.typography.title4.family"),
          quantityWeight: get(theme, "v2.typography.title4.weight"),
          quantityColor: get(theme, "v2.color.typography.title6"),
          controllersColor: get(quantityControllersTheme, "v2.color.primary"),
          quantitySize: get(quantityControllersTheme, "v2.typography.title4.size"),
          controllersWidth: {
            lg: get(theme, "v2.static.spacing.size.xl"),
            md: get(theme, "v2.static.spacing.size.xl"),
            sm: get(theme, "v2.static.spacing.size.xl"),
          },
          controllersHeight: {
            lg: get(theme, "v2.static.spacing.size.xl"),
            md: get(theme, "v2.static.spacing.size.xl"),
            sm: get(theme, "v2.static.spacing.size.xl"),
          },
          minusIconSize: get(theme, "v2.static.spacing.size.xl"),
          plusIconSize: get(theme, "v2.static.spacing.size.xl"),
          removeItemIcon: {
            color: get(theme, "v2.color.primary"),
          },
        },
        addToCartButton: {
          borderColor: get(theme, "v2.color.onBackground"),
          backgroundColor: get(theme, "v2.color.primary"),
          color: get(theme, "v2.color.onPrimary"),
          borderRadius: get(theme, "v2.decoration.border.radius.s"),
          fontFamily: get(theme, "v2.typography.title5.family"),
          fontSize: get(theme, "v2.typography.title5.size"),
          fontWeight: get(theme, "v2.typography.title5.weight"),
        },
      },
      secondaryTags: {
        backgroundColor: get(theme, "v2.color.secondary"),
        color: get(theme, "v2.color.onSecondary"),
        borderRadius: get(theme, "v2.decoration.border.radius.s"),
        fontSize: {
          lg: get(theme, "v2.typography.bodyText1.size.lg"),
          md: get(theme, "v2.typography.bodyText1.size.lg"),
          sm: get(theme, "v2.typography.bodyText1.size.lg"),
        },
        fontWeight: get(theme, "v2.typography.bodyText1.weight"),
        textTransform: get(theme, "v2.text.case.capitalize"),
        padding: {
          lg: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          md: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          sm: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
        },
      },
      navigationActions: {
        backButton: {
          backgroundColor: navigationActionsTheme.v2.color.base.grey["400"],
          color: navigationActionsTheme.v2.color.base.grey["900"],
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
        cartButton: {
          backgroundColor: navigationActionsTheme.v2.color.primary,
          color: navigationActionsTheme.v2.color.onPrimary,
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
      },
    };
  }

  getProductDetailSeparatorProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail?.separator),
    };

    return {
      backgroundColor: get(theme, "v2.color.base.grey[200]"),
    };
  }

  getProductListProps(_theme, site) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productList)};

    return {
      root: {
        elementsPerRow: {
          lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
          md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
          sm: 1,
        },
        padding: {
          lg: "0 32px",
          md: "0 16px",
          sm: "0 16px",
        },
      },
      element: {},
      header: {
        title: {
          fontFamily: theme.v2.typography.title1.family,
          color: theme.v2.color.primary,
          fontWeight: theme.v2.typography.title1.weight,
          textTransform: theme.v2.text.decorations.none,
          fontSize: {
            lg: theme.v2.typography.title1.size.lg,
            md: theme.v2.typography.title1.size.md,
            sm: theme.v2.typography.title1.size.md,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: theme.v2.typography.bodyText1.family,
            fontSize: {
              lg: theme.v2.typography.bodyText1.size.lg,
              md: theme.v2.typography.bodyText1.size.md,
              sm: theme.v2.typography.bodyText1.size.md,
            },
            lineHeight: {
              lg: theme.v2.typography.bodyText1.lineHeight,
              md: theme.v2.typography.bodyText1.lineHeight,
              sm: theme.v2.typography.bodyText1.lineHeight,
            },
          },
          button: {
            fontWeight: theme.v2.typography.title6.weight,
            color: theme.v2.color.onBackground,
          },
          text: {
            fontWeight: theme.v2.typography.bodyText1.weight,
            color: theme.v2.color.onBackground,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
    };
  }

  getNoResultsPlaceholderProps(theme) {
    return {
      root: {
        padding: {
          lg: "24px 8px",
          md: "24px 8px",
          sm: "24px 8px",
        },
      },
      logo: {
        backgroundColor: theme.v2.color.primary,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      titleText: {
        fontFamily: theme.v2.typography.title1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.l,
      },
      detailText: {
        fontFamily: theme.v2.typography.bodyText1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.m,
      },
    };
  }

  getShowcasedProductListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.showcasedProductsList)};

    return {
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 20px 16px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "column",
        },
      },
      element: {
        maxWidth: {
          lg: "320px",
          md: "250px",
          sm: "100%",
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "330px",
        },
      },
      header: {
        title: {
          fontFamily: theme.v2.typography.title1.family,
          color: theme.v2.color.primary,
          fontWeight: theme.v2.typography.title1.weight,
          textTransform: "uppercase",
          fontSize: {
            lg: theme.v2.typography.title1.size.lg,
            md: theme.v2.typography.title1.size.md,
            sm: theme.v2.typography.title1.size.md,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: theme.v2.typography.bodyText1.family,
            fontSize: {
              lg: theme.v2.typography.bodyText1.size.lg,
              md: theme.v2.typography.bodyText1.size.md,
              sm: theme.v2.typography.bodyText1.size.md,
            },
            lineHeight: {
              lg: theme.v2.typography.bodyText1.lineHeight,
              md: theme.v2.typography.bodyText1.lineHeight,
              sm: theme.v2.typography.bodyText1.lineHeight,
            },
          },
          button: {
            fontWeight: theme.v2.typography.title1.weight,
            color: theme.v2.color.onBackground,
          },
          text: {
            fontWeight: theme.v2.typography.bodyText1.weight,
            color: theme.v2.color.onBackground,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
      indicator: {
        indicator: {
          color: theme.v2.color.primary,
          inactiveColor: theme.v2.color.primaryVariant,
        },
      },
      navigationButton: {
        root: {
          width: "60px",
          height: "124px",
          backgroundColor: "rgb(224, 224, 224)",
          color: theme.v2.color.onBackground,
          borderRadius: "0px 50px 50px 0px",
          transform: "rotate(180deg)",
        },
      },
    };
  }

  getShowcasedProductListCategoryCardProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.categoryCard)};

    return {
      root: {
        height: "442px",
        backgroundColor: theme.v2.color.primary,
        boxShadow: "rgb(0 0 0 / 20%) 0px 0px 10px -3px",
        borderRadius: theme.v2.decoration.border.radius.default,
        padding: {
          lg: "24px 12px 40px 32px",
          md: "24px 12px 26px 18px",
          sm: "24px 12px 26px 18px",
        },
        minWidth: {
          sm: "300px",
        },
        maxWidth: {
          sm: "368px",
        },
      },
      title: {
        fontFamily: theme.v2.typography.title1.family,
        fontSize: theme.v2.typography.title1.size,
        fontWeight: theme.v2.typography.title1.weight,
        fontStyle: "italic",
        color: theme.v2.color.onPrimary,
        textTransform: theme.v2.typography.title1.textCase,
        maxLines: {
          lg: 3,
          md: 3,
          sm: 1,
        },
      },
      arrow: {
        color: theme.v2.color.onPrimary,
        size: {
          lg: "50px",
          md: "40px",
          sm: "40px",
        },
      },
    };
  }

  getDealTitleCardProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.dealTitleCard)};

    return {
      ...this.getShowcasedProductListCategoryCardProps(theme),
      root: {
        ...this.getShowcasedProductListCategoryCardProps(theme).root,
        height: "229px",
      },
    };
  }

  getAppliedFiltersListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.appliedFilters)};

    return {
      root: {
        margin: `0 ${theme.v2.spacing(4)} ${theme.v2.spacing(1)} 0`,
      },
      container: {
        backgroundColor: theme.v2.color.secondary,
        color: theme.v2.color.onSecondary,
      },
      text: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: {
          lg: theme.v2.typography.bodyText1.size.lg,
          md: theme.v2.typography.bodyText1.size.md,
          sm: theme.v2.typography.bodyText1.size.sm,
        },
        fontWeight: theme.v2.typography.bodyText1.weight,
      },
      closeIcon: {
        color: theme.v2.color.background,
      },
    };
  }

  getSortFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.sortFilters"))};
    const optionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.sortFilters.options")),
    };
    const borderTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.sortFilters.border")),
    };

    return {
      root: {
        padding: {
          lg: `0 0 ${theme.v2.spacing(8)} ${theme.v2.spacing(8)}`,
          md: `0 0 ${theme.v2.spacing(4)} ${theme.v2.spacing(4)}`,
        },
      },
      sort: {
        root: {
          width: {
            lg: "190px",
            sm: "190px",
          },
          control: {
            borderColor: borderTheme.v2.color.onBackground,
            borderRadius: theme.v2.decoration.border.radius.default,
            borderWidth: {
              lg: "3px",
              md: "3px",
              sm: "0",
            },
            color: "black",
            backgroundColor: "white",
            fontFamily: theme.v2.typography.title6.family,
            fontSize: {
              lg: theme.v2.typography.title6.size.md,
            },
            fontWeight: theme.v2.typography.title6.weight,
            lineHeight: theme.v2.typography.title6.lineHeight,
            hover: {
              borderColor: borderTheme.v2.color.onBackground,
            },
          },
          menu: {
            borderRadius: theme.v2.decoration.border.radius.default,
            backgroundColor: theme.v2.color.onBackground,
            borderColor: borderTheme.v2.color.background,
            margin: `${theme.v2.static.spacing.size.xs} 0`,
          },
          options: {
            fontFamily: optionsTheme.v2.typography.title6.family,
            fontSize: {
              lg: optionsTheme.v2.typography.title6.size.md,
            },
            fontWeight: optionsTheme.v2.typography.title6.weight,
            hover: {
              backgroundColor: optionsTheme.v2.color.onBackground,
              color: optionsTheme.v2.color.background,
            },
            color: theme.v2.color.background,
            selected: {
              backgroundColor: optionsTheme.v2.color.onBackground,
              color: optionsTheme.v2.color.background,
            },
          },
          arrow: {
            borderColor: `${theme.v2.color.background} transparent transparent`,
            open: {
              borderColor: `transparent transparent ${theme.v2.color.background}`,
            },
          },
        },
      },
      filters: {
        button: {
          root: {
            fontFamily: theme.v2.typography.title6.family,
            fontSize: theme.v2.typography.title6.size.md,
            borderRadius: theme.v2.decoration.border.radius.default,
            border: {
              lg: `3px solid ${borderTheme.v2.color.onBackground}`,
              md: "none",
              sm: "none",
            },
            fontWeight: theme.v2.typography.title6.weight,
            hoverBorderColor: borderTheme.v2.color.onBackground,
            boxShadow: {
              sm: "0 0 20px -5px rgba(0, 0, 0, 0.2)",
            },
          },
          secondary: {
            backgroundColor: theme.v2.color.onBackground,
            color: theme.v2.color.background,
          },
          large: {
            minWidth: "100px",
            maxWidth: "124px",
          },
        },
      },
    };
  }

  getFooterProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.footer)};
    return {
      root: {
        backgroundColor: theme.v2.color.base.grey["50"],
      },
      element: {
        hoverLinkColor: theme.v2.color.primary,
        activeLinkColor: theme.v2.color.primary,
        fontFamily: theme.v2.typography.bodyText1.family,
        color: theme.v2.color.onBackground,
      },
    };
  }

  getCardProps(theme, rootTheme) {
    return {
      root: {
        borderRadius: rootTheme.v2.decoration.border.radius.default,
        boxShadow: theme.v2.decoration.shadow.xs
          ? applyShadow(theme.v2.decoration.shadow.xs)
          : "rgb(0 0 0 / 20%) 0px 0px 10px -3px !important",
        border: `2px solid ${rootTheme.v2.color.primary}`,
        hoverBorder: `2px solid ${rootTheme.v2.color.secondary}`,
      },
    };
  }

  getDealsListProps(theme) {
    return {
      ...this.defaultThemeProps.getDealsListProps(theme),
      list: {
        justifyContentMobile: "center",
        padding: "14px 16px 42px 16px",
      },
    };
  }

  getDealDetailProps(theme) {
    return this.defaultThemeProps.getDealDetailProps(theme);
  }

  getDealCardProps(theme) {
    const rootTheme = {v2: merge({}, theme.v2, theme.v2.overrides.dealCard)};
    return {
      ...this.defaultThemeProps.getDealCardProps(theme),
      root: {
        ...this.getCardProps(theme, rootTheme).root,
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "130px",
        },
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "250px",
        },
        padding: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
      },
      header: {
        root: {
          backgroundColor: theme.v2.color.primary,
          borderRadius: theme.v2.decoration.border.radius.default,
        },
      },
      type: {
        fontSize: {
          lg: theme.v2.typography.bodyText2.size.lg,
          md: theme.v2.typography.bodyText2.size.lg,
          sm: theme.v2.typography.bodyText2.size.lg,
        },
        fontWeight: theme.v2.typography.bodyText2.weight,
        fontFamily: theme.v2.typography.bodyText2.family,
        color: theme.v2.color.base.grey["600"],
      },
      title: {
        fontSize: {
          lg: theme.v2.typography.title6.size.lg,
          md: theme.v2.typography.title6.size.lg,
          sm: theme.v2.typography.title6.size.lg,
        },
        fontWeight: theme.v2.typography.title6.weight,
        color: theme.v2.color.onSurface,
        fontFamily: theme.v2.typography.bodyText2.family,
      },
    };
  }

  getRecommendedListProps(theme) {
    return {
      list: {justifyContent: "flex-start"},
      element: {
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "360px",
        },
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px",
        },
      },
      header: {},
      indicator: {
        indicator: {
          color: theme.v2.color.primaryVariant,
        },
      },
      navigationButton: {
        root: {},
      },
    };
  }

  getBrandHeaderProps(theme) {
    return {
      fontFamily: theme.v2.typography.title1.family,
      color: theme.v2.color.primary,
      fontWeight: theme.v2.typography.title1.weight,
      textTransform: theme.v2.text.decorations.none,
      fontSize: {
        lg: theme.v2.typography.title1.size.lg,
        md: theme.v2.typography.title1.size.md,
        sm: theme.v2.typography.title1.size.md,
      },
      textAlign: "left",
    };
  }

  getTitleProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme, "v2.overrides.title"))};
    return {
      fontFamily: theme.v2.typography.title1.family,
      fontSize: {
        sm: theme.v2.typography.sizing["3xl"].lg,
        md: theme.v2.typography.sizing["4xl"].lg,
        lg: theme.v2.typography.sizing["4xl"].lg,
      },
      letterSpacing: {
        sm: theme.v2.typography.title1.letterSpacing,
        md: theme.v2.typography.title1.letterSpacing,
        lg: theme.v2.typography.title1.letterSpacing,
      },
      fontWeight: theme.v2.typography.primary.weight.heavy,
      color: theme.v2.color.primary,
      margin: 0,
      padding: 0,
      textTransform: "uppercase",
      backgroundColor: "transparent",

      "> span": ` {
          cursor: default;
          font-family: ${theme.v2.typography.body.family};
      
          &:hover {
            text-decoration: none;
          }
        }`,
    };
  }

  getProductRecommendationsProps() {
    return {
      element: {
        minWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
      },
    };
  }

  getProductFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productFilters)};
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.title")),
    };
    const counterTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.counter")),
    };
    const sectionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.section")),
    };

    return {
      title: {
        backgroundColor: titleTheme.v2.color.background,
        color: titleTheme.v2.color.onBackground,
      },
      counter: {
        color: counterTheme.v2.color.onSurface,
      },
      section: {
        color: sectionTheme.v2.color.onSurface,
        separatorColor: sectionTheme.v2.color.base.grey[300],
        separatorWidth: "1px",
        checkmarkColor: sectionTheme.v2.color.onBackground,
      },
    };
  }

  getSearchProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appBar?.search)};
    const searchBarTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchBar")),
    };
    const searchPanelTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPanel")),
    };
    const searchPanelGroupTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.group")
      ),
    };
    const searchPanelTitleTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.title")
      ),
    };
    const searchPanelDescriptionTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.description")
      ),
    };
    const searchPanelSeeAllTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.seeAll")
      ),
    };
    const searchPageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPage")),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.secondary,
      },
      searchBar: {
        root: {
          padding: {
            lg: 0,
            md: 0,
            sm: 0,
          },
        },
        input: {
          backgroundColor: searchBarTheme.v2.color.surface,
          borderRadius: searchBarTheme.v2.decoration.border.radius.xs,
          border: `1px solid ${searchBarTheme.v2.color.onSecondary}`,
          fontFamily: searchBarTheme.v2.typography.bodyText1.family,
          fontWeight: searchBarTheme.v2.typography.bodyText1.weight,
          fontSize: searchBarTheme.v2.typography.bodyText1.size,
          color: searchBarTheme.v2.color.typography.bodyText1,
          placeholder: {
            color: searchBarTheme.v2.color.typography.bodyText1,
          },
          searchIcon: {
            color: searchBarTheme.v2.color.base.grey[600],
          },
        },
        close: {
          color: searchBarTheme.v2.color.onSecondary,
        },
      },
      searchPanel: {
        root: {
          backgroundColor: searchPanelTheme.v2.color.secondary,
        },
        groupName: {
          fontFamily: searchPanelGroupTheme.v2.typography.title4.family,
          fontWeight: searchPanelGroupTheme.v2.typography.title4.weight,
          fontSize: searchPanelGroupTheme.v2.typography.title4.size,
          color: searchPanelGroupTheme.v2.color.onSecondary,
        },
        image: {
          borderRadius: searchPanelTheme.v2.decoration.border.radius.xs,
        },
        title: {
          fontFamily: searchPanelTitleTheme.v2.typography.title6.family,
          fontWeight: searchPanelTitleTheme.v2.typography.title6.weight,
          fontSize: searchPanelTitleTheme.v2.typography.title6.size,
          color: searchPanelTitleTheme.v2.color.onSecondary,
        },
        description: {
          fontFamily: searchPanelDescriptionTheme.v2.typography.caption1.family,
          fontWeight: searchPanelDescriptionTheme.v2.typography.caption1.weight,
          fontSize: searchPanelDescriptionTheme.v2.typography.caption1.size,
          color: searchPanelDescriptionTheme.v2.color.onSecondary,
        },
        seeAll: {
          fontFamily: searchPanelSeeAllTheme.v2.typography.caption1.family,
          fontWeight: searchPanelSeeAllTheme.v2.typography.caption1.weight,
          fontSize: searchPanelSeeAllTheme.v2.typography.caption1.size,
          color: searchPanelSeeAllTheme.v2.color.onSecondary,
        },
      },
      searchPage: {
        header: {
          fontFamily: searchPageTheme.v2.typography.title6.family,
          fontWeight: searchPageTheme.v2.typography.title6.weight,
          fontSize: searchPageTheme.v2.typography.title6.size,
          color: searchPageTheme.v2.color.onSecondary,
        },
        tabs: {
          root: {
            padding: 0,
          },
          tab: {
            fontFamily: searchPageTheme.v2.typography.title5.family,
            fontWeight: searchPageTheme.v2.typography.title5.weight,
            fontSize: {
              lg: searchPageTheme.v2.typography.title5.size.lg,
              md: searchPageTheme.v2.typography.title5.size.md,
              sm: "14px",
            },
            color: searchPageTheme.v2.color.typography.onBackground,
            border: `3px solid ${searchPageTheme.v2.color.secondary}`,
            buttonSelectedBackgroundColor: searchPageTheme.v2.color.primary,
            buttonSelectedColor: searchPageTheme.v2.color.onPrimary,
            buttonBorder: `1px solid ${searchPageTheme.v2.color.primary}`,
          },
          separator: {
            color: searchPageTheme.v2.color.base.grey["200"],
          },
        },
        results: {
          fontFamily: searchPageTheme.v2.typography.bodyText2.family,
          fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
          fontSize: searchPageTheme.v2.typography.bodyText2.size,
          color: searchPageTheme.v2.color.typography.onBackground,
        },
        sort: {
          label: {
            fontFamily: searchPageTheme.v2.typography.bodyText2.family,
            fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
            fontSize: searchPageTheme.v2.typography.bodyText2.size,
            color: searchPageTheme.v2.color.typography.onBackground,
          },
        },
        groupedProductsList: {
          elementsPerRow: {
            lg: 4,
            md: 3,
            sm: 1,
          },
        },
      },
    };
  }

  getSubcategoriesProps(theme) {
    return {
      root: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: theme.v2.typography.bodyText1.size,
        fontWeight: theme.v2.typography.bodyText1.weight,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      selected: {
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onPrimary,
      },
      unselected: {
        backgroundColor: theme.v2.color.base.grey["200"],
        color: theme.v2.color.onSurface,
      },
    };
  }
}

memoizeClassMethods(ColorfulThemeProps);

export default ColorfulThemeProps;
