import React from "react";
import styled, {useTheme} from "styled-components";
import Link from "src/core/common/components/modules/Link";
import {ArrowRightShort} from "@styled-icons/bootstrap/ArrowRightShort";
import useIsDesktop from "@ui/utils/useIsDesktop";
import merge from "lodash/merge";

export default function TheColorFulViewAllLink({
  to,
  keepQuery,
  accessibilityIdentifier,
  ...props
}) {
  const isDesktop = useIsDesktop();

  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.viewAll)};

  return (
    <CustomLink
      aria-label={`View all ${
        to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
      } products`}
      to={to}
      keepQuery={keepQuery}
      color={props.color}
      underlined={
        props.underlined !== undefined ? props.underlined : props.defaultUnderlined
      }
    >
      <LinkContainer theme={theme}>
        <ViewAllText color={props.color} theme={theme}>
          <span>{isDesktop ? "View All" : ""}</span>
          <ArrowRightShortIcon
            color={props.color}
            role="img"
            title="View all"
            aria-label={`View all ${
              to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
            } products`}
          />
        </ViewAllText>
      </LinkContainer>
    </CustomLink>
  );
}

const LinkContainer = styled.div`
  border: solid;
  border-color: ${({theme}) =>
    !theme.v1?.components?.viewAll
      ? theme.v2.color.border
      : theme.v1.components?.viewAll?.borderColor ?? theme.v2.color.border};
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.xxs};
  border-width: ${({theme}) =>
    !theme.v1?.components.viewAll
      ? "3px"
      : theme.v1.components?.viewAll.borderWidth ?? "2px"};

  padding: 8px;
  display: flex;

  @media (min-width: 1025px) {
    min-width: 100px;
    height: 38px;
    padding: 8px 18px;
    border-radius: ${({theme}) => theme.v2.decoration.border.radius.xxs};
  }
`;

const CustomLink = styled(Link)`
  text-decoration: ${({underlined}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;

const ViewAllText = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  font-weight: ${({theme}) => theme.v2.typography.title1.weight};
  align-items: center;
  white-space: nowrap;
  @media (min-width: 1025px) {
    > span {
      padding-left: ${({theme}) => theme.v2.spacing(2)};
    }
  }
`;

const ArrowRightShortIcon = styled(ArrowRightShort)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  height: 24px;
  width: 24px;
`;
